// @flow

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { contentLocations } from '../Helpers/contentHelper'
import OverlayPage from '../Components/OverlayPage'
import { Column } from 'michelangelo/dist/Components'
import Row from 'michelangelo/dist/WebComponents/Layout/Row'
import NewsFeedLatestPosts from '../NewsFeed/Components/NewsFeedLatestPosts'
import GET_HASHTAGS_POSTS_QUERY from '../apollo/queries/hashtags/getHashtagPosts'
import GET_GRID_QUERY from '../apollo/queries/getGridByAccountId'
import { useApolloClient, useQuery } from '@apollo/client'
// import { ScrollPercentage } from 'react-scroll-percentage'
// import { debounce } from 'lodash'
import color from 'michelangelo/dist/Components/styles/color'
import { goToContentPreview, goToOldContentPreview } from '../Helpers/contentPreviewHelper'

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-start;
  @media (max-width: 767px) {
    display: none;
  }
`

const Container = styled.div`
  max-width: 564px;
  min-width: 344px;
  width: auto;
  margin: auto;
`
const ParentCard = styled.div`
  display: flex;
  flex-direction: column;
`

const NewsFeedContainer = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px 8px;
  }
`

const EndPageContainer = styled.div`
  margin-top: 20px;
`

const HashtagCard = styled.div`
  display: block;
  background-color: #fff;
  padding: 14px 26px;
`
const HashtagText = styled.div`
  font-weight: 500;
  font-size: 30px;
`
const HashtagStats = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${color.grey400}
`
// added for testing purposes
NewsFeedContainer.displayName = 'NewsFeedContainer'
TitleContainer.displayName = 'TitleContainer'
EndPageContainer.displayName = 'EndPageContainer'

type HashtagFeedPosts = {
  me: Object
} & InjectIntlProvidedProps

function HashtagFeed ({ me, intl: { formatMessage } }: HashtagFeedPosts) {
  const { cfpId, hashtag } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [gridEnabled, setGridEnabled] = useState(false)
  const client = useApolloClient()
  const accountId = me.memberships[0].account._id

  // query the newsfeed of the current cfp
  const { loading, data, refetch } = useQuery(GET_HASHTAGS_POSTS_QUERY(true), {
    variables: {
      hashtag,
      cfpId
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const { data: gridData } = useQuery(GET_GRID_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (gridData && gridData.getGridByAccountId) {
      setGridEnabled(gridData.getGridByAccountId.enabled)
    }
  }, [gridData])

  // const onContentPreviewClick = (content) => {
  //   navigate({
  //     pathname: `${location.pathname}/contentPreview`,
  //     state: {
  //       background: location,
  //       contentId: content._id,
  //       contentLocation: contentLocations.NEWSFEED
  //     }
  //   })
  // }

  // const onLoadMore = () => {
  // const offset = contents.length
  // if (reachedNewsfeedEnd) return
  // return fetchMore({
  //   variables: {
  //     offset
  //   },
  //   updateQuery: (prev, { fetchMoreResult }) => {
  //     if (!fetchMoreResult) return prev
  //     if (isEmpty(fetchMoreResult.getHashtagPosts)) setReachedNewsfeedEnd(true)
  //     const prevNewsFeedItems = prev.getHashtagPosts || []
  //     return Object.assign({}, prev, {
  //       getHashtagPosts: [...prevNewsFeedItems, ...fetchMoreResult.getHashtagPosts]
  //     })
  //   }
  // })
  // }

  const contents = data && data.getHashtagPosts && data.getHashtagPosts.contents ? data.getHashtagPosts.contents : []
  const postsCount = data && data.getHashtagPosts && data.getHashtagPosts.totalPosts

  const onContentPreviewClick = (content, activeChildContent) => {
    const contentLocation = contentLocations.NEWSFEED
    if (gridEnabled) {
      goToContentPreview(navigate,
        { cfpId, content, contents, contentLocation, location, rootPath: location?.pathname, activeChildContent }, {})
    } else {
      goToOldContentPreview(navigate, client, { content, location, contentLocation, activeChildContent })
    }
  }

  return (

    <Row classNames={['is-marginless']}>
      <Column classNames={['is-4-desktop']}>
        <HashtagCard>
          <HashtagText>#{hashtag}</HashtagText>
          <HashtagStats><span style={{ color: color.grey800 }}>{postsCount}</span> {postsCount > 1 ? 'posts' : 'post'}</HashtagStats>
        </HashtagCard>
      </Column>
      <NewsFeedContainer data-cy="newsFeedContainer">
        <OverlayPage visible={false}/>

        <Column classNames={['is-paddingless']}>
          <Container>
            <ParentCard>
              <NewsFeedLatestPosts me={me} contents={contents}
                onContentPreviewClick={onContentPreviewClick}
                refetch={refetch} loading={loading}/>
            </ParentCard>
            {/* <ScrollPercentage */}
            {/*  as="div" */}
            {/*  onChange={debounce((percentage, entry) => { */}
            {/*    if (!loading) { */}
            {/*      onLoadMore() */}
            {/*    } */}
            {/*  }, 1000)} */}
            {/* > */}
            {/* </ScrollPercentage> */}
          </Container>
        </Column>
      </NewsFeedContainer>
    </Row>
  )
}

export default injectIntl(HashtagFeed)
