import React from 'react'

function Hungarian () {
  return (
<>
  <title>theEMPLOYEEapp - Adatvédelmi irányelvek</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Adatvédelmi irányelvek</h1>
  <h4>Hatálybalépés dátuma: 2023. március 15.</h4>
  <p>
    A theEMPLOYEEapp („theEMPLOYEEapp”, vagy „mi”) elkötelezett amellett, hogy
    átláthatóságot biztosítson ügyfelei és felhasználói számára a személyes
    adataik kezelésével kapcsolatos gyakorlatát illetően. Ebből a célból ez az
    Adatvédelmi szabályzat leírja, hogy hogyan gyűjtjük, használjuk és adjuk ki
    a személyes adatokat, és hogyan lehet ezeket a személyes adatokat
    megtekinteni és szükség esetén korrigálni.
  </p>
  <p>
    A <a href="https://theemployeeapp.com/">webhely („Webhely”)</a>{' '}
    meglátogatásával, a kapcsolódó mobilalkalmazás („Alkalmazás”) letöltésével
    vagy a kapcsolódó szolgáltatások (együttesen „Szolgáltatások”)
    használatával, a velünk való kapcsolatfelvétellel vagy a tőlünk érkező
    e-mailek fogadásával ön elfogadja a jelen Adatvédelmi szabályzat
    feltételeit.
  </p>
  <p>
    A jelen Adatvédelmi szabályzat nem terjed ki a harmadik felek által
    üzemeltetett webhelyekre vagy szolgáltatásokra. Ezért nem vállalunk
    felelősséget azok adatvédelmi szabályzatáért, eljárásaiért és gyakorlatáért
    a személyes adatok védelmével kapcsolatban.
  </p>
  <p>
    Ez az Adatvédelmi szabályzat szerves részét képezi a Szoftver mint
    szolgáltatás megállapodásunknak („Megállapodás”), és annak hatálya alá
    tartozik.
  </p>
  <h1>1. Milyen kapcsolatban állunk az ön munkáltatójával?</h1>
  <p>
    Megállapodást kötöttünk az ön munkáltatójával, hogy hozzáférést biztosítunk
    önnek az Alkalmazáshoz és a Szolgáltatásokhoz („Megállapodás”). Személyes
    adatait az ön munkáltatója nevében és annak törvényes utasításai szerint
    kezeljük. Az ön által nekünk megadott és/vagy az Alkalmazáson keresztül
    feltöltött információkra (függetlenül attól, hogy azok személyes adatnak
    minősülnek-e vagy sem) szintén a Megállapodás vonatkozik.
  </p>
  <p>
    A jelen Adatvédelmi szabályzatban az „ön munkáltatójára” történő
    hivatkozások arra a szervezetre vonatkoznak, amely a megállapodást
    megkötötte velünk, függetlenül attól, hogy ön jogilag az adott szervezet
    alkalmazottja, tanácsadója vagy szerződéses partnere-e vagy sem, és az ilyen
    hivatkozásoknak nem célja az ön jogállásának jellemzése vagy sérelme az
    adott szervezettel szemben.
  </p>
  <h1>2. Milyen információkat gyűjtünk?</h1>
  <p>
    Az önről gyűjtött személyes és egyéb információk elsősorban a következő
    forrásokból származnak: (a) ön által megadott információk; (b) a
    munkáltatója által megadott információk; (c) az alkalmazáson keresztül
    gyűjtött információk; (d) az SMS-szolgáltatásunkon keresztül gyűjtött
    információk és (e) automatikusan gyűjtött információk.A személyes adatok
    feldolgozásának jogalapja elsősorban az, hogy a feldolgozás a Szolgáltatások
    nyújtásához szükséges, és hogy a feldolgozásra jogos érdekeink alapján kerül
    sor, amelyeket a „Hogyan használjuk fel az ön adatait?” részben
    részletezünk. Személyes adatokat az ön beleegyezése alapján is
    feldolgozhatunk, adott esetben kérve azt.
  </p>
  <p>
    <strong>
      <u>a) Az ön által megadott információk</u>
    </strong>
  </p>
  <p>
    Amikor ön belép a Szolgáltatásokba, megkérhetjük, hogy önkéntesen adjon meg
    bizonyos információkat, amelyek személyazonosításra alkalmasak, vagy amelyek
    felhasználhatók az ön személyazonosítására („Személyes adatok”). A személyes
    adatok közé tartoznak (de nem kizárólagosan) a következő kategóriák: (1)
    kapcsolattartási adatok (például az ön irodai címe, e-mail-címe és
    telefonszáma); (2) demográfiai adatok (például az ön irányítószáma); (3)
    szakmai adatok (például a cég neve, a beosztása és a munkavállalói
    azonosítója); és (4) egyéb azonosító adatok, amelyeket ön önkéntesen megad
    nekünk, és/vagy amelyeket a munkáltatója megkövetel, beleértve, de nem
    kizárólagosan az olyan egyedi azonosítókat, mint a jelszavak, fényképek,
    vagy az ön által nekünk elküldött e-mailekben vagy levelekben szereplő
    személyes adatok. További információkat is gyűjthetünk, amelyek személyes
    adatok lehetnek, ahogyan azt a gyűjtéskor vagy az ön hozzájárulása alapján
    egyébként ismertetjük önnel. Ha úgy dönt, hogy visszatartja az általunk kért
    személyes adatokat, előfordulhat, hogy nem férhet hozzá a Szolgáltatás
    bizonyos részeihez.
  </p>
  <p>
    <strong>
      <u>b) A munkáltatója által megdott információk</u>
    </strong>
  </p>
  <p>
    Az ön munkáltatója megadhatja nekünk az önre vonatkozó információkat: (1)
    amikor felhasználói profilt hoz létre az ön számára annak érdekében, hogy ön
    a Megállapodás értelmében hozzáférhessen az Alkalmazáshoz, vagy hogy
    lehetővé tegye számunkra a Szolgáltatásaink nyújtását, beleértve a szöveges
    üzenetküldést is. Ezek az információk tartalmazhatják az ön nevét,
    e-mail-címét, telefon- vagy mobiltelefonszámát, munkakörét, részlegét; (2)
    másképpen az Alkalmazás munkáltató általi használata során.
  </p>
  <p>
    <strong>
      <u>c) Az alkalmazáson keresztül gyűjtött információk</u>
    </strong>
  </p>
  <p>
    Az alkalmazáson belüli bizonyos műveletek végrehajtása során előfordulhat,
    hogy a következő műveletekhez való hozzáférés megadására kérik önt. Minden
    egyes műveletnél lemondhat.
  </p>
  <ol>
    <li>Személyazonosság</li>
    <li>Naptár</li>
    <li>Fényképek/Média/Fájlok</li>
    <li>Mikrofon</li>
    <li>Wifi-kapcsolat</li>
    <li>Készülékazonosító</li>
  </ol>
  <p>
    A hozzáférésre az alkalmazáson belül a következő műveletekhez lenne szükség:
  </p>
  <ul>
    <li>
      A személyazonosítási kérelem kizárólag annak megállapítására szolgál, hogy
      kihez tartozik a fiók.
    </li>
    <li>
      A Naptárra vonatkozó kérelem a naptárához való hozzáférésre kér engedélyt,
      hogy az alkalmazás az ön által kiválasztott elemeket hozzáadhassa a
      naptárához.
    </li>
    <li>
      A Fényképek/Média/Fájlok iránti kérelem lehetővé teszi az alkalmazásban
      szereplő fájlok offline használatra történő mentését. Ehhez az
      alkalmazásnak hozzáférésre van szüksége a fájljaihoz. Az alkalmazás nem
      módosítja az ön adatait. Egyszerűen csak lehetővé teszi az ön által
      kiválasztott fájl mentését, hogy később is hozzáférhessen, még akkor is,
      ha nincs internetkapcsolat.
    </li>
    <li>
      A Mikrofonra vonatkozó kérelem olyan élő eseményekhez vagy videók
      rögzítéséhez használható, amelyet ez az alkalmazás támogat.
    </li>
    <li>
      A wifikapcsolat-kérelem annak megállapítására szolgál, hogy ön wifi- vagy
      mobilkapcsolatot létesít-e az alkalmazás optimális teljesítménye
      érdekében.
    </li>
    <li>
      A készülékazonosító-kérelem lehetővé teszi a push-értesítések fogadását az
      alkalmazáson belül.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) Az SMS-szolgáltatásunkon keresztül gyűjtött információk</u>
    </strong>
  </p>
  <p>
    SMS-ünk használatához a következő adatokat kérhetjük öntől:
    mobiltelefonszáma és SMS-beállításai (fel- és leiratkozás). Munkáltatója
    ezeket az információkat az SMS-programunk kezeléséhez használja fel,
    beleértve az SMS-ek küldését is. Az ön vezeték nélküli szolgáltatója
    adatokat gyűjthet az ön vezeték nélküli készülékhasználatáról, és a
    szolgáltató gyakorlatát saját szabályzatai szabályozzák.
  </p>
  <p>
    <strong>
      <u>e) Automatikusan gyűjtött információk</u>
    </strong>
  </p>
  <p>
    Elemzési célokból platformunk hozzáférhet a készülékkel kapcsolatos
    információkhoz is, mint például a készülék típusa, verziója, operációs
    rendszere, valamint a böngésző típusa és verziója (adott esetben), valamint
    a Szolgáltatások ön általi használatára vonatkozó információkhoz..
  </p>
  <p>
    Harmadik felektől, többek között a Google Analytics-től is szerezhetünk
    információkat, és ezeket kombinálhatjuk az általunk gyűjtött
    információkkal..
  </p>
  <h1>3. Hogyan használjuk fel az ön adatait?</h1>
  <p>
    Lépéseket teszünk annak biztosítására, hogy csak azok az alkalmazottak
    férhessenek hozzá az ön személyes adataihoz, akiknek munkavégzési
    kötelezettségeik teljesítéséhez szükségük van rá.
  </p>
  <p>
    Az általunk gyűjtött információkat számos módon használjuk fel a
    Szolgáltatások nyújtása és üzleti tevékenységünk működtetése során, többek
    között a következők szerint:
  </p>
  <ul>
    <li>
      a Szolgáltatások összes funkciójának működtetése, karbantartása,
      fejlesztése és biztosítása, az ön által kért szolgáltatások és információk
      nyújtása, észrevételek és kérdések megválaszolása, valamint a
      Szolgáltatások felhasználóinak támogatása érdekében;
    </li>
    <li>a Megállapodásból eredő kötelezettségeink teljesítése érdekében;</li>
    <li>
      felhasználóink használati trendjeinek és preferenciáinak megértése és
      elemzése, a Szolgáltatások fejlesztése, valamint új termékek,
      szolgáltatások, funkciók és funkciók kifejlesztése érdekében;
    </li>
    <li>
      a termékekkel, Szolgáltatásokkal és az Alkalmazással kapcsolatos csalások
      vagy visszaélések megelőzése és felderítése;
    </li>
    <li>
      vagy adott esetben a jogi és szabályozási követelményeknek való megfelelés
      érdekében.
    </li>
  </ul>
  <h1>4. Kikkel osztjuk meg az ön adatait?</h1>
  <p>
    Bizonyos körülmények között és a Szolgáltatások nyújtása érdekében
    nyilvánosságra hozhatunk bizonyos, öntől gyűjtött információkat.:
  </p>
  <ul>
    <li>
      a vállalatcsaládon belül, amely magában foglalhat anyavállalatokat,
      tagvállalatokat, leányvállalatokat, üzleti egységeket és más, közös
      tuladonosi háttérrel rendelkező vállalatokat;
    </li>
    <li>az ön munkáltatójával a megállapodással összhangban;</li>
    <li>
      a nevünkben dolgozó harmadik fél szolgáltatókkal, akiknek munkájuk
      elvégzéséhez (pl. ügyfélszolgálat, számlázás stb.) szükségük van az ön
      adataihoz való hozzáférésre;
    </li>
    <li>
      valamint egy felvásárló, jogutód vagy engedményes számára bármely
      egyesülés, felvásárlás vagy hasonló tranzakció részeként;
    </li>
  </ul>
  <p>
    Amikor az ön személyes adatait harmadik feleknek adjuk át, ésszerű
    intézkedéseket teszünk annak biztosítására, hogy a jelen adatvédelmi
    szabályzatban meghatározott szabályokat betartsák, és ezek a harmadik felek
    elegendő garanciát nyújtanak a megfelelő technikai és szervezési
    intézkedések végrehajtására..
  </p>
  <p>
    Végül bizonyos automatikusan gyűjtött, összesített vagy más módon nem
    személyazonosításra alkalmas információkat harmadik felek rendelkezésére
    bocsáthatunk különböző célokból, beleértve (i) a különböző jelentési
    kötelezettségeknek való megfelelést; (ii) üzleti vagy marketing célokra;
    vagy (iii) hogy segítsük az ilyen feleket az ön érdeklődésének, szokásainak
    és használati szokásainak megértésében a Szolgáltatásokon keresztül elérhető
    bizonyos programok, tartalmak, szolgáltatások és/vagy funkciók tekintetében.
  </p>
  <h1>5. Hogyan tároljuk az ön adatait?</h1>
  <p>
    Az ön személyes adatait bármely olyan országban tárolhatjuk és
    feldolgozhatjuk, ahol létesítményeink vannak, vagy ahol szolgáltatókat
    veszünk igénybe. A Weboldal meglátogatásával, az Alkalmazás letöltésével
    vagy a Szolgáltatások használatával ön hozzájárul az információknak az ön
    lakóhelye szerinti országon kívüli országokba történő továbbításához,
    amelyekben az ön országától eltérő adatvédelmi szabályok lehetnek érvényben.
  </p>
  <p>
    Az ön személyes adataival kapcsolatos gyakorlatunkat azonban továbbra is a
    jelen Adatvédelmi szabályzat szabályozza, és adott esetben megfelelünk az
    általános Adatvédelmi Rendelet („GDPR”) követelményeinek, amelyek megfelelő
    védelmet biztosítanak a személyes adatoknak az EU-ból/ EGT-ből harmadik
    országba történő továbbítása esetén.
  </p>
  <p>
    Különböző fizikai, adminisztratív és technikai biztosítékokat vezettünk be,
    amelyek célja az ellenőrzésünk alatt álló személyes adatok bizalmas
    kezelésének és biztonságának védelme. Azonban semmilyen biztonsági
    intézkedés nem abszolút vagy teljes mértékben garantált, és önnek tisztában
    kell lennie azzal, hogy mindig fennáll annak a kockázata, hogy az ön által
    megadott Személyes adatokat az ön beleegyezése és a theEMPLOYEEapp hibája
    nélkül nyilvánosságra hozzák. Ha úgy gondolja, hogy személyes adatai
    veszélybe kerültek, kérjük, lépjen kapcsolatba velünk a „Lépjen kapcsolatba
    velünk” részben leírtak szerint. Ha tudomást szerzünk a biztonsági
    rendszerek megsértéséről, a vonatkozó jogszabályoknak megfelelően
    tájékoztatjuk önt és a hatóságokat a jogsértés bekövetkezéséről.
  </p>
  <p>
    Személyes adatait csak addig őrizzük meg, ameddig ésszerűen szükséges a
    jelen Adatvédelmi szabályzatban meghatározott vonatkozó célok
    teljesítéséhez, valamint a jogi és szabályozási kötelezettségeinknek való
    megfelelés érdekébe.
  </p>
  <h1>6. Az ön személyes adataival kapcsolatos jogok</h1>
  <p>
    Tudomásul vesszük, hogy önnek joga van a személyes adataihoz való
    hozzáférésre és azok helyesbítésére az alkalmazandó jogszabályokkal, illetve
    a GDPR szerinti további jogokkal összhangban. Az adatokat az ön
    munkáltatójának irányítása alatt gyűjtjük, és nem állunk közvetlen
    kapcsolatban önnel. Ha a továbbiakban nem szeretné, hogy munkáltatója
    felvegye önnel a kapcsolatot és/vagy szeretne hozzáférni Személyes adataihoz
    vagy helyesbíteni azokat, kérjük, forduljon közvetlenül a munkáltatójához,
    akivel kapcsolatban áll.
  </p>
  <h1>7. Gyermekek adatvédelme</h1>
  <p>
    A Szolgáltatások nem 16 év alatti gyermekeknek szólnak, és nem gyűjtünk
    tudatosan Személyes adatokat 16 év alatti gyermekektől szülői beleegyezés
    nélkül. Ha ön 16 év alatti, kérjük, ne használja a Szolgáltatásokat, és
    semmilyen időben és semmilyen módon ne férjen hozzá azokhoz. Ha megtudjuk,
    hogy a Szolgáltatásokon keresztül 16 év alatti személyektől és ellenőrizhető
    szülői beleegyezés nélkül gyűjtöttek Személyes adatokat, akkor megtesszük a
    megfelelő lépéseket ezen adatok törlésére. Ha ön szülő vagy gondviselő, és
    felfedezi, hogy 16 év alatti gyermeke Személyes adatokat szolgáltatott,
    akkor a „Lépjen kapcsolata velünk” részben meghatározott módon értesíthet
    minket, és kérheti, hogy töröljük a gyermek Személyes adatait a
    rendszereinkből.
  </p>
  <h1>8. Frissítés</h1>
  <p>
    Időről időre frissítjük ezt az Adatvédelmi szabályzatot, hogy tükrözze a
    gyakorlatunkban, a technológiákban, a jogi követelményekben és egyéb
    tényezőkben bekövetkezett változásokat. Kérjük, ellenőrizze az oldal tetején
    található „Hatálybalépés dátuma” alatt, hogy megtudja, mikor frissítették
    utoljára az Adatvédelmi szabályzatot. Ha a jelen Adatvédelmi szabályzatban
    változtatásokat eszközölünk, azok azonnal hatályba lépnek, amint azokat az
    ezen az oldalon közzétett frissített Adatvédelmi szabályzatban közzétesszük,
    kivéve, ha másképp nem jelezzük. A Szolgáltatásoknak a módosításokat követő
    használata azt jelenti, hogy ön elfogadja a frissített Adatvédelmi
    szabályzatban leírt gyakorlatot.
  </p>
  <h1>9. Lépjen kapcsolatba velünk</h1>
  <p>
    Ha bármilyen kérdése vagy észrevétele van a jelen Adatvédelmi szabályzattal
    vagy személyes adataival kapcsolatban, ha élni kíván az alkalmazandó
    jogokkal, ha panaszt szeretne tenni, vagy ha tájékoztatást szeretne kapni az
    irányelveinkről és gyakorlatainkról, az adatvédelmi biztosunkat az alábbi
    elérhetőségeken érheti el postai úton vagy e-mailben.
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Hungarian
