import React from 'react'

function Japanese () {
  return (
<>
  <title>theEMPLOYEEapp - プライバシーポリシー</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>プライバシーポリシー</h1>
  <h4>発効日：2023年03月15日</h4>
  <p>
    theEMPLOYEEapp（「theEMPLOYEEapp」、「当社」、または「私たち」）は、お客様およびユーザーの個人情報の取り扱いに関する慣行に関して、透明性を提供することを約束します。このため、本プライバシーポリシーでは、当社が個人情報を収集、使用、開示する方法、およびこの個人情報を必要に応じて参照および修正する方法について説明します。
  </p>
  <p>
    当社の{' '}
    <a href="https://theemployeeapp.com/">
      ウェブサイト（以下、「ウェブサイト」）
    </a>
    、関連するモバイルアプリケーション（以下、「アプリ」）のダウンロード、または関連するサービス（以下、「サービス」）の利用、当社へのお問い合わせ、または当社からのメール受信に同意することで、本プライバシーポリシーの利用規約に同意したものとみなされます。
  </p>
  <p>
    本プライバシーポリシーは、第三者が運営するウェブサイトやサービスには適用されません。したがって、第三者による個人情報の保護に関するプライバシーポリシー、手順、および実践については、当社は責任を負いません。
  </p>
  <p>
    本プライバシーポリシーは、当社のソフトウェアサービス契約（以下、「契約」）に組み込まれ、本契約の対象となります。
  </p>
  <h1>1. あなたと雇用主との関係</h1>
  <p>
    当社は、あなたの雇用主と、あなたが本アプリおよび本サービスにアクセスできるようにするための契約（以下、「契約」）を締結しています。当社は、あなたの雇用主に代わり、その合法的な指示に従い、あなたの個人情報を処理します。あなたが本アプリを通じて当社に提供し、および／またはアップロードする情報（それが個人情報を構成するか否かを問いません）についても、本契約が適用されるものとします。
  </p>
  <p>
    本プライバシーポリシーにおける「あなたの雇用主」という表現は、法律上の問題としてあなたが当該事業体の従業員、コンサルタントまたは請負業者であるか否かにかかわらず、当社と契約を締結した事業体を指すものとし、当該表現は、当該事業体に対するあなたの地位を特徴づけることまたは偏見を与えることを意図するものではありません。
  </p>
  <h1>2. 収集する情報</h1>
  <p>
    当社があなたについて収集する可能性のある個人情報およびその他の情報の種類は、主に以下の情報源からです。（a）あなたが提供する情報、（b）あなたの雇用主が提供する情報、（c）本アプリを通じて収集した情報、（d）当社のSMSサービスを通じて収集した情報、および（e）自動収集した情報。当社が個人情報を処理する法的根拠は、主に、処理が本サービスの提供に必要であること、および処理が当社の正当な利益のために実施されることであり、これらは「あなたの情報の使用方法」セクションで詳しく説明しています。また、当社は、適宜、あなたの同意を得て、個人情報を処理することがあります。
  </p>
  <p>
    <strong>
      <u>a</u>
    </strong>
    <strong>
      <u>）あなたが提供する情報</u>
    </strong>
  </p>
  <p>
    あなたが本サービスにアクセスする際、当社は、あなたを個人的に識別する、または個人的に識別するために使用できる特定の情報（以下、「個人情報」）を自主的に提供していただくようお願いする場合があります。個人情報には、以下のカテゴリーが含まれます（ただし、これに限定されるものではありません）。（1）連絡先データ（勤務先の住所、電子メールアドレス、電話番号など）、（2）人口統計データ（郵便番号など）、（3）職業に関する情報（会社名、職務、社員IDなど）、（4）その他、あなたが自発的に当社に提供することを選択した、および／またはあなたの雇用主が要求した識別情報（パスワード、写真、あなたが当社に送るメールまたは手紙の個人情報などの固有の識別情報を制限なく含む）。また、当社は、収集の時点であなたに別途説明したとおり、またはあなたの同意に基づき、個人情報である可能性のある追加情報を収集することがあります。あなたが当社から要求された個人情報を提供しない場合、本サービスの一部にアクセスすることができなくなる可能性があります。
  </p>
  <p>
    <strong>
      <u>b</u>
    </strong>
    <strong>
      <u>）あなたの雇用主が提供する情報</u>
    </strong>
  </p>
  <p>
    あなたの雇用主は、（1）あなたが本契約に基づき本アプリにアクセスできるようにするため、または当社がテキストメッセージングを含む当社のサービスを提供できるようにするために、あなたのユーザープロフィールを作成する場合（この情報には、あなたの氏名、電子メールアドレス、電話番号または携帯電話番号、役職、部署が含まれることがあります）、および（2）その他あなたの雇用主が本アプリを使用する過程で、あなたに関する情報を当社に提供することがあります。
  </p>
  <p>
    <strong>
      <u>c</u>
    </strong>
    <strong>
      <u>）本アプリを通じて収集した情報</u>
    </strong>
  </p>
  <p>
    あなたが本アプリ内で特定のアクションを実行すると、以下へのアクセスを許可するように求められることがあります。あなたは各アクションをオプトアウトすることができます。
  </p>
  <ol>
    <li>ID</li>
    <li>カレンダー</li>
    <li>写真／メディア／ファイル</li>
    <li>マイク</li>
    <li>WiFi接続</li>
    <li>デバイスID</li>
  </ol>
  <p>アプリ内の次のアクションにアクセスする必要があります。</p>
  <ul>
    <li>
      IDのリクエストは、アカウントが誰に属しているかを判断するためだけのものです。
    </li>
    <li>
      カレンダーのリクエストは、アプリがあなたのカレンダーに、あなたが選択した項目を追加できるように、カレンダーにアクセスする許可を求めるものです。
    </li>
    <li>
      写真／メディア／ファイルのリクエストは、アプリに含まれているファイルをオフラインで使用するために保存できるようにするものです。これを行うには、アプリがファイルにアクセスする必要があります。アプリによって情報が変更されることはありません。インターネット接続が利用できない場合でも、後でアクセスできるように、選択したファイルを保存できるようにするだけのものです。
    </li>
    <li>
      マイクのリクエストは、本アプリが対応するライブイベントや動画の撮影に使用されます。
    </li>
    <li>
      WiFi接続のリクエストは、アプリのパフォーマンスを最適化するために、WiFi接続またはデータ接続のどちらを使用しているかを判断するものです。
    </li>
    <li>
      デバイスIDのリクエストは、アプリ内でプッシュ通知を受信できるようにするものです。
    </li>
  </ul>
  <p>
    <strong>
      <u>d</u>
    </strong>
    <strong>
      <u>）当社の</u>
    </strong>
    <strong>
      <u>SMS</u>
    </strong>
    <strong>
      <u>サービスを通じて収集した情報</u>
    </strong>
  </p>
  <p>
    当社のSMSを利用するためには、あなたの携帯電話番号とテキストメッセージの設定（オプトインおよびオプトアウト）を提供するよう促される場合があります。あなたの雇用主は、あなたにテキストメッセージを送信することを含め、当社のテキストメッセージングプログラムを管理するためにこの情報を使用します。
    あなたの無線通信事業者は、お使いの無線デバイスの使用に関するデータを収集することがあり、その慣行は独自のポリシーによって管理されています。
  </p>
  <p>
    <strong>
      <u>e</u>
    </strong>
    <strong>
      <u>）自動収集した情報</u>
    </strong>
  </p>
  <p>
    分析の目的で、当社のプラットフォームは、デバイスのタイプ、バージョン、オペレーティングシステム、ブラウザのタイプとバージョン（該当する場合）などのデバイス情報、ならびにあなたの本サービスの使用に関する情報にアクセスすることができます。
  </p>
  <p>
    また、Googleアナリティクスを含むサードパーティから情報を取得し、収集した情報と組み合わせることもあります。
  </p>
  <h1>3. あなたの情報の使用方法</h1>
  <p>
    当社は、雇用上の義務を果たすためにあなたの個人情報へのアクセスが必要な従業員のみがアクセスできるように設計された措置を講じています。
  </p>
  <p>
    当社は、本サービスの提供および当社の事業運営において、収集した情報を以下のような様々な方法で利用します。
  </p>
  <ul>
    <li>
      本サービスのすべての機能の運営、維持、強化、提供、あなたが要求するサービスや情報の提供、コメントや質問への対応、本サービスのユーザーへのサポート提供のため。
    </li>
    <li>本契約から生じる当社の義務を履行するため。</li>
    <li>
      ユーザーの利用動向や嗜好を把握・分析し、本サービスを改善し、新しい製品、サービス、特徴、機能を開発するため。
    </li>
    <li>
      あなたの製品、本サービスおよび本アプリの不正利用を防止および検出するため。
    </li>
    <li>適用される法律に従って、あなたに通信を送信するため。</li>
    <li>または、該当する場合、法的および規制上の要件を遵守するため。</li>
  </ul>
  <h1>4. あなたの情報の共有先</h1>
  <p>
    特定の状況において、また本サービスを実行するために、当社はあなたから収集した特定の情報を開示する場合があります。
  </p>
  <ul>
    <li>
      親会社、法人、関連会社、子会社、事業部および共同所有する他の会社を含む、当社の系列企業で共有する場合。
    </li>
    <li>本契約に従いあなたの雇用主に開示する場合。</li>
    <li>
      当社に代わって業務を行う第三者サービスプロバイダーが、その業務を遂行するためにあなたの情報へのアクセスを必要とする場合（例：カスタマーサポート、請求書作成など）。
    </li>
    <li>
      および、合併、買収または同様の取引の一環として、買収者、承継者または譲受人と共有する場合。
    </li>
  </ul>
  <p>
    当社は、あなたの個人情報を第三者に開示する場合、本プライバシーポリシーに定める規則が遵守されるよう合理的な措置を講じるとともに、これらの第三者が適切な技術的および組織的措置を実施するために十分な保証を提供します。
  </p>
  <p>
    最後に、当社は、自動的に収集された集計された、または個人を特定しない情報を、（i）各種報告義務の遵守のため、（ii）ビジネスやマーケティングの目的で、（iii）当社のサービスを通じて提供されるプログラム、コンテンツ、サービス、および／または機能に関するあなたの興味、習慣、および使用パターンを理解するのに役立てるために、このような第三者をサポートするために第三者に提供することがあります。
  </p>
  <h1>5. あなたの情報の保管方法</h1>
  <p>
    あなたの個人情報は、当社が施設を有する国、または当社がサービスプロバイダーと契約している国で保管および処理されることがあります。本ウェブサイトの閲覧、本アプリのダウンロードまたは本サービスの利用により、あなたは、あなたの居住国とは異なるデータ保護規則を有する可能性のある居住国以外の国への情報の転送に同意するものとします。
  </p>
  <p>
    ただし、あなたの個人情報に関する当社の慣行は、常に本プライバシーポリシーに準拠し続け、該当する場合は、EU/EEAから第三国への個人情報の転送に適切な保護を提供する一般データ保護規則（「GDPR」）の要件に準拠するものとします。
  </p>
  <p>
    当社は、当社の管理下にある個人情報の機密性と安全性を保護するために、物理的、管理的、技術的なさまざまな保護措置を実施しています。しかしながら、いかなるセキュリティ対策も絶対的なものではなく、また完全に保証されるものではなく、あなたは、当社に提供した個人情報があなたの同意なく、またtheEMPLOYEEappの過失なく開示されるリスクが常に一定程度存在することを認識する必要があります。あなたの個人情報が漏洩したと思われる場合は、「お問い合わせ」に記載のとおり、当社までご連絡ください。セキュリティシステムの侵害が発生した場合、当社は適用される法律に従って、侵害の発生をあなたと関係当局に通知します。
  </p>
  <p>
    当社は、本プライバシーポリシーに定められた関連する目的を達成するため、および当社の法的義務や規制上の義務を遵守するために、合理的に必要な期間だけあなたの個人情報を保管します。
  </p>
  <h1>6. あなたの個人情報に関する権利</h1>
  <p>
    当社は、あなたが、適用される法律、またはGDPRに基づく追加の権利に従って、ご自身の個人情報にアクセスし、修正する権利を有することを認めます。当社は、あなたの雇用主の指示のもとで情報を収集しており、あなたと直接の関係はありません。雇用主からの連絡を希望しなくなった場合、および／または個人情報へのアクセスや訂正を希望する場合は、やり取りのある雇用主へ直接ご連絡ください。
  </p>
  <h1>7. 児童のプライバシー</h1>
  <p>
    本サービスは16歳未満の児童を対象としておらず、当社は保護者の同意を得ることなく、16歳未満の児童から意図的に個人情報を収集することはありません。あなたが16歳未満である場合、いつでも、いかなる方法でも、本サービスを使用またはアクセスしないでください。当社は、本サービスを通じて、16歳未満の者から、検証可能な保護者の同意なしに個人情報が収集されたことが判明した場合、当該情報を削除するために適切な措置を講じるものとします。あなたが保護者であり、16歳未満のお子様が個人情報を提供したことが判明した場合、「お問い合わせ」に記載されているように当社に通知し、当社のシステムからそのお子様の個人情報を削除するよう要求することができます。
  </p>
  <h1>8. 更新</h1>
  <p>
    当社は、当社の慣行、技術、法的要件およびその他の要因の変化を反映するために、本プライバシーポリシーを適宜更新します。本プライバシーポリシーの最終更新日は、このページの上部にある「発効日」をご確認ください。本プライバシーポリシーが変更された場合、別段の定めがない限り、本ページに掲載される最新のプライバシーポリシーで公開された時点で、直ちに効力を生じるものとします。これらの変更後に本サービスを利用した場合、あなたは、更新されたプライバシーポリシーに記載された慣行に同意したものとみなされます。
  </p>
  <h1>9. お問い合わせ</h1>
  <p>
    本プライバシーポリシーまたはあなたの個人情報に関してご質問やご意見がある場合、適用される権利を行使する場合、苦情を申し立てる場合、または当社の方針および慣行に関する情報を入手する場合は、当社のプライバシー担当者に以下の連絡先まで郵便または電子メールでお問い合わせください。
  </p>
  <p>
    <br />
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Japanese
