import React from 'react'

function PB () {
  return (
<>
  <title>theEMPLOYEEapp - Política de Privacidade</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Política de Privacidade</h1>
  <h4>Data de vigência: 15 de março de 2023</h4>
  <p>
    A theEMPLOYEEapp ("theEMPLOYEEapp", "nós", "nos", "nosso" ou "nossos") está
    comprometida com a transparência perante os seus clientes e usuários no que
    tange a práticas de tratamento de informações pessoais. Por isso, esta
    Política de Privacidade descreve como coletamos, utilizamos e divulgamos
    informações pessoais e como essas informações pessoais podem ser consultadas
    e corrigidas quando necessário.
  </p>
  <p>
    Ao acessar o <a href="https://theemployeeapp.com/">website ("Website")</a>,
    baixar o aplicativo móvel associado ("App"), utilizar serviços associados
    ("Serviços", de modo geral), entrar em contato conosco ou concordar em
    receber os nossos e-mails, você está confirmando que aceita os termos e as
    condições desta Política de Privacidade.
  </p>
  <p>
    Esta Política de Privacidade não se estende a websites ou serviços operados
    por terceiros. Portanto, não somos responsáveis por políticas de
    privacidade, procedimentos e práticas de terceiros relacionadas à proteção
    de informações pessoais.
  </p>
  <p>
    Esta Política de Privacidade está incorporada e está sujeita ao nosso
    Contrato de <em>software</em> como Serviço ("Contrato").
  </p>
  <h1>1. Qual é a nossa relação com o seu empregador?</h1>
  <p>
    Firmamos um contrato com o seu empregador para conceder a você acesso ao App
    e aos Serviços ("Contrato"). As suas informações pessoais serão processadas
    em nome do seu empregador e de acordo com as instruções legais dele. As
    informações que você fornecer a nós e/ou enviar por meio do App (sejam elas
    informações pessoais ou não) também serão regidas pelo Contrato.
  </p>
  <p>
    Nesta Política de Privacidade, as referências ao "seu empregador" dizem
    respeito à entidade que firmou Contrato conosco, seja você um funcionário,
    consultor ou contratado dessa entidade legalmente ou não. Tais referências
    não têm o intuito de caracterizar ou prejudicar o seu <em>status</em> em
    relação à essa entidade.
  </p>
  <h1>2. Quais são as informações que coletamos?</h1>
  <p>
    As informações pessoais e as outras informações que podemos coletar sobre
    você são oriundas principalmente das seguintes fontes: (a) informações
    fornecidas por você; (b) informações fornecidas pelo seu empregador; (c)
    informações coletadas por meio do App; d) informações coletadas por meio do
    nosso serviço de SMS; e (e) informações coletadas automaticamente. Como base
    legal do processamento de informações pessoais realizado por nós,
    considera-se, principalmente, que o processamento é necessário para
    fornecimento dos Serviços e que o processamento é realizado com base em
    legítimos interesses, explicados em detalhes na seção "Como utilizamos as
    suas informações?". Também podemos processar informações pessoais mediante o
    seu consentimento, solicitando-as conforme apropriado.
  </p>
  <p>
    <strong>
      <u>a) Informações fornecidas por você</u>
    </strong>
  </p>
  <p>
    Quando você acessa os Serviços, podemos solicitar que você nos forneça
    voluntariamente certas informações que identificam você pessoalmente ou que
    podem ser utilizadas para identificar você pessoalmente ("informações
    pessoais"). As informações pessoais incluem (mas não estão limitadas a) as
    seguintes categorias de informações: (1) informações de contato (como o seu
    endereço comercial, o seu endereço de e-mail e o seu número de telefone);
    (2) informações demográficas (como o seu código postal); (3) informações
    profissionais (como o nome da sua empresa, o seu cargo e a sua identificação
    de funcionário); e (4) outras informações de identificação que você optar
    por fornecer voluntariamente a nós e/ou que são exigidas pelo seu
    empregador, incluindo (sem estarem limitadas a) identificadores exclusivos,
    como senhas, fotos ou informações pessoais em e-mails ou cartas que você
    enviar para nós. Também podemos coletar informações adicionais, que podem
    ser informações pessoais, a serem descritas para você no momento da coleta
    ou de acordo com o seu consentimento. Se você optar por não divulgar
    qualquer informação pessoal solicitada por nós, é possível que você não
    obtenha o acesso a certas partes do Serviço.
  </p>
  <p>
    <strong>
      <u>b) Informações fornecidas pelo seu empregador </u>
    </strong>
  </p>
  <p>
    O seu empregador pode fornecer para nós informações sobre você: (1) ao criar
    um perfil de usuário para você, a fim de permitir que você acesse o App com
    base no Contrato ou a fim de garantir que possamos fornecer os nossos
    Serviços, incluindo mensagens de texto. Essas informações podem incluir o
    seu nome, endereço de e-mail, número de telefone ou celular, cargo,
    departamento; ou, ainda, (2) ao utilizar o App.
  </p>
  <p>
    <strong>
      <u>c) Informações coletadas por meio do App</u>
    </strong>
  </p>
  <p>
    Ao realizar determinadas ações no App, pode ser solicitado que você conceda
    acesso ao seguinte. Você pode optar por recusar cada uma dessas opções.
  </p>
  <ol>
    <li>Identidade</li>
    <li>Calendário</li>
    <li>Fotos/Mídia/Arquivos</li>
    <li>Microfone</li>
    <li>Conexão wi-fi</li>
    <li>ID do dispositivo</li>
  </ol>
  <p>A solicitação de acesso se deve às seguintes ações no App:</p>
  <ul>
    <li>
      A solicitação de Identidade serve apenas para determinar a quem pertence a
      conta.
    </li>
    <li>
      A solicitação de Calendário se refere à permissão para acessar o seu
      calendário para que o App possa adicionar itens de sua escolha ao seu
      calendário.
    </li>
    <li>
      A solicitação de Fotos/Mídia/Arquivos permite que você salve arquivos que
      estão no seu App para uso off-line. Para isso, o App precisa ter acesso
      aos seus arquivos. O App não faz alterações nas suas informações; ele
      simplesmente permite que você salve um arquivo de sua escolha para acesso
      futuro, mesmo quando não houver conexão de internet.
    </li>
    <li>
      A solicitação de Microfone é utilizada em casos de eventos ao vivo ou para
      gravar vídeos, ação que pode ser realizada pelo App.
    </li>
    <li>
      A solicitação de Conexão wi-fi serve para identificar se você está
      utilizando uma conexão wi-fi ou os dados do celular visando otimizar o
      desempenho do App.
    </li>
    <li>
      A solicitação de ID do dispositivo permite que você receba notificações
      push no App.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) Informações coletadas por meio do nosso serviço de SMS</u>
    </strong>
  </p>
  <p>
    Para utilizar o nosso serviço de SMS, pode ser necessário que você informe:
    o seu número de celular e as suas preferências de mensagens de texto (
    <em>opt-in</em> e <em>opt-out</em>). O seu empregador utiliza essas
    informações para gerenciar o nosso programa de mensagens de texto, inclusive
    para enviar mensagens de texto para você. A sua operadora de conexão sem fio
    pode coletar dados sobre o uso do seu dispositivo sem fio, e as práticas,
    nesse caso, são regidas pelas suas políticas próprias.
  </p>
  <p />
  <p>
    <strong>
      <u>e) Informações coletadas automaticamente</u>
    </strong>
  </p>
  <p>
    Para fins de análise, a nossa plataforma também pode ter acesso a
    informações do dispositivo, como tipo de dispositivo, versão, sistema
    operacional e tipo e versão do navegador (se aplicável), além de informações
    sobre o seu uso dos Serviços.{' '}
  </p>
  <p>
    Também podemos obter informações de terceiros, como o Google Analytics, e
    combiná-las com as informações que coletamos.{' '}
  </p>
  <h1>3. Como utilizamos as suas informações?</h1>
  <p>
    Nos empenhamos para garantir que apenas os funcionários que precisam ter
    acesso às suas informações pessoais para realizar o trabalho que desempenham
    tenham acesso a elas.
  </p>
  <p>
    Utilizamos as informações que coletamos de várias maneiras ao fornecer os
    Serviços e realizar o nosso trabalho, como, por exemplo:
  </p>
  <ul>
    <li>
      para operar, manter, aprimorar e fornecer todos os recursos dos Serviços,
      para fornecer os serviços e as informações solicitadas por você, para
      responder a comentários e perguntas e para fornecer atendimento aos
      usuários dos Serviços;
    </li>
    <li>para cumprir as nossas obrigações decorrentes do Contrato;</li>
    <li>
      para entender e analisar as tendências e as preferências de uso dos nossos
      usuários, para melhorar os Serviços e para desenvolver novos produtos,
      serviços, recursos e funcionalidades;
    </li>
    <li>
      para prevenir e detectar fraudes ou uso indevido dos produtos, dos
      Serviços e do App;
    </li>
    <li>para enviar a você comunicações sujeitas às leis vigentes; ou</li>
    <li>
      para cumprir requisitos legais e outros requisitos estabelecidos, quando
      aplicável.
    </li>
  </ul>
  <h1>4. Com quem compartilhamos as suas informações?</h1>
  <p>
    Em certas circunstâncias, visando a execução dos Serviços, certas
    informações que coletamos de você podem ser compartilhadas:
  </p>
  <ul>
    <li>
      dentro da nossa família de empresas, que pode incluir matrizes,
      corporações, afiliadas, subsidiárias, unidades de negócios e outras
      empresas que compartilham propriedade comum;
    </li>
    <li>com o seu empregador, de acordo com o Contrato;</li>
    <li>
      com provedores de serviços terceirizados que trabalham conosco e exigem
      acesso às suas informações para realizar o seu trabalho (por exemplo:
      atendimento ao cliente, cobrança etc.);
    </li>
    <li>
      com adquirentes, sucessores ou cessionários como parte de qualquer fusão,
      aquisição ou transação semelhante.
    </li>
  </ul>
  <p>
    Quando divulgamos as suas informações pessoais a terceiros, tomamos medidas
    razoáveis para garantir que as regras estabelecidas nesta Política de
    Privacidade sejam cumpridas e que esses terceiros forneçam garantias
    suficientes para implementação de medidas técnicas e organizacionais
    apropriadas.
  </p>
  <p>
    Por fim, é possível que disponibilizemos a terceiros determinadas
    informações coletadas automaticamente, agregadas ou, de outra forma, que não
    possibilitem a identificação pessoal para diversos fins, como (i) para fins
    de conformidade com diversas obrigações de relatórios; (ii) para fins
    comerciais ou de marketing; ou (iii) para ajudar esses terceiros a entender
    os seus interesses, hábitos e padrões de uso em relação a determinados
    programas, conteúdos, serviços e/ou funcionalidades disponíveis por meio dos
    Serviços.
  </p>
  <h1>5. Como armazenamos as suas informações?</h1>
  <p>
    As suas informações pessoais podem ser armazenadas e processadas em qualquer
    país onde tenhamos instalações ou contratemos prestadores de serviços. Ao
    visitar o Website, baixar o App ou utilizar os Serviços, você concorda com a
    transferência de informações para países fora do seu país de residência, que
    podem ter regras de proteção de dados diferentes das regras no seu país.{' '}
  </p>
  <p>
    No entanto, a nossas práticas em relação às suas informações pessoais
    continuarão sempre sendo regidas por esta Política de Privacidade e, se
    aplicável, nós cumpriremos os requisitos da Lei Geral de Proteção de Dados
    ("LGPD"), fornecendo proteção adequada na transferência de informações
    pessoais da União Europeia/área Econômica Europeia para um país terceiro.
  </p>
  <p>
    Implementamos várias formas de proteção físicas, administrativas e técnicas
    destinadas a proteger a confidencialidade e a segurança das informações
    pessoais que estão sob o nosso controle. No entanto, nenhuma medida de
    segurança é absoluta ou totalmente garantida, e você deve estar ciente de
    que sempre existe certo nível de risco de que as informações pessoais que
    você nos fornece sejam divulgadas sem o seu consentimento e sem culpa por
    parte do theEMPLOYEEapp. Se você acredita que as suas informações pessoais
    foram comprometidas, entre em contato conosco, conforme indicado na seção
    "Fale conosco". Se soubermos de alguma violação dos sistemas de segurança,
    informaremos sobre a ocorrência da violação para você e para as autoridades,
    de acordo com a lei vigente.
  </p>
  <p>
    Manteremos as suas informações pessoais apenas pelo tempo razoavelmente
    necessário para cumprimento dos propósitos relevantes estabelecidos nesta
    Política de Privacidade e para cumprimento das nossas obrigações legais e
    regulamentares.{' '}
  </p>
  <h1>6. Direitos relacionados às suas informações pessoais</h1>
  <p>
    Reconhecemos que você tem o direito de acessar e corrigir as suas
    informações pessoais, de acordo com a lei vigente, ou direitos adicionais
    com base na LGPD. Coletamos informações com indicação do seu empregador, sem
    relações diretas com você. Se você não quiser mais ser contatado pelo seu
    empregador e/ou se quiser acessar ou corrigir as suas informações pessoais,
    entre em contato com o seu empregador, com quem você interage diretamente.
  </p>
  <h1>7. Privacidade para crianças</h1>
  <p>
    Os Serviços não são dirigidos a crianças menores de 16 anos, e não coletamos
    intencionalmente informações pessoais de crianças menores de 16 anos sem
    obtenção do consentimento dos seus pais. Se você tiver menos de 16 anos de
    idade, não utilize nem acesse os Serviços em nenhum momento ou de qualquer
    forma. Se soubermos que informações pessoais foram coletadas por meio dos
    Serviços de pessoas com menos de 16 anos de idade e sem o consentimento
    verificável dos pais, tomaremos as medidas apropriadas para excluir essas
    informações. Se você for pai, mãe ou responsável e descobrir que o seu filho
    menor de 16 anos forneceu informações pessoais, você pode nos alertar,
    conforme estabelecido na seção "Fale Conosco", e solicitar que as
    informações pessoais da criança sejam excluídas dos nossos sistemas.
  </p>
  <h1>8. Atualização</h1>
  <p>
    Atualizaremos esta Política de Privacidade periodicamente para que reflita
    as alterações em nossas práticas, tecnologias, requisitos legais e outros
    fatores. Por favor, verifique a "Data de vigência" na parte superior desta
    página para ver quando esta Política de Privacidade foi atualizada pela
    última vez. Quando forem realizadas alterações na Política de Privacidade,
    elas entrarão em vigor imediatamente, quando publicadas em uma Política de
    Privacidade atualizada, publicada nesta página, salvo indicação contrária. O
    seu uso dos Serviços após essas alterações indica o seu consentimento no que
    tange às práticas descritas na Política de Privacidade atualizada.
  </p>
  <h1>9. Fale conosco</h1>
  <p>
    Se você tiver alguma dúvida ou comentário sobre esta Política de Privacidade
    ou sobre aspectos referentes às suas informações pessoais, para exercer
    direitos aplicáveis, para fazer uma reclamação ou para obter informações
    sobre as nossas políticas e práticas, entre em contato com o nosso Diretor
    de Privacidade por correio ou e-mail usando as seguintes informações de
    contato:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default PB
