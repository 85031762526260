import React from 'react'

function German () {
  return (
<>
  <title>theEMPLOYEEapp - Datenschutzrichtlinie</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Datenschutzrichtlinie</h1>
  <h4>Gilt ab: 15. März 2023</h4>
  <p>
    theEMPLOYEEapp („theEMPLOYEEapp“, „uns“ oder „wir“) ist bestrebt, seinen
    Kunden und Nutzern Transparenz in Bezug auf den Umgang mit ihren
    persönlichen Daten zu bieten. Zu diesem Zweck beschreibt diese
    Datenschutzrichtlinie, wie wir persönliche Daten erfassen, verwenden und
    weitergeben und wie diese persönlichen Daten eingesehen und gegebenenfalls
    korrigiert werden können.
  </p>
  <p>
    Durch den Aufruf der{' '}
    <a href="https://theemployeeapp.com/">Website („Website“)</a>, das
    Herunterladen der zugehörigen mobilen Anwendung („App“) oder die Nutzung
    zugehöriger Dienste (zusammen „Dienste“), durch die Kontaktaufnahme mit uns
    oder die Zustimmung zum Erhalt von E-Mails von uns akzeptieren Sie die
    Bedingungen dieser Datenschutzrichtlinie.
  </p>
  <p>
    Diese Datenschutzrichtlinie erstreckt sich nicht auf Websites oder Dienste,
    die von Dritten betrieben werden. Wir haften daher nicht für deren
    Datenschutzrichtlinien, -verfahren und -praktiken in Bezug auf den Schutz
    personenbezogener Daten.
  </p>
  <p>
    Diese Datenschutzrichtlinie ist Teil unserer
    Software-as-a-Service-Vereinbarung („Vereinbarung“) und unterliegt dieser.
  </p>
  <h1>1. Was ist unsere Beziehung zu Ihrem Arbeitgeber?</h1>
  <p>
    Wir haben mit Ihrem Arbeitgeber eine Vereinbarung geschlossen, um Ihnen
    Zugang zur App und den Diensten zu gewähren („Vereinbarung“). Wir werden
    Ihre personenbezogenen Daten im Namen Ihres Arbeitgebers und in
    übereinstimmung mit dessen rechtmäßigen Anweisungen verarbeiten. Die
    Informationen, die Sie uns zur Verfügung stellen und/oder über die App
    hochladen (unabhängig davon, ob es sich um personenbezogene Daten handelt
    oder nicht), unterliegen ebenfalls der Vereinbarung.
  </p>
  <p>
    Verweise in dieser Datenschutzrichtlinie auf „Ihren Arbeitgeber“ beziehen
    sich auf das Unternehmen, das die Vereinbarung mit uns geschlossen hat,
    unabhängig davon, ob Sie rechtlich gesehen ein Angestellter, Berater oder
    Auftragnehmer dieses Unternehmens sind oder nicht, und solche Verweise
    sollen Ihren Status gegenüber diesem Unternehmen nicht charakterisieren oder
    beeinträchtigen.
  </p>
  <h1>2. Welche Informationen erfassen wir?</h1>
  <p>
    Die Arten von persönlichen und anderen Informationen, die wir über Sie
    erfassen können, stammen hauptsächlich aus den folgenden Quellen: (a) von
    Ihnen bereitgestellte Informationen; (b) von Ihrem Arbeitgeber
    bereitgestellte Informationen; (c) über die App gesammelte Informationen;
    (d) über unseren SMS-Dienst gesammelte Informationen und (e) automatisch
    gesammelte Informationen. Die Rechtsgrundlagen für unsere Verarbeitung
    personenbezogener Daten sind in erster Linie, dass die Verarbeitung für die
    Bereitstellung der Dienste erforderlich ist und dass die Verarbeitung in
    unserem legitimen Interesse erfolgt, was im Abschnitt „Wie verwenden wir
    Ihre Daten? Wir können personenbezogene Daten auch mit Ihrer Zustimmung
    verarbeiten, indem wir Sie gegebenenfalls darum bitten.
  </p>
  <p>
    <strong>
      <u>a) Informationen, die Sie bereitstellen</u>
    </strong>
  </p>
  <p>
    Wenn Sie auf die Dienste zugreifen, können wir Sie bitten, uns freiwillig
    bestimmte Informationen zur Verfügung zu stellen, die Sie persönlich
    identifizieren oder dazu verwendet werden könnten, Sie persönlich zu
    identifizieren („personenbezogene Daten“). Zu den personenbezogenen Daten
    gehören u. a. die folgenden Kategorien von Informationen: (1) Kontaktdaten
    (z. B. Ihre Büroadresse, E-Mail-Adresse und Telefonnummer); (2)
    demografische Daten (z. B. Ihre Postleitzahl); (3) berufliche Informationen
    (z. B. der Name Ihres Unternehmens, Ihre Funktion und Ihre Mitarbeiter-ID);
    und (4) andere identifizierende Informationen, die Sie uns freiwillig zur
    Verfügung stellen und/oder die von Ihrem Arbeitgeber verlangt werden,
    einschließlich, aber nicht beschränkt auf eindeutige
    Identifizierungsmerkmale wie Passwörter, Fotos oder personenbezogene Daten
    in E-Mails oder Briefen, die Sie an uns senden. Wir können auch zusätzliche
    Informationen erfassen, bei denen es sich um personenbezogene Daten handeln
    kann, wie es Ihnen zum Zeitpunkt der Sammlung oder gemäß Ihrer Zustimmung
    beschrieben wurde. Wenn Sie sich dafür entscheiden, von uns angeforderte
    personenbezogenen Daten zurückzuhalten, kann es sein, dass Sie keinen Zugang
    zu bestimmten Teilen des Dienstes erhalten.
  </p>
  <p>
    <strong>
      <u>b) Informationen, die von Ihrem Arbeitgeber bereitgestellt werden</u>
    </strong>
  </p>
  <p>
    Ihr Arbeitgeber kann uns Informationen über Sie bereitstellen: (1) bei der
    Erstellung eines Benutzerprofils für Sie, um Ihnen den Zugriff auf die App
    im Rahmen der Vereinbarung zu ermöglichen oder um uns in die Lage zu
    versetzen, unsere Dienste einschließlich Textnachrichten bereitzustellen.
    Diese Informationen können Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefon-
    oder Mobiltelefonnummer, Ihre Berufsbezeichnung und Ihre Abteilung umfassen;
    (2) anderweitig im Rahmen der Nutzung der App durch Ihren Arbeitgeber.
  </p>
  <p>
    <strong>
      <u>c) über die App erfasste Informationen</u>
    </strong>
  </p>
  <p>
    Wenn Sie bestimmte Aktionen in dieser App ausführen, werden Sie
    möglicherweise aufgefordert, Zugang zu den folgenden Informationen zu
    gewähren.
  </p>
  <ol>
    <li>Identität</li>
    <li>Kalender</li>
    <li>Fotos/Medien/Dateien</li>
    <li>Mikrofon</li>
    <li>WLAN-Verbindung</li>
    <li>Geräte-ID</li>
  </ol>
  <p>Der Zugang wird für die folgenden Aktionen innerhalb der App benötigt:</p>
  <ul>
    <li>
      Die Identitätsanforderung dient ausschließlich dazu, den Kontoinhaber zu
      ermitteln.
    </li>
    <li>
      Die Kalenderanforderung bittet um die Berechtigung, auf Ihren Kalender
      zuzugreifen, damit die App Einträge Ihrer Wahl zu Ihrem Kalender
      hinzufügen kann.
    </li>
    <li>
      Die Anforderung für Fotos/Medien/Dateien ermöglicht es Ihnen, Dateien, die
      in Ihrer App enthalten sind, zur Offline-Nutzung zu speichern. Dazu
      benötigt die App Zugriff auf Ihre Dateien. Die App ändert Ihre
      Informationen nicht. Sie ermöglicht Ihnen lediglich, eine Datei Ihrer Wahl
      zu speichern, um später darauf zugreifen zu können, auch wenn keine
      Internetverbindung verfügbar ist.
    </li>
    <li>
      Die Mikrofonanforderung wird für Live-Events oder zur Aufnahme von Videos
      verwendet, die diese App unterstützen kann.
    </li>
    <li>
      Die WLAN-Verbindungsanforderung dient dazu, festzustellen, ob Sie eine
      WLAN-Verbindung oder eine Mobilfunkverbindung haben, um eine optimale
      Leistung der App zu gewährleisten.
    </li>
    <li>
      Die Geräte-ID-Anforderung ermöglicht es Ihnen, Push-Benachrichtigungen
      innerhalb der App zu erhalten.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) über unseren SMS-Dienst erfasste Informationen</u>
    </strong>
  </p>
  <p>
    Um unser SMS-Programm zu nutzen, werden Sie möglicherweise aufgefordert,
    Folgendes anzugeben: Ihre Mobiltelefonnummer und Ihre SMS-Präferenzen
    (Opt-in und Opt-out). Ihr Arbeitgeber verwendet diese Informationen, um
    unser SMS-Programm zu verwalten und Ihnen Textnachrichten zu senden. Ihr
    Mobilfunkanbieter kann Daten über die Nutzung Ihres drahtlosen Geräts
    sammeln, und seine Praktiken werden durch seine eigenen Richtlinien
    geregelt.
  </p>
  <p />
  <p>
    <strong>
      <u>e) Automatisch erfasste Informationen</u>
    </strong>
  </p>
  <p>
    Zu Analysezwecken kann unsere Plattform auch Zugriff auf Geräteinformationen
    wie Gerätetyp, Version, Betriebssystem sowie Browsertyp und -version (falls
    zutreffend) sowie auf Informationen über Ihre Nutzung der Dienste haben.
  </p>
  <p />
  <p>
    Wir können auch Informationen von Dritten erhalten, einschließlich Google
    Analytics, und diese mit den von uns erfassten Informationen kombinieren.{' '}
  </p>
  <h1>3. Wie verwenden wir Ihre Informationen?</h1>
  <p>
    Wir ergreifen Maßnahmen, um sicherzustellen, dass nur die Mitarbeiter Zugang
    zu Ihren personenbezogenen Daten haben, die diese zur Erfüllung ihrer
    beruflichen Pflichten benötigen.
  </p>
  <p>
    Wir verwenden die von uns erfassten Informationen auf verschiedene Weise, um
    die Dienste bereitzustellen und unser Geschäft zu betreiben. Dazu gehören:
  </p>
  <ul>
    <li>
      der Betrieb, die Wartung, die Verbesserung und die Bereitstellung aller
      Funktionen der Dienste, die Bereitstellung der von Ihnen angeforderten
      Dienste und Informationen, die Beantwortung von Kommentaren und Fragen und
      die Bereitstellung von Support für die Nutzer der Dienste;
    </li>
    <li>um unsere Verpflichtungen aus der Vereinbarung zu erfüllen;</li>
    <li>
      um die Nutzungstrends und Vorlieben unserer Nutzer zu verstehen und zu
      analysieren, um die Dienste zu verbessern und um neue Produkte, Dienste
      und Funktionen zu entwickeln;
    </li>
    <li>
      um Betrug oder Missbrauch Ihrer Produkte, Dienstleistungen und App zu
      verhindern und aufzudecken;
    </li>
    <li>
      um Ihnen vorbehaltlich der geltenden Gesetze Mitteilungen zukommen zu
      lassen;
    </li>
    <li>
      oder zur Erfüllung gesetzlicher und behördlicher Anforderungen, sofern
      zutreffend.
    </li>
  </ul>
  <h1>4. Mit wem teilen wir Ihre Informationen?</h1>
  <p>
    Unter bestimmten Umständen und um die Dienste zu erbringen, können wir
    bestimmte Informationen, die wir von Ihnen erheben, weitergeben:
  </p>
  <ul>
    <li>
      innerhalb unserer Unternehmensfamilie, zu der Muttergesellschaften,
      Konzerne, Tochtergesellschaften, Geschäftsbereiche und andere Unternehmen
      gehören können, die gemeinsame Eigentümer haben;
    </li>
    <li>mit Ihrem Arbeitgeber in übereinstimmung mit der Vereinbarung;</li>
    <li>
      mit Drittanbietern, die in unserem Auftrag arbeiten, Zugang zu Ihren Daten
      benötigen, um ihre Arbeit auszuführen (z. B. Kundenbetreuung,
      Rechnungsstellung, etc.);
    </li>
    <li>
      und an einen Erwerber, Nachfolger oder Abtretungsempfänger im Rahmen einer
      Fusion, übernahme oder ähnlichen Transaktion
    </li>
  </ul>
  <p>
    Wenn wir Ihre personenbezogenen Daten an Dritte weitergeben, ergreifen wir
    angemessene Maßnahmen, um sicherzustellen, dass die in dieser
    Datenschutzrichtlinie dargelegten Regeln eingehalten werden und diese
    Dritten ausreichende Garantien für die Umsetzung angemessener technischer
    und organisatorischer Maßnahmen bieten.
  </p>
  <p>
    Schließlich können wir bestimmte automatisch erfasste, zusammengefasste oder
    anderweitig nicht persönlich identifizierbare Informationen Dritten zu
    verschiedenen Zwecken zur Verfügung stellen, einschließlich (i) der
    Einhaltung verschiedener Berichtspflichten, (ii) zu Geschäfts- oder
    Marketingzwecken oder (iii) um diese Parteien dabei zu unterstützen, Ihre
    Interessen, Gewohnheiten und Nutzungsmuster für bestimmte Programme,
    Inhalte, Dienste und/oder Funktionen zu verstehen, die über die Dienste
    verfügbar sind.
  </p>
  <h1>5. Wie speichern wir Ihre Informationen?</h1>
  <p>
    Ihre personenbezogenen Daten können in jedem Land gespeichert und
    verarbeitet werden, in dem wir Einrichtungen haben oder in dem wir
    Dienstleister beauftragen. Durch den Besuch der Website, das Herunterladen
    der App oder die Nutzung der Dienste erklären Sie sich mit der übertragung
    von Informationen in Länder außerhalb Ihres Wohnsitzlandes einverstanden, in
    denen möglicherweise andere Datenschutzbestimmungen gelten als in Ihrem
    Land.{' '}
  </p>
  <p>
    Unsere Praktiken in Bezug auf Ihre personenbezogene Daten werden jedoch
    jederzeit durch diese Datenschutzrichtlinie geregelt, und wir werden
    gegebenenfalls die Anforderungen der Allgemeinen Datenschutzverordnung
    („DSGVO“) erfüllen, die einen angemessenen Schutz für die übertragung
    persönlicher Daten aus der EU/dem EWR in ein Drittland bietet.
  </p>
  <p>
    Wir haben verschiedene physische, administrative und technische
    Sicherheitsvorkehrungen getroffen, um die Vertraulichkeit und Sicherheit der
    von uns kontrollierten personenbezogenen Daten zu schützen. Allerdings sind
    keine Sicherheitsmaßnahmen absolut oder vollständig garantiert, und Sie
    müssen sich darüber im Klaren sein, dass immer ein gewisses Risiko besteht,
    dass die persönlichen Daten, die Sie uns zur Verfügung stellen, ohne Ihre
    Zustimmung und ohne Verschulden von theEMPLOYEEapp offengelegt werden. Wenn
    Sie glauben, dass Ihre personenbezogenen Daten kompromittiert wurden,
    kontaktieren Sie uns bitte wie im Abschnitt „Kontakt“ beschrieben. Wenn wir
    von einer Verletzung des Sicherheitssystems erfahren, werden wir Sie und die
    Behörden in übereinstimmung mit dem anwendbaren Recht über das Auftreten der
    Verletzung informieren.
  </p>
  <p>
    Wir werden Ihre personenbezogenen Daten nur so lange aufbewahren, wie es
    vernünftigerweise notwendig ist, um die in dieser Datenschutzrichtlinie
    dargelegten Zwecke zu erfüllen und um unseren gesetzlichen und behördlichen
    Verpflichtungen nachzukommen.{' '}
  </p>
  <h1>6. Rechte in Bezug auf Ihre personenbezogenen Daten</h1>
  <p>
    Wir erkennen an, dass Sie das Recht haben, auf Ihre personenbezogenen Daten
    zuzugreifen und diese zu korrigieren, in übereinstimmung mit geltendem Recht
    oder zusätzlichen Rechten gemäß der DSGVO. Wir erfassen Daten unter der
    Leitung Ihres Arbeitgebers und haben keine direkte Beziehung zu Ihnen. Wenn
    Sie nicht mehr von Ihrem Arbeitgeber kontaktiert werden möchten und/oder
    Ihre personenbezogenen Daten einsehen oder korrigieren möchten, wenden Sie
    sich bitte an Ihren Arbeitgeber, mit dem Sie direkt in Kontakt stehen.
  </p>
  <h1>7. Datenschutz für Kinder</h1>
  <p>
    Die Dienste richten sich nicht an Kinder unter 16 Jahren, und wir erfassen
    nicht wissentlich personenbezogene Daten von Kindern unter 16 Jahren, ohne
    die Zustimmung der Eltern einzuholen. Wenn Sie unter 16 Jahre alt sind,
    sollten Sie die Dienste zu keiner Zeit und in keiner Weise nutzen oder
    darauf zugreifen. Wenn wir erfahren, dass über die Dienste personenbezogene
    Daten von Personen unter 16 Jahren und ohne überprüfbare elterliche
    Zustimmung erfasst wurden, werden wir die entsprechenden Schritte
    unternehmen, um diese Daten zu löschen. Wenn Sie ein Elternteil oder
    Erziehungsberechtigter sind und feststellen, dass Ihr Kind unter 16 Jahren
    personenbezogene Daten zur Verfügung bereitgestellt hat, können Sie uns wie
    im Abschnitt „Kontakt“ beschrieben benachrichtigen und verlangen, dass wir
    die personenbezogenen Daten aus unseren Systemen löschen.
  </p>
  <h1>8. Aktualisierung</h1>
  <p>
    Wir werden diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren, um
    änderungen an unseren Praktiken, Technologien, rechtlichen Anforderungen und
    anderen Faktoren zu berücksichtigen. Bitte überprüfen Sie das
    „Gültigkeitsdatum“ oben auf dieser Seite, um zu sehen, wann diese
    Datenschutzrichtlinie zuletzt aktualisiert wurde. Wenn änderungen an dieser
    Datenschutzrichtlinie vorgenommen werden, treten sie sofort in Kraft, wenn
    sie in einer aktualisierten Datenschutzrichtlinie auf dieser Seite
    veröffentlicht werden, sofern nicht anders angegeben. Durch die Nutzung der
    Dienste nach diesen änderungen erklären Sie sich mit den in der
    aktualisierten Datenschutzrichtlinie beschriebenen Praktiken einverstanden.
  </p>
  <h1>9. Kontakt</h1>
  <p>
    Wenn Sie Fragen oder Anmerkungen zu dieser Datenschutzrichtlinie oder zu
    Ihren personenbezogenen Daten haben, um geltende Rechte auszuüben, eine
    Beschwerde einzureichen oder Informationen über unsere Richtlinien und
    Praktiken zu erhalten, können Sie unseren Datenschutzbeauftragten per Post
    oder E-Mail unter den folgenden Kontaktdaten erreichen:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default German
