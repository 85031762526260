import React from 'react'

function CS () {
  return (
<>
  <title>theEMPLOYEEapp - 隐私政策</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>隐私政策</h1>
  <h4>生效日期：2023年3月15日</h4>
  <p>
    theEMPLOYEEapp（以下称为"theEMPLOYEEapp"或"我们"）致力于向客户和用户展现其个人信息处理方式的透明性。为了达到此目的，本隐私政策描述我们如何收集、使用和披露个人信息，以及必要时如何查阅和纠正此类个人信息。
  </p>
  <p>
    访问<a href="https://theemployeeapp.com/">网站（以下称为"网站"）</a>
    、下载关联的移动应用程序（以下称为"应用"）或使用关联的服务（统称为"服务"）、联系我们或同意接收我们的邮件，即表示您同意本隐私政策的条款和条件。
  </p>
  <p>
    本隐私政策不适用于第三方运维的网站或服务。故我们不对他们关于个人信息保护的隐私政策、流程和实践负责。
  </p>
  <p>
    本隐私政策是我们的软件即服务协议（以下称为"协议"）的一部分，也从属于后者。
  </p>
  <h1>1.我们与您的雇主之间是何关系？</h1>
  <p>
    我们已与您的雇主达成协议，授权您访问应用和服务（"协议"）。我们将代表您的雇主，按照其合法指示处理您的个人信息。您向我们提供的，和/或通过应用上传的信息（不论是否被视为个人信息）也受协议约束。
  </p>
  <p>
    本隐私政策所指称的"您的雇主"是指与我们达成协议的实体，不论在法律意义上您是否为该实体的员工、顾问或合同工，而且该指称无意描述或损害您与该实体之间的状态。
  </p>
  <h1>2.我们收集哪些信息？</h1>
  <p>
    我们可能会收集的关于您的个人信息和其他类型信息主要来自以下来源：(a)
    您提供的信息；(b) 您的雇主提供的信息；(c) 通过应用收集的信息；(d)
    通过我们的短信服务提供的信息，以及 (e)
    自动收集的信息。我们处理个人信息的法律依据主要是，处理是提供服务的必要条件，是出于我们的合法利益开展的，"我们如何使用您的信息"章节进一步解释了该概念。我们还可能会酌情征求您的同意，并在您同意的情况下处理个人信息。
  </p>
  <p>
    <strong>
      <u>a) 您提供的信息</u>
    </strong>
  </p>
  <p>
    当您访问服务时，我们可能会请您自愿向我们提供特定的可直接或间接标识您个人身份的信息（"个人信息"）。个人信息包含（但不限于）以下类别的信息：(1)
    联系人数据（例如办公室地址、电子邮箱地址和手机号码）；(2)
    人口数据（例如您的邮政编码）；(3)
    职业信息（例如您的公司名称、您的职能和您的员工 ID）；以及 (4)
    其他您自愿选择提供给我们和/或您的雇主要求您提供的身份识别信息，包括但不限于您通过电子邮件或信件发送给我们的各类唯一标识符，例如密码、照片或个人信息。我们还可能收集附加信息（可能也是个人信息），并会在收集时单独向您描述，或者按照您的同意执行。如果您选择隐瞒我们请求的任何个人信息，那么您有可能无法获取服务特定部分的使用权限。
  </p>
  <p>
    <strong>
      <u>b) 您的雇主提供的信息</u>
    </strong>
  </p>
  <p>
    您的雇主可能会向我们提供您的信息：(1)
    为了让您能够在协议范围内访问应用，或者为了让我们能够提供包括短信在内的服务为您创建用户档案时。此类信息可能包含您的姓名、电子邮箱地址、电话号码或手机号码、职位、部门；(2)
    您的雇主使用应用过程中以其他方式提供。
  </p>
  <p>
    <strong>
      <u>c) 通过应用收集的信息</u>
    </strong>
  </p>
  <p>
    当在本应用中执行特定操作时，您可能会收到关于提供以下访问权限的提示。您可以选择不执行任何操作。
  </p>
  <ol type={1}>
    <li>身份信息</li>
    <li>日历</li>
    <li>照片/媒体/文件</li>
    <li>麦克风</li>
    <li>WiFi 连接</li>
    <li>设备 ID</li>
  </ol>
  <p>应用内的以下操作需要用到访问权限：</p>
  <ul>
    <li>关于身份信息的请求仅用于确定帐户属于谁。</li>
    <li>
      关于日历的请求是为了获取您的日历的访问权限，以便应用能够将您选择的项目添加到您的日历中。
    </li>
    <li>
      关于照片/媒体/文件的请求是为了保存您的应用中包含的文件用于离线使用。为了做到这一点，应用需要访问您的文件。本应用不会篡改您的信息。它只会让您保存您选择的文件以待后续访问，即便没有网络连接时也可以访问。
    </li>
    <li>关于麦克风的请求用于直播活动或录制视频，这些在本应用支持的范围内。</li>
    <li>
      关于WiFi 连接的请求是为了确定您使用的是 WiFi
      还是蜂窝网络连接，以便实现应用的最佳性能。
    </li>
    <li>关于设备 ID 的请求是为了在应用内推送通知。</li>
  </ul>
  <p>
    <strong>
      <u>d) 通过我们的短信服务收集的信息</u>
    </strong>
  </p>
  <p>
    使用我们的短信前，您可能会被要求提供：您的手机号码和您的短信内容偏好（选择接收和选择停止接收）。您的雇主使用此信息管理我们的短信程序，包括给您发送短信。您的无线服务提供商可能会收集关于您的无线设备使用的数据，其实践受其自身政策约束。
  </p>
  <p>
    <strong>
      <u>e) 自动收集的信息</u>
    </strong>
  </p>
  <p>
    出于数据分析目的，我们的平台可能还会访问设备信息，例如设备类型、版本、操作系统和浏览器的类型及版本（如条件适用）以及关于的服务使用信息。{' '}
  </p>
  <p>
    我们可能也会从第三方获取信息，包括 Google
    Analytics，并将此类信息与我们收集的信息结合在一起。{' '}
  </p>
  <h1>3.我们如何使用您的信息？</h1>
  <p>
    我们采取的措施可确保只有因为要履行职责而需要访问您个人信息的员工有权限访问。
  </p>
  <p>我们将我们通过各种方式收集的信息用于提供服务和开展业务，包括：</p>
  <ul>
    <li>
      运营、维护、增强和提供服务的各项功能，提供您请求的服务和信息，回复评论和提问，以及为服务的用户提供支持；
    </li>
    <li>履行协议规定的义务；</li>
    <li>
      了解和分析我们的用户的使用趋势和偏好，改进服务，以及开发新产品、服务、特性和功能；
    </li>
    <li>防止和检测诈骗或产品、服务和应用的滥用；</li>
    <li>向您发送符合适用法律规定的通信内容；</li>
    <li>或者在适用的情况下遵循法律法规要求。</li>
  </ul>
  <h1>4.我们与谁分享您的信息？</h1>
  <p>在特定情况下，以及为了执行服务，我们可能会披露向您收集的特定信息：</p>
  <ul>
    <li>
      在我们的公司谱系内披露，可能包含母公司、集团、附属公司、子公司、业务单元和其他共享共同所有权的公司；
    </li>
    <li>按照协议的规定披露给您的雇主；</li>
    <li>
      披露给代表我们索取您的信息的访问权限并开展工作的第三方服务提供商（例如客户支持、计费等）；
    </li>
    <li>以及披露给任何合并、收购或相似交易中的收购方、继任方或受让人</li>
  </ul>
  <p>
    当我们向第三方披露您的个人信息时，我们会采取合理措施，确保本隐私政策设定的规则得到落实，此类第三方提供足够的保障来施行技术和组织措施。
  </p>
  <p>
    最后我们可能出于各种目的向第三方提供特定的自动收集的、汇总的或其他不可识别个人身份的信息，包括
    (i) 遵循各种报告义务；(ii) 出于商务或市场营销目的；或者 (iii)
    协助该类第三方了解您对服务中的特定程序、内容、服务和/或功能的兴趣、习惯和使用规律。
  </p>
  <h1>5.我们如何储存您的信息？</h1>
  <p>
    我们可能会在任何我们拥有设施或者我们接触服务提供商的国家和地区储存和处理您的个人信息。访问网站、下载应用或使用服务，即表示您同意将信息传输至您所居住的国家和地区以外的国家和地区，后者的数据保护规则可能与您所在国家和地区的不同。{' '}
  </p>
  <p>
    然而，我们关于您个人信息的实践仍将始终受到本隐私政策的约束，并且在符合条件的情况下，我们会遵循《通用数据保护条例》（以下称为"GDPR"）关于从欧盟/欧洲经济区向第三国传输个人信息时须提供充分保护的要求。
  </p>
  <p>
    我们已在实体、行政和技术层面实施多项保障措施，这些措施的设计宗旨是保护在我们控制下的个人信息的机密性和安全性。然而，没有安全保护措施是绝对可靠和完全有保障的，您必须知晓，您提供给我们的个人信息将始终面临在不经过您同意且theEMPLOYEEapp没有过错的情况下被披露的风险。如果您认为自己的个人信息已泄露，请根据"联系我们"章节的陈述联系我们。如果我们得知有安全系统漏洞，我们将按照适用法律的规定，向您和当局通报此漏洞。
  </p>
  <p>
    我们只会将您的个人信息保留合理必要的时长，用于实现本隐私政策所述的相关目的，以及遵守我们的法律法规义务。{' '}
  </p>
  <h1>6.关于您个人信息的权利</h1>
  <p>
    我们认可按照适用法律您有权利访问和纠正您的个人信息，或者 GDPR
    规定的附加权利。我们在您雇主的指示下收集信息，与您没有直接关系。如果您希望以后不再被您的雇主联系并/或希望纠正您的个人信息，请联系与您直接互动的雇主。
  </p>
  <h1>7.儿童隐私</h1>
  <p>
    服务不面向 16
    周岁以下的儿童，而且在没有获取家长同意的情况下，我们不会可以收集 16
    周岁以下儿童的个人信息。如果您未满 16
    周岁，那么请您任何时候都不要以任何方式使用或访问服务。如果我们得知在没有获得可证实的家长同意的情况下通过服务收集了
    16
    周岁以下人士的个人信息，那么我们会采取相应措施来删除此类信息。如果您是家长或者监护人，并发现您未满
    16
    周岁的孩子提供了个人信息，那么您可以按照"联系我们"章节的规定提醒我们，并要求我们从系统删除孩子的个人信息。
  </p>
  <h1>8.更新</h1>
  <p>
    我们会不定期更新本隐私政策来体现我们的实践、技术、法律要求和其他因素的变化。请查看本页面顶部的"生效日期"，了解本隐私政策上次更新的具体时间。当隐私政策发生变更时，一旦发表在本页面发布的更新后的隐私政策中，就会立即生效，另有说明的除外。发生上述变更后如果您继续使用服务，则表示您同意更新后的隐私政策所描述的实践。
  </p>
  <h1>9.联系我们</h1>
  <p>
    如果您有关于本隐私政策或您的个人信息的疑问或意见，或希望行使适用的权利，提出投诉，或者获取关于我们政策和实践的信息，可通过以下联系方式联系我们的隐私官：
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default CS
