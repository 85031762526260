// @flow
import React, { useEffect, useState, useRef } from 'react'
import Card from 'michelangelo/dist/SharedComponents/ContentCard/ContentCard'
import styled from 'styled-components'
import DateHelper from '../../Helpers/DateHelper'
import { getLanguageTagFromLocale, shouldShowTranslate } from '../../Helpers/TranslationHelper'
import Comments from '../Components/Comments'
import { getSocialCountData } from '../../Helpers/SocialDataHelper'
import toLower from 'lodash/toLower'
import * as copy from 'copy-to-clipboard'
import { hermesUrl } from '../../config'
import axios from 'axios'

import GET_SIGNED_DOWNLOAD_URL from '../../apollo/queries/getSignedDownloadUrl'
import { useLazyQuery, useMutation } from '@apollo/client'
import ADD_LIKE_QUERY from '../../apollo/queries/addLike'
import REMOVE_LIKE_QUERY from '../../apollo/queries/removeLike'

import { trackLikeEvent, trackContentOpened, trackContentShared, trackContentSaved } from '../../Helpers/segmentHelper'
import is from 'is_js'
import VideoPlayer from '../../Components/VideoPlayer/VideoPlayer'
import AudioPlayer from '../../Components/AudioPlayer/AudioPlayer'
import { contentShareType } from '../../Helpers/contentHelper'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { messages } from '../../i18n/messages'
import SEND_EMAIL_QUERY from '../../apollo/queries/sendEmail'
import { checkIsReadAcknowledgement } from '../../Helpers/ReadAcknowledgement'
import OverlayPage from '../../Components/OverlayPage'
import GET_CONTENT_BY_ID_QUERY from '../../apollo/queries/getContentById'
import { useNavigate } from 'react-router-dom'
import { readFromCache } from '../../apollo/cacheHelper'
import { isHashtag } from '../../Helpers/hashtagHelper'
import DownloadImageGalleryModal from './DownloadImageModal'

const ContentCardContainer = styled.div`
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.08);
`
const CardParent = styled.div`
  margin-bottom: 1px;
  cursor: pointer;
`
// added for testing purposes
ContentCardContainer.displayName = 'ContentCardContainer'
export type ContentType = {
  _id: string,
  contentType: ?string,
  owner: ?string,
  postDate: string,
  likesEnabled: ?boolean,
  totalLikes: ?number,
  commentsEnabled: ?boolean,
  likedByUser: ?boolean,
  calendar: ?string,
  s3Key: ?string,
  webUrl: ?string,
  htmlSignedDownloadUrl: ?string,
  signedDownloadUrl: string,
  hlsVideoPlaylistUrl?: string,
  mediumThumbnailUrl?: string,
  contentListPosition?: number,
  ownerName: ?string,
  secure?: boolean,
  translatedTitle?: string,
  translatedCaption?: string,
  caption: ?string,
  ownerPhoto: Object,
  childContents: ?Array<Object>
}
type ContentCardType = {
  me: Object,
  appBrandColor: string,
  addNotification?: Function,
  onDelete?: () => void,
  savedContent?: Array<Object>,
  inProgressContent?: Array<string>,
  contentId?: string,
  isDarkTheme: boolean,
  content: ContentType,
  likesEnabled: boolean,
  commentsEnabled: boolean,
  onContentPreviewClick?: Function,
  isContentPreview?: boolean,
  client?: Object,
  contentLocation: String,
  noCommentList: boolean,
  onCommentButtonClick?: Function,
  hideImage: boolean
} & InjectIntlProvidedProps

function ContentCard (props: ContentCardType) {
  const { appBrandColor, isDarkTheme, content, likesEnabled, onCommentButtonClick, commentsEnabled, me, intl: { formatMessage, locale }, onContentPreviewClick, isContentPreview, isTransparent, noCommentList, client, contentLocation } = props
  const [itemContent, setItemContent] = useState<ContentType>(content)
  const [isLiked, setIsLiked] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [socialCountData, setSocialCountData] = useState(getSocialCountData(content, likesEnabled, commentsEnabled, formatMessage))
  const [timeAgo, setTimeAgo] = useState('')
  const [caption, setCaption] = useState('')
  const [isTranslated, setIsTranslated] = useState(false)
  const contentSubLocation = isContentPreview ? 'CONTENT_DETAILS' : 'CONTENT_CARD'
  const [createLike] = useMutation(ADD_LIKE_QUERY)
  const [removeLike] = useMutation(REMOVE_LIKE_QUERY)
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  const navigate = useNavigate()
  const seeTranslationText = formatMessage(messages.seeTranslation)
  const seeOriginalText = formatMessage(messages.seeOriginal)
  const lang = getLanguageTagFromLocale(locale)
  const shouldTranslate = shouldShowTranslate(lang)
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled
  const { activeCfp } = readFromCache(client, ['activeCfp'])
  const [downloadImageModal, setDownloadImageModal] = useState(false)

  const [getSignedDownloadUrl, { loading, data }] = useLazyQuery(
    GET_SIGNED_DOWNLOAD_URL,
    { variables: { s3Key: content.s3Key, accountId: me.memberships[0].account._id } }
  )

  const [getContentById, { data: contentData, loading: getContentByIdLoading }] = useLazyQuery(GET_CONTENT_BY_ID_QUERY(displayContentAuthorEnabled, shouldTranslate),
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })

  /* eslint-disable */
  useEffect(() => {
    let item = content
    const socialData = getSocialCountData(item, likesEnabled, commentsEnabled, formatMessage)
    setSocialCountData(socialData)
    setTimeAgo(DateHelper.getDateFromNow(new Date(item.postDate)))
    setIsLiked(item.likedByUser)
  }, [content])

  useEffect(() => {
    if (contentData && contentData.getContentById && !getContentByIdLoading) {
      setItemContent({ ...contentData.getContentById, loading: false })
    }
  }, [contentData])

  useEffect(() => {
    async function formatCaption () {
      let caption = isTranslated && itemContent.translatedCaption ? itemContent.translatedCaption : itemContent.caption
      caption = caption || ''
      let matches = caption.match(/@[a-z\d]{24}/g) || []

      let newCaption = caption
      if (matches != null) {
        for (const match of matches) {
          let userId = match.substr(1)
          let user = content.mentions.find(u => u.id === userId)
          if (user) {
            newCaption = newCaption.replace(match, '['+user.name+']('+userId+')')
          } else {
            newCaption = newCaption.replace(match, '[Deleted User]()')
          }
        }
      }
      setCaption(newCaption)
    }
    formatCaption()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* eslint-enable */

  useEffect(() => {
    itemContent && setSocialCountData(getSocialCountData(itemContent, likesEnabled, commentsEnabled, formatMessage))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsEnabled, itemContent, likesEnabled])

  useEffect(() => {
    const handleFileDownload = async () => {
      if (loading || !data || !isSaving) return
      const link = data.getSignedDownloadUrl.signedUrl
      const response = await axios.get(link, { responseType: 'blob' })
      const fileName = content.s3Key ? content.s3Key.replace('/', '_') : ''
      if (window.navigator.msSaveOrOpenBlob && (is.ie() || is.edge())) {
        const fileData = [response.data]
        const blobObject = new Blob(fileData)
        window.navigator.msSaveOrOpenBlob(blobObject, fileName)
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.setAttribute('download', fileName)
        const body = document.body
        if (body) body.appendChild(a)
        a.click()
        a.remove()
      }
      setIsSaving(false)
    }
    handleFileDownload()
  }, [content, loading, data, isSaving])

  const checkIsRead = async () => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, itemContent, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const onShareClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) {
      throw new Error('onShareClick Cancel Read Acknowledgement')
    }

    const shareUrl = `${hermesUrl}/content/${content._id}`
    try {
      copy(shareUrl)
      await trackContentShared({ ...content, contentLocation, contentSubLocation }, contentShareType.LINK_COPIED, client)
    } catch (e) {
      console.error(e, 'error')
      return false
    }
    return true
  }

  const commentsRef = useRef(null)
  const onCommentClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return

    // has comments
    if (commentsRef.current) commentsRef.current.setInputFocus()
  }

  const onSaveClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) {
      setIsSaving(false)
      throw new Error('onSaveClick Cancel Read Acknowledgement')
    }
    if (content.contentType === 'CONTENT_MISC') {
      openDownloadModal()
      return
    }
    try {
      if (isSaving) return // prevent click
      setIsSaving(true) // show loading state on the save button
      await getSignedDownloadUrl() // call query
      await trackContentSaved({ ...content, contentLocation, contentSubLocation }, client)
    } catch (e) {
      setIsSaving(false)
    }
  }
  const labels = {
    like: formatMessage(messages.like),
    comment: formatMessage(messages.comment),
    share: formatMessage(messages.share),
    save: isSaving ? formatMessage(messages.saving) : formatMessage(messages.save),
    remove: isSaving ? formatMessage(messages.removing) : formatMessage(messages.remove),
    captionMore: toLower(formatMessage(messages.more))
  }

  const onPlayClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return

    alert('onPlayClick')
  }

  const onLikeClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return

    const likeRequest = {
      variables: {
        like: {
          account: me.memberships[0].account._id,
          item: content._id,
          itemType: 'CONTENT',
          owner: me._id,
          created: content.postDate
        }
      }
    }
    if (content.likedByUser) {
      await removeLike(likeRequest)
      await trackLikeEvent(false, { ...content, contentLocation, contentSubLocation }, client)
    } else {
      await createLike(likeRequest)
      await trackLikeEvent(true, { ...content, contentLocation, contentSubLocation }, client)
    }
  }

  const onWebLinkClick = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return
    await trackContentOpened({ ...content, contentLocation }, client)
    window.open(content.webUrl, '_blank')
  }

  const handleVideoControlsClick = (target: HTMLElement) => {
    const videoJsEl = target.closest('.video-js')
    const wasPlaying = videoJsEl ? videoJsEl.classList.contains('vjs-playing') : false
    const firstLoad = target.classList.contains('vjs-tech')
    const bigPlayButton = target.classList.contains('vjs-big-play-button')
    const playPauseButton = target.classList.contains('vjs-icon-placeholder')
    const volume = target.classList.contains('vjs-volume-bar') || target.classList.contains('vjs-volume-level')
    const currentTime = target.classList.contains('vjs-current-time') || target.classList.contains('vjs-current-time-display')
    const duration = target.classList.contains('vjs-duration') || target.classList.contains('vjs-duration-display')
    const poster = target.classList.contains('vjs-poster')
    const controlBar = target.classList.contains('vjs-control-bar')
    if (bigPlayButton || playPauseButton || volume || currentTime || duration) return true
    if (wasPlaying && poster) return true
    if (wasPlaying && controlBar) return false
    if (firstLoad || controlBar) return false
    return true
  }

  const handleAudioControlsClick = (target: HTMLElement) => {
    const playButton = target.id === 'play-button'
    const pauseButton = target.id === 'pause-button'
    const progressBar = target.id === 'progress-bar'
    const thumbnail = target.id === 'thumbnail'
    const timeContainer = target.id === 'time-container'
    return playButton || pauseButton || progressBar || thumbnail || timeContainer
  }

  const openProvisoryContentPreview = async () => {
    const isRead = await checkIsRead()
    // if was cancelled
    if (!isRead) return

    let urlToOpen
    if (content.webUrl) urlToOpen = content.webUrl
    else urlToOpen = content.htmlSignedDownloadUrl ? content.htmlSignedDownloadUrl : content.signedDownloadUrl
    window.open(urlToOpen)
  }

  const handleOnContentPreviewClick = async (e) => {
    if (content.contentType === 'CONTENT_AUDIO' || content.contentType === 'CONTENT_VIDEO') e.persist()
    if (content.contentType !== 'CONTENT_AUDIO' && content.contentType !== 'CONTENT_VIDEO') {
      const isRead = await checkIsRead()
      // if was cancelled
      if (!isRead) return
    }

    switch (content.contentType) {
      case 'CONTENT_IMAGE':
      case 'CONTENT_PDF':
      case 'CONTENT_DOCX':
      case 'CONTENT_PPTX':
      case 'CONTENT_XLSX':
      case 'CONTENT_RICH_TEXT':
        if (onContentPreviewClick) onContentPreviewClick(content)
        break
      case 'CONTENT_MISC':
        if (onContentPreviewClick) onContentPreviewClick(content, e.id ? e : null)
        break
      case 'CONTENT_WEB':
        if (!content.likesEnabled && !content.commentsEnabled) {
          openProvisoryContentPreview()
        } else {
          if (onContentPreviewClick) onContentPreviewClick(content)
        }
        break
      case 'CONTENT_AUDIO':
        if (handleAudioControlsClick(e.target)) {
          return false
        } else {
          const isRead = await checkIsRead()
          // if was cancelled
          if (!isRead) return
          if (onContentPreviewClick) onContentPreviewClick(content)
        }
        break
      case 'CONTENT_VIDEO':
        // prevent clicking on the player controls to open the preview
        if (handleVideoControlsClick(e.target)) {
          return false
        } else {
          // handle case when video is on full screen and we don't click on the video player controls
          // to prevent opening the preview
          const video = document.getElementsByClassName('video-js')
          if (video.length && video[0].classList.contains('vjs-fullscreen')) {
            return false
          } else {
            if (onContentPreviewClick) onContentPreviewClick(content)
          }
        }
        break
      default:
        openProvisoryContentPreview()
        break
    }
    await trackContentOpened({ ...content, contentLocation }, client)
  }

  const shareNotification = () => ({
    bannerText: formatMessage(messages.linkSuccess),
    icon: 'link',
    type: 'success'
  })

  const saveNotification = () => ({
    bannerText: formatMessage(messages.contentDownloaded),
    icon: 'download',
    type: 'info'
  })

  const getVideoPlayer = () => {
    return (
      <VideoPlayer
        {...(content.mediumThumbnailUrl ? { poster: content.mediumThumbnailUrl } : null)}
        fluid={true}
        inactivityTimeout={100}
        controls={true}
        sources={[
          {
            src: content.hlsVideoPlaylistUrl || content.signedDownloadUrl || ''
          }
        ]}
        content={content}
        client={client}
        me={me}
        formatMessage={formatMessage}
        contentLocation={contentLocation}
      />
    )
  }

  const getAudioPlayer = () => {
    return <AudioPlayer src={content.signedDownloadUrl} thumbnail={content.mediumThumbnailUrl} content={content} client={client} me={me} formatMessage={formatMessage} contentLocation={contentLocation}/>
  }

  const getMediaPlayer = () => {
    return content.contentType === 'CONTENT_VIDEO' ? getVideoPlayer() : content.contentType === 'CONTENT_AUDIO' ? getAudioPlayer() : null
  }

  const renderTranslate = async () => {
    // contentPreview is calling getContentById in its own
    if (!isContentPreview) {
      if (!itemContent.translatedTitle) setItemContent({ ...itemContent, loading: true })
      getContentById({ variables: { contentId: itemContent._id, lang } })
    }

    if (contentData && !getContentByIdLoading) {
      setItemContent({ ...itemContent, translatedTitle: contentData.translatedTitle, translatedCaption: contentData.translatedCaption, loading: false })
      setIsTranslated(translated => !translated)
    }
  }

  const handleHashtagClick = (hashtag) => {
    let hashtagValue = hashtag
    if (isHashtag(hashtag)) {
      hashtagValue = hashtag.substring(1)
    }
    navigate({
      pathname: `/${activeCfp._id}/hashtags/${hashtagValue}`
    })
  }

  const handleArrowClick = async (direction) => {
    await trackContentOpened({ ...content, contentLocation }, client)
  }

  const openDownloadModal = () => {
    setDownloadImageModal(true)
  }

  const onRequestClose = async (downloaded) => {
    try {
      if (downloaded) {
        setIsSaving(true) // show loading state on the save button
        await getSignedDownloadUrl() // call query
        await trackContentSaved({ ...content, contentLocation, contentSubLocation }, client)
      }
      setIsSaving(false)
      setDownloadImageModal(false)
    } catch (e) {
      setIsSaving(false)
      setDownloadImageModal(false)
    }
  }

  return (
    <ContentCardContainer >
      <OverlayPage visible={showOverlay}/>
      <CardParent>
        <Card
          hideImage={props.hideImage ? props.hideImage : false}
          profilePhoto={itemContent.ownerPhoto && itemContent.ownerPhoto._32px}
          testID={itemContent._id}
          darkTheme={isDarkTheme}
          content={{ ...itemContent, likesEnabled: itemContent.likesEnabled && likesEnabled, commentsEnabled: itemContent.commentsEnabled && commentsEnabled }}
          appBrandColor={appBrandColor}
          timeAgo={timeAgo}
          onShareClick={onShareClick}
          onCommentClick={onCommentButtonClick || onCommentClick}
          labels={labels}
          onSaveClick={onSaveClick}
          onLikeClick={onLikeClick}
          liked={isLiked}
          onWebLinkClick={onWebLinkClick}
          saving={isSaving}
          onContentPreviewClick={handleOnContentPreviewClick}
          onPlayClick={onPlayClick}
          socialCountsData={socialCountData}
          shareNotification={shareNotification()}
          saveNotification={saveNotification()}
          mediaPlayer={getMediaPlayer()}
          isContentPreview={isContentPreview}
          isTransparent={isTransparent}
          renderTranslate={renderTranslate}
          showTranslateButton={shouldTranslate}
          seeTranslationText={seeTranslationText}
          seeOriginalText={seeOriginalText}
          disableSaving={content.disableSaving}
          caption={caption}
          onHashtagClick={handleHashtagClick}
          onArrowClick={handleArrowClick}
        />
        {
          itemContent.contentType && itemContent.contentType === 'CONTENT_MISC'
            ? <>
                <DownloadImageGalleryModal accountId={ me.memberships[0].account._id } isOpen={ downloadImageModal } onRequestClose={onRequestClose.bind(this)} content={itemContent}></DownloadImageGalleryModal>
              </>
            : null
        }
      </CardParent>
      {!noCommentList && itemContent.commentsEnabled && commentsEnabled ? <Comments testID={itemContent._id} ref={commentsRef} content={ content } client={client} /> : null }
    </ContentCardContainer>
  )
}

export default injectIntl(ContentCard)
