import React from 'react'

function Malay () {
  return (
<>
  <title>theEMPLOYEEapp - Dasar Privasi</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Dasar Privasi</h1>
  <h4>Tarikh Berkuat Kuasa: 15 Mac 2023</h4>
  <p>
    theEMPLOYEEapp ("theEMPLOYEEapp", atau "kami" ) komited untuk memberikan
    ketelusan kepada pelanggan dan penggunanya berkaitan dengan amalannya dalam
    mengendalikan maklumat peribadi mereka. Untuk itu, Dasar Privasi ini
    menerangkan cara kami mengumpul, menggunakan dan mendedahkan maklumat
    peribadi dan cara maklumat peribadi ini boleh dirujuk dan diperbetulkan
    apabila perlu.
  </p>
  <p>
    Dengan melawati{' '}
    <a href="https://theemployeeapp.com/">laman web ("Laman Web")</a>, memuat
    turun aplikasi mudah alih yang berkaitan ("Aplikasi") atau menggunakan
    perkhidmatan yang berkaitan (secara kolektif "Perkhidmatan"), dengan
    menghubungi kami atau bersetuju menerima e-mel daripada kami, anda menerima
    terma dan syarat Dasar Privasi ini.
  </p>
  <p>
    Dasar Privasi ini tidak meliputi laman web atau perkhidmatan yang
    dikendalikan oleh pihak ketiga. Oleh itu, kami tidak bertanggungjawab ke
    atas dasar privasi, prosedur dan amalan mereka berkaitan perlindungan
    maklumat peribadi.
  </p>
  <p>
    Dasar Privasi ini dimasukkan ke dalam, dan tertakluk pada Perisian sebagai
    Perjanjian Perkhidmatan ("Perjanjian") kami
  </p>
  <h1>1. Apakah Hubungan Kami Dengan Majikan Anda?</h1>
  <p>
    Kami telah memeterai perjanjian dengan majikan anda untuk memberikan anda
    akses kepada Aplikasi dan Perkhidmatan ("Perjanjian"). Kami akan memproses
    Maklumat Peribadi anda bagi pihak majikan anda dan mengikut arahannya yang
    sah. Maklumat yang anda berikan kepada kami dan/atau dimuat naik melalui
    Aplikasi (sama ada merupakan Maklumat Peribadi atau tidak) juga akan dikawal
    oleh Perjanjian.
  </p>
  <p>
    Rujukan dalam Dasar Privasi ini untuk "majikan anda" hendaklah merujuk
    kepada entiti yang telah memeterai Perjanjian dengan kami, sama ada mengikut
    undang-undang atau tidak, bahawa anda adalah pekerja, perunding atau
    kontraktor bagi entiti tersebut, dan rujukan tersebut bukan bermaksud untuk
    mencirikan atau prejudis terhadap status anda berkaitan entiti tersebut.
  </p>
  <h1>2. Apakah Maklumat Yang Kami Kumpul?</h1>
  <p>
    Jenis-jenis maklumat peribadi dan maklumat lain yang mungkin kami kumpulkan
    tentang anda adalah terutamanya daripada sumber berikut: (a) maklumat yang
    anda berikan; (b) maklumat yang diberikan oleh majikan anda; (c) maklumat
    yang dikumpulkan melalui Aplikasi; d) maklumat yang dikumpulkan melalui
    perkhidmatan SMS kami dan (e) maklumat yang dikumpulkan secara automatik.
    Asas undang-undang untuk pemprosesan Maklumat Peribadi kami adalah
    terutamanya bahawa pemprosesan adalah perlu untuk menyediakan Perkhidmatan
    dan bahawa pemprosesan dijalankan untuk kepentingan sah kami, yang
    diterangkan selanjutnya dalam bahagian 'Bagaimanakah Kami Menggunakan
    Maklumat Anda?'. Kami juga mungkin memproses Maklumat Peribadi atas
    persetujuan anda, memintanya mengikut kesesuaian.
  </p>
  <p>
    <strong>
      <u>a) Maklumat yang Anda Berikan</u>
    </strong>
  </p>
  <p>
    Apabila anda mengakses Perkhidmatan, kami mungkin meminta anda untuk dengan
    sukarela memberikan kepada kami maklumat tertentu yang mengenal pasti anda
    secara peribadi atau boleh digunakan untuk mengenal pasti anda secara
    peribadi ('Maklumat Peribadi'). Maklumat Peribadi termasuk (tetapi tidak
    terhad kepada) kategori maklumat berikut: (1) data untuk dihubungi (seperti
    alamat pejabat, alamat e-mel dan nombor telefon anda); (2) data demografi
    (seperti poskod anda); (3) maklumat profesional (seperti nama syarikat anda,
    fungsi anda dan ID pekerja anda); dan (4) maklumat pengenalan lain yang anda
    pilih secara sukarela untuk diberikan kepada kami dan/atau dikehendaki oleh
    majikan anda, termasuk tanpa had pengecam unik seperti kata laluan, foto
    atau Maklumat Peribadi dalam e-mel atau surat yang anda hantar kepada kami.
    Kami juga mungkin mengumpul maklumat tambahan, yang berkemungkinan Maklumat
    Peribadi, seperti yang diterangkan kepada anda pada masa pengumpulan
    tersebut atau menurut persetujuan anda. Jika anda memilih untuk enggan
    memberikan sebarang Maklumat Peribadi yang kami minta, anda mungkin tidak
    boleh mendapatkan akses kepada bahagian tertentu Perkhidmatan tersebut.
  </p>
  <p>
    <strong>
      <u>b) Maklumat Diberikan oleh Majikan anda</u>
    </strong>
  </p>
  <p>
    Majikan anda mungkin memberi kami maklumat tentang anda: (1) semasa mencipta
    profil pengguna untuk anda bagi membolehkan anda mengakses Aplikasi di bawah
    Perjanjian atau membolehkan kami menyediakan Perkhidmatan kami termasuk
    pemesejan teks. Maklumat ini mungkin termasuk nama, alamat e-mel, nombor
    telefon atau telefon bimbit anda, jawatan, jabatan; (2) sebaliknya semasa
    penggunaan Aplikasi oleh majikan anda.
  </p>
  <p>
    <strong>
      <u>c) Maklumat Dikumpul melalui Aplikasi</u>
    </strong>
  </p>
  <p>
    Apabila melakukan tindakan tertentu dalam aplikasi ini, anda mungkin digesa
    untuk memberikan akses kepada yang berikut. Anda boleh menarik diri daripada
    setiap tindakan.
  </p>
  <ol>
    <li>Identiti</li>
    <li>Kalendar</li>
    <li>Foto/Media/Fail</li>
    <li>Mikrofon</li>
    <li>Sambungan Wifi</li>
    <li>ID peranti</li>
  </ol>
  <p>Akses akan diperlukan untuk tindakan berikut dalam Aplikasi:</p>
  <ul>
    <li>
      Permintaan Identiti adalah semata-mata untuk menentukan pemilik akaun itu.
    </li>
    <li>
      Permintaan Kalendar meminta keizinan untuk mengakses kalendar anda supaya
      aplikasi boleh menambah item pilihan anda pada kalendar anda.
    </li>
    <li>
      Permintaan untuk Foto/Media/Fail membolehkan anda menyimpan fail yang
      disertakan dalam aplikasi anda untuk kegunaan luar talian. Untuk melakukan
      ini, aplikasi memerlukan akses kepada fail anda. Aplikasi ini tidak
      mengubah maklumat anda. Ia hanya membenarkan anda menyimpan fail pilihan
      anda untuk akses pada masa akan datang, walaupun sambungan internet tidak
      tersedia.
    </li>
    <li>
      Permintaan Mikrofon digunakan untuk acara langsung atau untuk merakam
      video, yang boleh disokong oleh aplikasi ini.
    </li>
    <li>
      Permintaan sambungan Wifi adalah untuk menentukan sama ada anda
      menggunakan sambungan Wifi atau selular untuk prestasi optimum aplikasi.
    </li>
    <li>
      Permintaan ID Peranti membolehkan anda menerima pemberitahuan tolak dalam
      aplikasi.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) maklumat yang dikumpul melalui perkhidmatan SMS kami</u>
    </strong>
  </p>
  <p>
    Untuk menggunakan SMS kami, anda mungkin digesa untuk memberikan: nombor
    telefon mudah alih anda dan pilihan teks anda (ikut serta dan tidak ikut
    serta). Majikan anda menggunakan maklumat ini untuk mengurus program
    pemesejan teks kami, termasuk menghantar mesej teks kepada anda. Pembawa
    wayarles anda mungkin mengumpul data tentang penggunaan peranti wayarles
    anda, dan amalannya dikawal oleh dasarnya sendiri.
  </p>
  <p>
    <strong>
      <u>e) Maklumat Dikumpul Secara Automatik</u>
    </strong>
  </p>
  <p>
    Untuk tujuan analisis, platform kami juga mungkin mempunyai akses kepada
    maklumat peranti seperti jenis peranti, versi, sistem pengendalian dan jenis
    dan versi pelayar (jika berkenaan) serta maklumat mengenai penggunaan
    Perkhidmatan oleh anda.
  </p>
  <p>
    Kami juga boleh mendapatkan maklumat daripada pihak ketiga, termasuk Google
    Analytics, dan menggabungkannya dengan maklumat yang kami kumpulkan.
  </p>
  <h1>3. Bagaimanakah Kami Menggunakan Maklumat Anda?</h1>
  <p>
    Kami mengambil langkah yang direka untuk memastikan bahawa hanya pekerja
    yang memerlukan akses kepada Maklumat Peribadi anda untuk memenuhi tugas
    pekerjaan mereka akan mendapat akses kepadanya.
  </p>
  <p>
    Kami menggunakan maklumat yang kami kumpulkan dalam pelbagai cara dalam
    menyediakan Perkhidmatan dan mengendalikan perniagaan kami, termasuk:
  </p>
  <ul>
    <li>
      untuk mengendalikan, menyelenggarakan, meningkatkan dan menyediakan semua
      ciri Perkhidmatan, untuk menyediakan perkhidmatan dan maklumat yang anda
      minta, untuk membalas komen dan soalan dan untuk memberikan sokongan
      kepada pengguna Perkhidmatan;
    </li>
    <li>untuk melaksanakan kewajipan kami yang timbul daripada Perjanjian;</li>
    <li>
      untuk memahami dan menganalisis aliran penggunaan dan pilihan pengguna
      kami, untuk menambah baik Perkhidmatan, dan untuk membangunkan produk,
      perkhidmatan, ciri dan fungsi baru;
    </li>
    <li>
      untuk mencegah dan mengesan penipuan atau penyalahgunaan produk,
      Perkhidmatan dan Aplikasi anda;
    </li>
    <li>
      untuk menghantar komunikasi kepada anda tertakluk pada undang-undang yang
      terpakai;
    </li>
    <li>
      atau untuk mematuhi syarat undang-undang dan peraturan jika berkenaan.
    </li>
  </ul>
  <h1>4. Dengan Siapakah Kami Berkongsi Maklumat Anda?</h1>
  <p>
    Dalam keadaan tertentu, dan untuk melaksanakan Perkhidmatan, kami mungkin
    mendedahkan maklumat tertentu yang kami kumpulkan daripada anda:
  </p>
  <ul>
    <li>
      dalam keluarga syarikat kami, yang mungkin termasuk syarikat induk,
      korporat, sekutu, anak syarikat, unit perniagaan dan syarikat lain yang
      berkongsi pemilikan bersama;
    </li>
    <li>dengan majikan anda mengikut Perjanjian;</li>
    <li>
      dengan pembekal perkhidmatan pihak ketiga yang bekerja bagi pihak kami
      memerlukan akses kepada maklumat anda untuk menjalankan kerja mereka (cth
      sokongan pelanggan, pengebilan, dsb.);
    </li>
    <li>
      dan kepada pemeroleh, pengganti atau penerima serah hak sebagai sebahagian
      daripada sebarang penggabungan, pemerolehan atau transaksi yang serupa
    </li>
  </ul>
  <p>
    Apabila kami mendedahkan Maklumat Peribadi anda kepada pihak ketiga, kami
    mengambil langkah yang munasabah untuk memastikan bahawa peraturan yang
    ditetapkan dalam Dasar Privasi ini dipatuhi dan pihak ketiga ini memberikan
    jaminan yang mencukupi untuk melaksanakan langkah teknikal dan organisasi
    yang sesuai.
  </p>
  <p>
    Kami akhirnya boleh menyediakan maklumat tertentu yang dikumpulkan secara
    automatik, diagregatkan atau sebaliknya tidak boleh dikenal pasti secara
    peribadi kepada pihak ketiga untuk pelbagai tujuan, termasuk (i) pematuhan
    dengan pelbagai kewajipan pelaporan; (ii) untuk tujuan perniagaan atau
    pemasaran; atau (iii) untuk membantu pihak tersebut memahami minat, tabiat
    dan corak penggunaan anda untuk program, kandungan, perkhidmatan dan/atau
    fungsi tertentu yang tersedia melalui Perkhidmatan.
  </p>
  <h1>5. Bagaimanakah Kami Menyimpan Maklumat Anda?</h1>
  <p>
    Maklumat Peribadi anda mungkin disimpan dan diproses di mana-mana negara
    yang kami mempunyai kemudahan atau di mana kami melibatkan pembekal
    perkhidmatan. Dengan melawati Laman Web, memuat turun Aplikasi atau
    menggunakan Perkhidmatan, anda bersetuju dengan pemindahan maklumat ke
    negara di luar tempat tinggal anda, yang mungkin mempunyai peraturan
    perlindungan data yang berbeza daripada di negara anda.
  </p>
  <p>
    Walau bagaimanapun, amalan kami mengenai Maklumat Peribadi anda pada setiap
    masa akan terus dikawal oleh Dasar Privasi ini dan, jika berkenaan, kami
    akan mematuhi syarat Peraturan Perlindungan Data Umum ("GDPR") yang
    menyediakan perlindungan yang mencukupi untuk pemindahan Maklumat Peribadi
    daripada EU/EEA ke negara ketiga.
  </p>
  <p>
    Kami telah melaksanakan pelbagai perlindungan fizikal, pentadbiran dan
    teknikal yang direka untuk melindungi kerahsiaan dan keselamatan Maklumat
    Peribadi di bawah kawalan kami. Walau bagaimanapun, tiada langkah
    keselamatan yang mutlak atau terjamin sepenuhnya dan anda mesti sedar bahawa
    sentiasa ada tahap risiko tertentu bagi Maklumat Peribadi yang anda berikan
    kepada kami akan terdedah tanpa kebenaran anda dan tanpa kesalahan di pihak
    EMPLOYEEapp. Jika anda percaya bahawa Maklumat Peribadi anda telah terjejas,
    sila hubungi kami seperti yang dinyatakan dalam bahagian 'Hubungi Kami'.
    Jika kami mengetahui tentang pelanggaran sistem keselamatan, kami akan
    memberitahu anda dan pihak berkuasa tentang berlakunya pelanggaran mengikut
    undang-undang yang terpakai.
  </p>
  <p>
    Kami hanya akan menyimpan Maklumat Peribadi anda selama yang munasabah
    diperlukan untuk memenuhi tujuan berkaitan yang ditetapkan dalam Dasar
    Privasi ini dan untuk mematuhi kewajipan undang-undang dan peraturan kami.
  </p>
  <h1>6. Hak Berkenaan Maklumat Peribadi Anda</h1>
  <p>
    Kami mengakui bahawa anda mempunyai hak untuk mengakses dan membetulkan
    Maklumat Peribadi anda, menurut undang-undang yang terpakai, atau hak
    tambahan di bawah GDPR. Kami mengumpul maklumat di bawah arahan majikan
    anda, dan tidak mempunyai hubungan langsung dengan anda. Jika anda tidak
    mahu lagi dihubungi oleh majikan anda dan/atau ingin mengakses atau
    membetulkan Maklumat Peribadi anda, sila hubungi majikan anda yang
    berinteraksi dengan anda secara langsung.
  </p>
  <h1>7. Privasi Kanak-kanak</h1>
  <p>
    Perkhidmatan ini tidak ditujukan kepada kanak-kanak di bawah umur 16 tahun,
    dan kami tidak mengumpul Maklumat Peribadi daripada kanak-kanak di bawah
    umur 16 tahun dengan sengaja tanpa mendapat kebenaran ibu bapa. Jika anda
    berumur di bawah 16 tahun, jangan gunakan atau mengakses Perkhidmatan pada
    bila-bila masa atau dalam apa jua cara. Sekiranya kami mendapat tahu bahawa
    Maklumat Peribadi telah dikumpulkan melalui Perkhidmatan daripada mereka
    yang berumur di bawah 16 tahun dan tanpa kebenaran ibu bapa yang boleh
    ditentusahkan, maka kami akan mengambil langkah yang sewajarnya untuk
    memadamkan maklumat ini. Jika anda ibu bapa atau penjaga dan mendapati
    bahawa anak anda di bawah umur 16 tahun telah memberikan Maklumat Peribadi,
    maka anda boleh memaklumkan kami seperti yang dinyatakan dalam bahagian
    "Hubungi Kami" dan meminta kami memadamkan Maklumat Peribadi kanak-kanak
    tersebut daripada sistem kami.
  </p>
  <h1>8. Kemas Kini</h1>
  <p>
    Kami akan mengemas kini Dasar Privasi ini dari semasa ke semasa untuk
    mencerminkan perubahan kepada amalan, teknologi, syarat undang-undang dan
    faktor lain kami. Sila semak 'Tarikh Berkuat Kuasa' di bahagian atas halaman
    ini untuk melihat bila Dasar Privasi ini dikemas kini kali terakhir. Apabila
    perubahan dibuat pada Dasar Privasi ini, ia akan berkuat kuasa serta-merta
    apabila diterbitkan dalam Dasar Privasi yang dikemas kini yang disiarkan
    pada halaman ini melainkan dinyatakan sebaliknya. Penggunaan Perkhidmatan
    oleh anda berikutan perubahan ini menandakan persetujuan anda terhadap
    amalan yang diterangkan dalam Dasar Privasi yang dikemas kini.
  </p>
  <h1>9. Hubungi Kami</h1>
  <p>
    Jika anda mempunyai sebarang soalan atau komen tentang Dasar Privasi ini
    atau Maklumat Peribadi anda, untuk melaksanakan sebarang hak yang berkenaan,
    untuk membuat aduan, atau untuk mendapatkan maklumat tentang dasar dan
    amalan kami, Pegawai Privasi kami boleh dihubungi melalui mel atau e-mel
    menggunakan alamat berikut maklumat:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Malay
