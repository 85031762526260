import { gql } from '@apollo/client'

const GET_HASHTAGS_POSTS_QUERY = (displayContentAuthorEnabled) => {
  let ownerName = ''
  if (displayContentAuthorEnabled) {
    ownerName = 'ownerName'
  }
  return gql`
  query getHashtagPosts($hashtag: String!, $cfpId: String!) {
    getHashtagPosts(req: {hashtag: $hashtag, cfpId: $cfpId}) {
      contents {
        _id
        owner
        ${ownerName}
        ownerPhoto {
          _32px
          _64px
          _128px
          _256px
          _512px
        }
        account
        title
        created
        modified
        modifiedBy
        notify
        includeInFeed
        accessGroups
        integrationId
        integrationType
        thumbnailS3Key
        smallThumbnailUrl
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        mentionsEnabled
        thumbnailJobId
        thumbnailJobStatus
        displayThumbnail
        publishDate
        webUrl
        s3Key
        tags
        share
        postDate
        unpostDate
        postTimezone
        posted
        notesEnabled
        cacheable
        contentType
        childContents {
          id
          contentType
          s3Key
          smallThumbnailUrl
          mediumThumbnailUrl
          largeThumbnailUrl
          xlargeThumbnailUrl
          priority
        }
        totalLikes
        totalComments
        totalFirstComments
        totalViews
        secure
        contentFolder
        info
        hlsVideoPlaylistUrl
        transcodeVideoStatus
        likesEnabled
        commentsEnabled
        caption
        published
        likedByUser
        signedDownloadUrl
        htmlSignedDownloadUrl
        disableSaving
        hashtags
        mentions {
          id: _id
          name
        }
      }
      totalPosts
    }
  }
`
}

export default GET_HASHTAGS_POSTS_QUERY
