import React from 'react'

function CT () {
  return (
<>
  <title>theEMPLOYEEapp - 私隱政策</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>《隱私權政策》</h1>
  <h4>生效日期： 2023 年 3 月 15 日</h4>
  <p>
    theEMPLOYEEapp（下稱「theEMPLOYEEapp」或「我們」）致力於向客戶及用戶說明有關個人資料的處理方式。有鑑於此，本政策將說明我們如何收集、使用和披露個人資料，以及如何在必要時查閱和更正有關的個人資料。
  </p>
  <p>
    <u>瀏覽</u>
    <a href="https://theemployeeapp.com/">網站（下稱「網站」）</a>
    、下載相關行動應用程式（下稱「應用程式」）或使用相關服務（統稱為「服務」），包括聯絡我們或同意接收我們的電子郵件，即表示閣下接受本政策的條款與細則。
  </p>
  <p>
    本政策不適用於第三方營運的網站或服務，故此我們對於任何第三方就保護個人資料的《隱私權政策》、程序和做法概不負責。
  </p>
  <p>本政策已納入我們的《軟體即服務協議》（下稱「協議」）並受其約束。</p>
  <h1>1.我們與閣下的僱主是什麼關係？</h1>
  <p>
    我們已與閣下的僱主達成協議，授予閣下使用本應用程式和服務的權限（即「協議」）。我們將代表閣下的僱主並根據合法指示處理閣下的個人資料。閣下提供給我們及/或經由本應用程式上傳的資料（無論是否屬於個人資料）皆受本協議所約束。
  </p>
  <p>
    本政策中提及的「閣下的僱主」應指與我們簽訂協議的實體（無論閣下在法律上是否該實體的僱員、顧問或約聘人員），而且此類指稱並非旨在描述或損害閣下相對於該實體的地位。
  </p>
  <h1>2.我們會收集哪些資料？</h1>
  <p>
    我們可能收集有關閣下的個人資料和其他資料類型主要來自：(a)閣下提供的資料；(b)閣下僱主提供的資料；(c)經由本應用程式收集的資料；(d)經由我們的SMS服務收集的資料；以及(e)自動收集的資料。我們以提供服務必需及履行合法利益作為處理個人資料的主要法律依據，並於下方〈我們會如何使用閣下的資料？〉一節進一步說明。我們亦可能在徵得閣下同意下處理個人資料，並在適當時提出請求。
  </p>
  <p>
    <u>a) 閣下提供的資料</u>
  </p>
  <p>
    閣下使用服務時，我們可能會要求閣下自願向我們提供可識別閣下個人身分或可用於識別閣下個人身分的資料（下稱「個人資料」）。個人資料包括但不限於以下類別的資料：(1)聯絡方式（如閣下的辦公地址、電子郵件地址和聯絡號碼）；(2)人口統計資料（如閣下的郵遞區號）；(3)職業資料（如閣下的公司名稱、職務和員工編號）；(4)閣下自願選擇提供給我們及/或閣下的僱主要求提供的其他識別資料，包括但不限於密碼等唯一識別碼、照片或閣下寄給我們的電子郵件或信件中的個人資料。我們亦可能會收集其他資料，也可能屬於個人資料，並會在收集時或根據閣下的意向向閣下說明。若閣下選擇拒絕提供我們要求的任何個人資料，閣下可能無法使用部分服務。
  </p>
  <p>
    <u>b) 閣下僱主提供的資料</u>
  </p>
  <p>
    閣下僱主可能會在以下情況向我們提供有關閣下的資料：(1)在建立閣下的用戶個人檔案時，為方便閣下能夠根據協議使用本應用程式，或確保我們能夠提供服務（包括
    SMS）。資料可能包括閣下的姓名、電子郵件地址、電話或手機號碼、職位、部門；(2)在閣下僱主使用本應用程式的過程中。
  </p>
  <p>
    <u>c) 經由本應用程式收集的資料</u>
  </p>
  <p>
    在本應用程式內執行某些操作時，閣下可能會被指示授予下列權限。閣下可自行選擇拒絕各項授權指示。
  </p>
  <ol style={{ listStyleType: 'decimal' }}>
    <li>個人身分資料</li>
    <li>行事曆</li>
    <li>照片/媒體/檔案</li>
    <li>麥克風</li>
    <li>Wi-Fi連線</li>
    <li>裝置ID</li>
  </ol>
  <p>我們將向用戶徵求存取權，以便執行本應用程式內的操作，詳情臚列如下：</p>
  <ul>
    <li>我們會索取閣下的個人身分資料，僅供確定帳戶持有人的身分。</li>
    <li>
      我們會要求使用閣下的行事曆，以便本應用程式可按閣下所需新增事項到行事曆中。
    </li>
    <li>
      我們會索取照片/媒體/檔案，以便閣下儲存應用程式內的檔案供離線使用。本應用程式需要取得閣下的檔案存取權，以進行此步驟。本應用程式不會改動閣下的資料，僅允許閣下按個人所需儲存檔案，以供無網路連線時備用。
    </li>
    <li>
      我們會要求使用麥克風，以提供本應用程式所支援的現場直播或錄製影片功能。
    </li>
    <li>
      我們會要求使用Wi-Fi連線，以確定裝置已連線到Wi-Fi或行動網路，確保本應用程式運作時保持最佳效果。
    </li>
    <li>我們會索取裝置ID，以便閣下接收本應用程式發出的推送通知。</li>
  </ul>
  <p>
    <u>d)經由我們的 SMS 服務收集的資料</u>
  </p>
  <p>
    要使用我們的SMS服務，閣下可能會被指示提供以下資料：閣下的手機號碼及SMS偏好設定（可自行選擇加入或拒絕）。閣下僱主使用此資料來管理我們的
    SMS程式，包括向閣下發送SMS。閣下的無線網路營運商可能會收集有關閣下無線裝置使用情況的資料，處理方式則受營運商訂明的政策所約束。
  </p>
  <p>
    <u>e) 自動收集的資料</u>
  </p>
  <p>
    為進行分析，本平台可能亦會索取裝置資料，包括裝置類型、版本、作業系統、瀏覽器類型及版本（如適用）以及閣下使用服務的相關資料。
  </p>
  <p>
    我們亦可能從第三方（包括Google
    Analytics）獲取資料，並連同我們所收集的資料一併使用。
  </p>
  <h1>3.我們會如何使用閣下的資料？</h1>
  <p>
    我們會採取措施，確保只有需要取用閣下個人資料以履行工作職責的員工才能取用有關資料。
  </p>
  <p>我們透過各種方式收集有關資料來提供服務和經營業務，包括：</p>
  <ul>
    <li>
      營運、維護、加強和提供服務的所有功能、提供閣下要求的服務和資料、回應評論和問題，並為服務用戶提供支援；
    </li>
    <li>履行因協議而產生的義務；</li>
    <li>
      了解及分析用戶的使用趨勢和偏好、改進服務以及開發新產品、服務、工具和功能；
    </li>
    <li>防範及偵測有關閣下產品、服務和應用程式的欺詐或盜用行為；</li>
    <li>根據適用法律向閣下發送通訊內容；或</li>
    <li>遵守適用法律及法規的要求。</li>
  </ul>
  <h1>4.我們會與誰共享閣下的資料？</h1>
  <p>
    在某些情況下，為了履行服務，我們可能會向下列單位披露從閣下收集得來的部分資料：
  </p>
  <ul>
    <li>
      旗下公司，可能包括母公司、公司、附屬公司、子公司、業務部門和其他擁有共同所有權的公司；
    </li>
    <li>閣下僱主（根據協議內容）；</li>
    <li>
      代表我們履行職務的第三方服務供應商（需要存取閣下資料執行職務，包括客戶支援、計費等）；以及
    </li>
    <li>作為任何合併、收購或類似交易一部分的收購方、繼任人或受讓人</li>
  </ul>
  <p>
    向第三方披露閣下的個人資料時，我們會採取合理措施確保本政策中列明的規定得到遵守，並確保第三方提供充分的保證以履行在技術上及組織上的適當措施。
  </p>
  <p>
    我們最終可能出於各種目的向第三方提供自動收集、彙總或其他非個人身分資料，包括
    (i)遵守各項報告義務；(ii)出於商業或營銷目的；(iii)協助有關第三方了解閣下對經由服務提供的程式、內容、服務及/或功能的興趣、習慣和使用模式。
  </p>
  <h1>5.我們會如何儲存閣下的資料？</h1>
  <p>
    閣下的個人資料可能會經由任何國家/地區的轄下設施或受聘服務供應商進行儲存和處理程序。閣下瀏覽網站、下載應用程式或使用服務，即表示同意將資料傳輸到閣下居住地以外的國家/地區，有關國家/地區的資料保護規則可能與閣下所在國家/地區不同。
  </p>
  <p>
    但無論在任何情況下，我們對閣下個人資料所履行的做法始終受本政策約束，在適用情況下，我們會履行《一般資料保護規則》（GDPR）的要求，為任何歐盟/歐洲經濟區至第三方國家/地區的個人資料傳輸提供充分保護。
  </p>
  <p>
    我們執行了各種實體、行政和技術保護措施，旨在保護我們控制下的個人資料確保機密性及安全性，惟任何安全措施皆沒有所謂絕對或百分百保證。閣下必須知道，閣下提供給我們的個人資料始終有一定程度在未經閣下同意且theEMPLOYEEapp無過失的情況下被披露的風險。若閣下認為閣下的個人資料被洩露，請透過〈聯絡我們〉一節所列明的聯絡方式告知我們。若得知任何安全系統資料外洩，我們將根據適用法律將外洩事件通知閣下及有關當局。
  </p>
  <p>
    我們僅會在履行本政策中列明的相關目的以及遵守法律和監管義務的合理必要期限內保留閣下的個人資料。
  </p>
  <h1>6.關於閣下的個人資料權利</h1>
  <p>
    我們同意閣下有權根據適用法律或《一般資料保護規則》規定的其他權利存取和更正閣下的個人資料。我們在閣下僱主的指導下收集資料，與閣下沒有直接關係。若不欲僱主繼續聯絡閣下，或欲存取或更正閣下的個人資料，請直接聯絡閣下的僱主。
  </p>
  <h1>7.兒童隱私權</h1>
  <p>
    服務對象不包括未滿16歲人士，未經父母同意，我們不會有意收集未滿16歲人士的個人資料。若閣下未滿16歲，請勿在任何時候或以任何方式使用服務。若我們得知經由服務所收集的有關個人資料是來自未滿16歲的人士，而且未經父母同意，我們將採取適當的措施刪除有關資料。若閣下是父母或監護人，發現閣下未滿16歲的孩子提供了個人資料，閣下則可透過〈聯絡我們〉一節所列明的聯絡方式告知我們，並要求我們從系統中移除孩子的個人資料。
  </p>
  <h1>8.更新</h1>
  <p>
    我們會不時更新《隱私權政策》，以反映處理方式、技術、法律要求和其他因素的變動。請查看本頁頂的「生效日期」，了解本政策的最後更新日期。本政策內容改動時，除非另有說明，否則將在本頁發佈更新版政策時立即生效。閣下在政策更新後使用服務即表示同意更新政策中列明的處理方式。
  </p>
  <h1>9.聯絡我們</h1>
  <p>
    若閣下對《隱私權政策》或閣下的個人資料有任何疑問或意見、欲行使任何適用權利、提出投訴或獲取有關政策和處理方式的資料，請透過以下的郵寄地址或電子郵件地址聯絡隱私執行官：
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default CT
