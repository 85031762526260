import React from 'react'

function Spanish () {
  return (
<>
  <title>theEMPLOYEEapp - Política de privacidad</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Política de privacidad</h1>
  <h4>Fecha de vigencia: 15 de marzo de 2023</h4>
  <p>
    theEMPLOYEEapp ("theEMPLOYEEapp" o "nosotros") está comprometida para
    proporcionar transparencia a nuestros clientes y usuarios en relación con
    nuestras prácticas para el manejo de su información personal. Con ese fin,
    esta Política de privacidad describe cómo recopilamos, usamos y divulgamos
    la información personal y cómo esta información personal puede ser
    consultada y corregida cuando sea necesario.
  </p>
  <p>
    Al visitar el sitio{' '}
    <a href="https://theemployeeapp.com/">web ("Sitio web")</a>, descargar la
    aplicación móvil asociada ("Aplicación") o usar los servicios asociados
    (colectivamente "Servicios"), al comunicarse con nosotros o al aceptar
    recibir correos electrónicos de nosotros, usted acepta los términos y
    condiciones de esta Política de privacidad.
  </p>
  <p>
    Esta Política de privacidad no se extiende a los sitios web o servicios
    operados por terceros. Por lo tanto, no somos responsables por sus políticas
    de privacidad, procedimientos y prácticas relacionadas con la protección de
    información personal.
  </p>
  <p>
    Esta Política de privacidad está incorporada y sujeta a nuestro Acuerdo de
    Software como un Servicio ("Acuerdo").
  </p>
  <h1>1. ¿Cuál es nuestra relación con su empleador?</h1>
  <p>
    Tenemos un acuerdo con su empleador para garantizar que usted tenga acceso a
    la Aplicación y a los Servicios ("Acuerdo"). Procesaremos su Información
    personal a nombre de su empleador y de acuerdo con sus instrucciones
    legales. La información que nos proporcione y/o cargue vía la Aplicación
    (constituya o no Información personal) también estará gobernada por el
    Acuerdo.
  </p>
  <p>
    Las referencias a "su empleador" en esta Política de privacidad deben
    referirse a la entidad que realizó el Acuerdo con nosotros, sea o no, en
    términos legales, un empleado, consultor o contratista de esa entidad, y
    dichas referencias no pretenden caracterizar o perjudicar su estatus ante
    esa entidad.
  </p>
  <h1>2. ¿Qué información recopilamos?</h1>
  <p>
    Los tipos de información personal o de otro tipo que podríamos recopilar
    acerca de usted son principalmente de las siguientes fuentes: (a)
    información que usted proporciona; (b) información proporcionada por su
    empleador; (c) información recopilada vía la Aplicación; (d) información
    recopilada vía nuestro servicio de SMS y (e) información recopilada
    automáticamente. Las bases legales para nuestro procesamiento de la
    Información personal son principalmente que el procesamiento es necesario
    para proporcionar los Servicios y que el procesamiento se lleva a cabo en
    nuestro legítimo interés, que se explica más adelante en la sección "¿Cómo
    usamos su información?". Es posible que también procesemos la Información
    Personal con su consentimiento previo, pidiéndolo cuando sea adecuado.
  </p>
  <p>
    <strong>
      <u>a) Información que usted proporciona</u>
    </strong>
  </p>
  <p>
    Cuando accede a los Servicios, es posible que le pidamos que voluntariamente
    nos proporcione cierta información que lo identifique personalmente o que
    pueda ser usada para identificarlo personalmente ("Información personal").
    La Información personal incluye (entre otras) las siguientes categorías de
    información: (1) datos de contacto (como la dirección de su oficina,
    dirección de correo electrónico y número de teléfono); (2) datos
    demográficos (como su código postal); (3) información profesional (como el
    nombre de su empresa, su puesto y su identificación de empleado); y (4) otra
    información de identificación que usted voluntariamente elija
    proporcionarnos y/o que sea requerida por su empleador, incluyendo, entre
    otras, identificadores únicos como contraseñas, fotos o Información personal
    en correos electrónicos o cartas que usted nos envíe. También podremos
    recopilar información adicional, que podría ser Información personal, como
    se le describió de antemano en el punto de recopilación o de conformidad con
    su consentimiento. Si elige retener cualquier Información personal
    solicitada por nosotros, es posible que no pueda tener acceso a ciertas
    partes del Servicio.
  </p>
  <p>
    <strong>
      <u>b) Información proporcionada por su empleador</u>
    </strong>
  </p>
  <p>
    Es posible que su empleador nos entregue información acerca de usted: (1) al
    crear un perfil de usuario para permitir que usted tenga acceso a la
    Aplicación bajo el Acuerdo o para permitirnos proporcionar nuestros
    Servicios incluyendo la mensajería de texto. Esta información puede incluir
    su nombre, dirección de correo electrónico, número de teléfono o celular,
    puesto, departamento; (2) de cualquier otra manera en el curso del uso de la
    Aplicación por parte de su empleador.
  </p>
  <p>
    <strong>
      <u>c) Información recopilada por la Aplicación</u>
    </strong>
  </p>
  <p>
    Al realizar ciertas acciones dentro de esta aplicación, es posible que se le
    pida otorgar acceso a lo siguiente. Usted puede optar por no otorgarlo en
    cada una de las acciones.
  </p>
  <ol>
    <li>Identidad</li>
    <li>Calendario</li>
    <li>Fotos/Multimedia/Archivos</li>
    <li>Micrófono</li>
    <li>Conexión Wi-Fi</li>
    <li>Identificación del dispositivo</li>
  </ol>
  <p>
    El acceso sería necesario para las siguientes acciones dentro de la
    Aplicación:
  </p>
  <ul>
    <li>
      La solicitud de la identidad es únicamente para determinar a quién
      pertenece la cuenta.
    </li>
    <li>
      La solicitud para el calendario pide permiso para acceder a su calendario
      para que la aplicación pueda agregar elementos de su elección a su
      calendario.
    </li>
    <li>
      La solicitud para fotos/multimedia/archivos le permite guardar archivos
      que están incluidos en su aplicación para su uso fuera de línea. Para
      hacer esto, la aplicación necesita acceso a sus archivos. La aplicación no
      modifica su información, simplemente le permite guardar un archivo de su
      elección para acceder al mismo más tarde, incluso cuando no tenga
      disponible una conexión a Internet.
    </li>
    <li>
      La solicitud para el micrófono se usa para eventos en vivo o para grabar
      videos, algo que esta aplicación puede realizar.
    </li>
    <li>
      La solicitud para la conexión Wi-Fi es para determinar si está en una
      conexión Wi-Fi o de datos de celular para el desempeño óptimo de la
      aplicación.
    </li>
    <li>
      La solicitud de la identificación del dispositivo le permite recibir
      notificaciones push dentro de la aplicación.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) Información recopilada via nuestro servicio de SMS</u>
    </strong>
  </p>
  <p>
    Para usar nuestro SMS es posible que se le pida proporcionar su número de
    teléfono móvil y sus preferencias para mensajes de texto (inclusión y
    exclusión). Su empleador usa esta información para administrar nuestro
    programa de mensajes de texto, incluyendo enviarle mensajes de texto. Es
    posible que su proveedor de Internet recopile datos acerca del uso de su
    dispositivo inalámbrico, y sus prácticas están gobernadas por sus propias
    políticas.
  </p>
  <p>
    <strong>
      <u>e) Información recopilada automáticamente</u>
    </strong>
  </p>
  <p>
    Es posible que, para propósitos de análisis, nuestra plataforma también
    tenga acceso a información del dispositivo como el tipo de dispositivo, la
    versión, el sistema operativo o el tipo y versión del navegador (si aplica)
    así como información relacionada con su uso de los Servicios.{' '}
  </p>
  <p>
    Es posible que también obtengamos información de terceros, incluyendo Google
    Analytics, y combinarla con la información que recopilamos.{' '}
  </p>
  <h1>3. ¿Cómo usamos su información?</h1>
  <p>
    Tomamos medidas diseñadas para garantizar que solo los empleados que
    necesitan acceder a su Información personal para cumplir con sus tareas
    laborales tengan acceso a ella.
  </p>
  <p>
    Usamos la información que recopilamos de varias maneras para proporcionar
    los Servicios y operar nuestro negocio, incluyendo las siguientes:
  </p>
  <ul>
    <li>
      para operar, mantener, mejorar y proporcionar todas las funciones de los
      Servicios, para proporcionar los servicios y la información que usted
      solicita, para responder a los comentarios y preguntas, y para
      proporcionar soporte a los usuarios de los Servicios;
    </li>
    <li>para llevar a cabo nuestras obligaciones derivadas del Acuerdo;</li>
    <li>
      para comprender y analizar las tendencias de uso y las preferencias de
      nuestros usuarios, para mejorar los Servicios y para desarrollar nuevos
      productos, servicios, características y funcionalidades;
    </li>
    <li>
      para evitar y detectar fraude o mal uso de nuestros productos, Servicios y
      la Aplicación;
    </li>
    <li>para enviarle comunicados sujetos a las leyes vigentes;</li>
    <li>
      o para cumplir con requerimientos legales y regulatorios cuando sea
      aplicable.
    </li>
  </ul>
  <h1>4. ¿Con quién compartimos su información?</h1>
  <p>
    En algunas circunstancias, y para realizar los Servicios, es posible que
    divulguemos cierta información que hemos recopilado de usted:
  </p>
  <ul>
    <li>
      dentro de nuestra familia de empresas, lo que puede incluir empresas
      matrices, corporativas, afiliadas, subsidiarias, unidades de negocio y
      otras empresas que comparten propiedad compartida;
    </li>
    <li>con su empleador de acuerdo con el Acuerdo;</li>
    <li>
      con terceros proveedores de servicios que trabajan a nuestro nombre y
      requieren acceso a su información para realizar su trabajo (p. ej.,
      atención al cliente, facturación, etc.);
    </li>
    <li>
      y para un adquiriente, sucesor o asignado como parte de una fusión,
      adquisición o transacción similar.
    </li>
  </ul>
  <p>
    Cuando divulgamos su Información personal con terceros, tomamos las medidas
    razonables para garantizar que se cumplan las reglas establecidas en esta
    Política de privacidad y que estos terceros proporcionen suficientes
    garantías para implementar las medidas técnicas y organizacionales
    adecuadas.
  </p>
  <p>
    Finalmente, es posible que divulguemos a terceros cierta información
    personal recopilada automáticamente, acumulada o de otra manera que no sea
    personalmente identificable para diferentes propósitos, incluyendo: (i)
    cumplimiento de varias obligaciones de informes; (ii) para fines comerciales
    o de marketing; o (iii) para ayudar a dichos terceros para que comprendan
    sus intereses, hábitos y patrones de uso para ciertos programas, servicios
    de contenido, y/o la funcionalidad disponible a través de los Servicios.
  </p>
  <h1>5. ¿Cómo almacenamos su información?</h1>
  <p>
    Su Información personal puede ser almacenada y procesada en cualquier país
    donde tengamos instalaciones o en los que contratemos proveedores de
    servicios. Al visitar el Sitio web, descargar la aplicación o usar los
    Servicios, usted acepta la transferencia de información a países fuera de su
    país de residencia, los que pueden tener diferentes reglas de protección de
    datos de las de su país.{' '}
  </p>
  <p>
    Sin embargo, nuestras prácticas en relación con su Información personal en
    todo momento seguirán siendo gobernadas por esta Política de privacidad y,
    si es aplicable, cumpliremos con los requisitos de la Ley General de
    Protección de Datos ("GDPR") proporcionando la protección adecuada para la
    transferencia de Información personal desde la UE/EEE a otros países.
  </p>
  <p>
    Hemos implementado varias protecciones físicas, administrativas y técnicas
    diseñadas para proteger la confidencialidad y seguridad de la Información
    personal que está bajo nuestro control. Sin embargo, ninguna medida de
    seguridad es absoluta o completamente garantizada y debe estar consciente de
    que siempre existe cierto nivel de riesgo de que la Información personal que
    nos proporciona será divulgada sin su consentimiento y sin falta por parte
    de theEMPLOYEEapp. Si piensa que su Información personal ha sido
    comprometida, comuníquese con nosotros como se establece en la sección
    "Comuníquese con nosotros". Si llegamos a saber que existe una infracción de
    los sistemas de seguridad, informaremos a usted y a las autoridades acerca
    de la ocurrencia de la infracción de acuerdo con la ley vigente.
  </p>
  <p>
    Solo conservaremos su Información personal en tanto sea razonablemente
    necesaria para cumplir con los propósitos relevantes establecidos en esta
    Política de privacidad y para cumplir con nuestras obligaciones legales y
    regulatorias.
  </p>
  <h1>6. Derechos relacionados con su Información personal</h1>
  <p>
    Reconocemos que usted tiene el derecho de acceder y corregir su Información
    personal de acuerdo con la ley vigente, o derechos adicionales en virtud de
    la GDPR. Recolectamos información en virtud de las instrucciones de su
    empleador y no tenemos ninguna relación con usted. Si no desea ser
    contactado por su empleador y/o desea acceder o corregir su Información
    personal, comuníquese con el empleador con el que interactúa de manera
    directa.
  </p>
  <h1>7. Privacidad de los niños</h1>
  <p>
    Los servicios no están dirigidos a niños menores de 16 años y, a sabiendas,
    no recopilamos información personal de niños menores de 16 años sin obtener
    el consentimiento de los padres. Si usted tiene menos de 16 años, por favor,
    no use o acceda a los Servicios en ningún momento y de ninguna manera. Si
    nos enteramos de que se ha recopilado Información personal de personas
    menores de 16 años vía los Servicios y sin el consentimiento verificable de
    los padres, entonces tomaremos las medidas necesarias para borrar esta
    información. Si usted es un padre o tutor y descubre que su hijo(a) menor de
    16 años proporcionó Información personal, entonces puede notificarnos como
    se establece en la sección "Comuníquese con nosotros" y solicitar que
    borremos de nuestros sistemas la Información personal de ese menor.
  </p>
  <h1>8. Actualización</h1>
  <p>
    Actualizaremos esta Política de privacidad cada cierto tiempo para reflejar
    cambios en nuestras prácticas, tecnologías, requerimientos legales u otros
    factores. Revise la "Fecha de vigencia" en la parte superior de esta página
    para ver cuándo se actualizó por última vez esta Política de privacidad.
    Cuando se hagan cambios a esta Política de privacidad, entrarán en vigor
    inmediatamente cuando se publique una Política de privacidad actualizada en
    esta página a menos que se indique lo contrario. Su uso de los Servicios
    después de estos cambios indica que acepta las prácticas descritas en la
    Política de privacidad actualizada.
  </p>
  <h1>9. Comuníquese con nosotros</h1>
  <p>
    Si tiene preguntas o comentarios acerca de esta Política de privacidad o
    acerca de su Información personal, para ejercer algún derecho aplicable,
    para presentar una queja o para obtener información acerca de nuestras
    políticas o prácticas, puede comunicarse con nuestro Ejecutivo de Privacidad
    vía correo o correo electrónico usando la siguiente información de contacto:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Spanish
