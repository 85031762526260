// @flow
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import color from 'michelangelo/dist/Components/styles/color'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import { getLanguageTagFromLocale, shouldShowTranslate } from '../../Helpers/TranslationHelper'
import GET_CONTENT_BY_ID_QUERY from '../../apollo/queries/getContentById'
import ContentTypePreview from './ContentTypePreview'
import Column from 'michelangelo/dist/WebComponents/Layout/Column'
import ContentCard from '../../NewsFeed/Components/ContentCard'
import useDimensions from 'michelangelo/src/Components/helpers/WebDimension'
import { readFromCache, writeToCache } from '../../apollo/cacheHelper'
import { goToContentPreview } from '../../Helpers/contentPreviewHelper'
import { trackContentIteration } from '../../Helpers/segmentHelper'
import eventTypes from '../../Helpers/eventTypeHelper'
import { contentLocations } from '../../Helpers/contentHelper'
import LoadingScreen from '../LoadingScreen'
import { checkIsReadAcknowledgement } from '../../Helpers/ReadAcknowledgement'
import OverlayPage from '../OverlayPage'
import SEND_EMAIL_QUERY from '../../apollo/queries/sendEmail'
import { messages } from '../../i18n/messages'
import UrlHelper from '../../Helpers/urlHelper'
import { alfredUrl } from '../../config'
import Carousel from 'michelangelo/dist/SharedComponents/ContentCard/Carousel'

const ContainerContentPreviewWeb = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
`

const ContentPreviewContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`

const ContentDisplayContainer = styled.div`
  position: relative;
  width: ${(props) => props.isContentArchived ? '100%;' : '70%;'}
  height: 100%;
  background-color: #111111;
`

const ContentCommentsContainer = styled.div`
  background-color: white;
  width: ${(props) => props.isContentArchived ? '0%;' : '30%;'}
  overflow: auto;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
`

const ContentNotAvailableText = styled.h2`
    position: absolute;
    top: 50%;
    bottom: 50%;
    color:#fff;
    font-size: 20px;
`

const CenterContentPreview = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NavigateSectionContainer = styled.div`
  //position: absolute;
  //bottom: 61px;
  margin-top: 12px;
  padding: 10px;
  display: flex;
  max-width: 900px;
  flex-direction: row;

  ${(props) => props.isLast
? `
    justify-content: flex-start;`
    : `
    justify-content: space-between;`};

  ${(props) => props.isFirst
? `
    justify-content: flex-end;`
    : `
    justify-content: space-between;`};
`

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2f2e2e;

  ${(props) => props.isFullPreview
? `
    flex: 1;
    height: ${props.height}px`
    : `
    min-height: 500px;
    min-width: 500px;`};
`

const ContentCardContainer = styled.div`
  background: ${color.white};
  width: 100%;
  border-left: 1px solid ${(props) => props.darkTheme ? color.darkSecondary : color.grey200};
  border-top: 1px solid ${(props) => props.darkTheme ? color.darkSecondary : color.grey200};
  overflow-y: auto;
`

const ArrowText = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #2f2e2e;
`

const ArrowContainer = styled.div`
  display: flex;
  cursor: pointer;
  ${(props) => props.isLeft
  ? `
  padding-left: 2%;
  bottom 50px;
  `
  : `
  padding-right: 2%;
  bottom 50px;`};
`

type NewContentPreviewProps = {
    me: Object
} & InjectIntlProvidedProps

const NewContentPreviewWeb = ({ me, intl: { locale, formatMessage } }: NewContentPreviewProps) => {
  const { state } = useLocation()
  const { cfpId, contentId } = useParams()
  const background = useLocation()
  const navigate = useNavigate()

  const client = useApolloClient()
  const { activeCfp, darkTheme } = readFromCache(client, ['activeCfp', 'darkTheme'])
  const primaryColor = activeCfp.primaryColor
  const displayContentAuthorEnabled = me.memberships[0].account.displayContentAuthorEnabled
  const lang = getLanguageTagFromLocale(locale)
  const shouldTranslate = shouldShowTranslate(lang)

  const [maxImageDimensions, setMaxImageDimensions] = useState({ width: 500, height: 500 })
  const [isFullPreview, setIsFullPreview] = useState(true)
  const [containerRef, { width, height }] = useDimensions()
  const [contentCounter, setContentCounter] = useState(0)
  const [showOverlay, setShowOverlay] = useState(false)
  const [sendEmail] = useMutation(SEND_EMAIL_QUERY)

  const [contentsData] = useState(state && state?.contents ? state?.contents : [])
  const [iterate] = useState(contentsData.length > 0)
  //  const [contentLocation] = useState(background.pathname.includes('/explore') ? contentLocations.EXPLORE : contentLocations.NEWSFEED)
  const contentLocation = state?.contentLocation

  const [contentFilters] = useState(state && state?.contentFilters ? state?.contentFilters : { type: 'asc', field: 'priority' })
  const [folderFilters] = useState(state && state?.folderFilters ? state?.folderFilters : { type: 'asc', field: 'priority' })
  const [contentPage] = useState(state && state?.contentPage ? state?.contentPage : 1)
  const [folderPage] = useState(state && state?.folderPage ? state?.folderPage : 1)
  const [rootPath] = useState(state && state?.rootPath && state?.rootPath)
  const verticalPadding = 84
  const horizontalPadding = 464

  if (document.documentElement) document.documentElement.classList.add('modal-open')

  /* eslint-disable */
  useEffect(() => {
    return () => {
      if (document.documentElement) document.documentElement.classList.remove('modal-open')
      writeToCache(client, { isPreviewOpen: false })
    }
  }, [])
  /* eslint-enable */

  const checkIsRead = async (content) => {
    setShowOverlay(true)
    const isRead = await checkIsReadAcknowledgement(client, content, formatMessage, me, sendEmail)
    setShowOverlay(false)
    return isRead
  }

  const { data, loading } = useQuery(GET_CONTENT_BY_ID_QUERY(displayContentAuthorEnabled, shouldTranslate), {
    variables: {
      contentId,
      lang
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  })

  const content: any = data && !loading ? data.getContentById : null

  /* eslint-disable */
    useEffect(() => {
        const imageMaxWidth = width - horizontalPadding > 500 ? width - horizontalPadding : 500
        const imageMaxHeight = height - verticalPadding > 500 ? height - verticalPadding : 500
        setMaxImageDimensions({width: imageMaxWidth, height: imageMaxHeight})

        if (content && (content.contentType === 'CONTENT_IMAGE' || content.contentType === 'CONTENT_WEB')) {
            setIsFullPreview(width <= 1000)
        } else {
            setIsFullPreview(true)
        }
    }, [width, height, content])

    useEffect(()=>{
        const checkRead = async (content) => {
            const isRead = await checkIsRead(content)
            // if was cancelled
            if (!isRead) onClose()
        }

        if (content){
            checkRead(content)
        }
    }, [content])

    useEffect(() => {
        if (!loading) {
            const idx = contentsData.findIndex(el => el._id === content._id)
            if (idx >= 0) {
                setContentCounter(idx)
            }
        }
    }, [contentsData, loading])

    const next = (counter) => {
        if (counter >= 0) {
            const nextCounter = counter - 1
            const newContent = contentsData[nextCounter]
            trackContentIteration( {...newContent, contentId: content._id, user: me._id}, client, eventTypes.CONTENT_PREVIEW_NEXT)
            goToContentPreview(navigate,
                {content: newContent, cfpId, contents:contentsData, location: background, contentLocation, rootPath},
                {folderFilters, contentFilters, contentPage, folderPage})
        }
    }

    const previous = (counter) => {
        if (counter < contentsData.length - 1) {
            const previousCounter = counter + 1
            const newContent = contentsData[previousCounter]
            trackContentIteration( {...newContent, contentId: content._id, user: me._id}, client, eventTypes.CONTENT_PREVIEW_PREVIOUS)
            goToContentPreview(navigate,
                {content: newContent, cfpId, contents: contentsData, location: background, contentLocation, rootPath},
                {folderFilters, contentFilters, contentPage, folderPage})
        }
    }

    const getContentPreview = (data) => {
        if (data.archived) {
            return (
                <ContentNotAvailableText>This content is no longer available. Please <a target='_blank' href={`${alfredUrl}/customer-care?subject=5`}>contact the administrator.</a></ContentNotAvailableText>
            )
        }

        if (data) {
            const preview = (
                <PreviewContainer isFullPreview={isFullPreview} height={maxImageDimensions.height-120}>
                    <ContentTypePreview content={data} client={client} isFullPreview={isFullPreview}
                                        maxImageDimensions={maxImageDimensions} me={me} formatMessage={formatMessage}
                                        contentLocation={contentLocation}/>
                </PreviewContainer>)

            if (isFullPreview) {
                return (
                    <Column classNames={['is-paddingless', 'is-11-fullhd', 'is-9-widescreen', 'is-9-desktop','is-8-tablet', 'is-12-mobile' ]}>
                        {preview}
                    </Column>
                )
            }
            return preview
        }

    }

    const contentNavigationControls = () => {
        const enableList = [
            contentLocations.EXPLORE
        ]
        // For next, the inverse index is used in the condition because the count starts from the bottom element
        return(
            iterate &&
            enableList.includes(contentLocation) &&
            <NavigateSectionContainer
                isLast={contentCounter === 0}
                isFirst={contentCounter === contentsData.length - 1}>
                {
                    contentCounter < contentsData.length - 1 &&
                    <ArrowContainer isLeft={true} onClick={() => previous(contentCounter)}>
                        <Button disabled={contentCounter >= contentsData.length - 1}
                                smallButton icon='leftArrow'
                                onClick={() => previous(contentCounter)}
                                iconSize={32}
                                appBrandColor={color.grey400}/>
                        <ArrowText>
                            {formatMessage(messages.previous)}
                        </ArrowText>
                    </ArrowContainer>
                }
                { contentsData.length > 1 &&
                    contentsData.length - contentCounter < contentsData.length &&
                    <ArrowContainer isLeft={false} onClick={() => next(contentCounter)}>
                    <ArrowText>
                        {formatMessage(messages.next)}
                    </ArrowText>
                    <Button style={{paddingLeft: '10px'}} disabled={contentCounter <= 0}
                            smallButton icon='rightArrow'
                            onClick={() => next(contentCounter)}
                            iconSize={32}
                            appBrandColor={color.grey400}/>
                </ArrowContainer>
                }

            </NavigateSectionContainer>
        )
    }

    const getContentCard = (data) => {
        if (data.archived) return null

        if (data) {
            const isWebContent = data && data.contentType === 'CONTENT_WEB'
            const likesEnabled = data.likesEnabled
            const commentsEnabled = data.commentsEnabled
            return (
                <ContentCardContainer isFullPreview={isFullPreview} maxHeight={maxImageDimensions.height}>
                    <div className="controls">
                        {contentNavigationControls()}
                    </div>
                    <ContentCard
                        hideImage={true}
                        content={data}
                        isDarkTheme={darkTheme}
                        me={me}
                        appBrandColor={primaryColor}
                        likesEnabled={likesEnabled}
                        commentsEnabled={commentsEnabled}
                        isContentPreview={!isWebContent}
                        client={client}
                        contentLocation={contentLocation}
                    />
                </ContentCardContainer>)
        }
    }

    const onClose = () => {
        let params = ''
        let pathname = background?.pathname.includes('/explore') ? `/${cfpId}/explore` : `/${cfpId}/newsfeed`
        if (rootPath){
            pathname = rootPath
        }
        if (pathname.includes('/explore')) {
            params = UrlHelper.buildExploreUrlParams(content.contentFolder, folderFilters.field, folderFilters.type, contentFilters.field, contentFilters.type)
        }
        if (document.documentElement) document.documentElement.classList.remove('modal-open')
        navigate({
            pathname,
            search: params,
            state:{
                folder: content.contentFolder,
                contents: [...contentsData].reverse(),
                folderFilters,
                contentFilters,
                contentPage,
                folderPage,
                rootPath
            }
        })}

    return (
        <>
            {
                loading ? <LoadingScreen/> : <ContainerContentPreviewWeb>
                    <OverlayPage visible={showOverlay}/>
                    <ContentPreviewContainer ref={containerRef}>
                        {
                            !loading && content &&
                                <>
                                    <ContentDisplayContainer isContentArchived={content.archived}>
                                        <CloseButtonContainer onClick={() => onClose()}>
                                            <Button smallButton icon='x' onClick={() => onClose()} iconSize={32}
                                                    appBrandColor={color.grey400} />
                                        </CloseButtonContainer>
                                        <CenterContentPreview>
                                            {content.contentType === 'CONTENT_MISC' ?                                             <Carousel activeChildContent={state && state.activeChildContent} autoplay={false} data={content} width={85} scaleType={'vh'} aspectRatio={(5/5)} onItemClick={(e) => { ()=>{} }} /> : getContentPreview(content)}
                                        </CenterContentPreview>
                                    </ContentDisplayContainer>
                                    <ContentCommentsContainer isContentArchived={content.archived}>
                                        {getContentCard(content)}
                                    </ContentCommentsContainer>
                                </>
                        }
                    </ContentPreviewContainer>
                </ContainerContentPreviewWeb>
            }
        </>
    )
}

export default injectIntl(NewContentPreviewWeb)
