import React from 'react'

function Bulgarian () {
  return (
<>
  <title>theEMPLOYEEapp - Политика за поверителност</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Политика за поверителност</h1>
  <h4>Дата на влизане в сила: 15 март, 2023 г.</h4>
  <p>
    theEMPLOYEEapp („theEMPLOYEEapp“, „нас“ или „ние“) се ангажира да предоставя
    прозрачност на своите клиенти и потребители по отношение на своите практики
    при обработване на техните лични данни. За тази цел тази Политика за защита
    на личните данни описва как събираме, използваме и разкриваме лични данни и
    как може да бъдат преглеждани и коригирани, когато е необходимо.
  </p>
  <p>
    Като посетите <a href="https://theemployeeapp.com/">уебсайта („Уебсайт“)</a>
    , изтеглите свързаното мобилно приложение („Приложение“) или използвате
    свързани услуги (наречени общо „Услуги“), като се свържете с нас или се
    съгласите да получавате имейли от нас, вие приемате правилата и условията на
    тази Политика за защита на личните данни.
  </p>
  <p>
    Тази Политика за защита на личните данни не обхваща уебсайтове или услуги,
    управлявани от трети страни. Съобразно с това ние не носим отговорност за
    техните политики за защита на данните, процедури и практики по отношение на
    защитата на личните данни.
  </p>
  <p>
    Тази Политика за защита на личните данни е включена в и е в съответствие с
    нашия Софтуер като Договор за услуги („Договор“).
  </p>
  <h1>1. Какви са отношенията ни с вашия работодател?</h1>
  <p>
    Ние сключихме договор с вашия работодател, за да ви предоставим достъп до
    Приложението и Услугите („Договор“). Ние ще обработваме вашите Лични данни
    от името на вашия работодател и в съответствие с неговите законни
    инструкции. Информацията, която ни предоставяте и/или качвате чрез
    Приложението (независимо дали представлява или не Лични данни), ще се
    обработва също в съответствие с Договора.
  </p>
  <p>
    Споменаванията в тази Политика за защита на данните на „вашият работодател“
    се отнасят за юридическото лице, което е сключило Договор с нас, независимо
    дали по закон сте служител, консултант или изпълнител на това юридическо
    лице, и такива споменавания не са предназначени за определяне или
    накърняване на вашия статус по отношение на това юридическо лице.
  </p>
  <h1>2. Каква информация събираме?</h1>
  <p>
    Типовете лична и друга информация, която можем да събираме за вас, са
    предимно от следните източници: (a) информация, която предоставяте; (б)
    информация, предоставена от вашия работодател; (в) информация, записана чрез
    Приложението; (г) информация, записана чрез нашата SMS услуга и (д)
    автоматично записана информация. Правните основания за обработването от нас
    на Лични данни са предимно, че обработката е необходима за предоставяне на
    Услугите и че обработката се извършва според нашите законни интереси, които
    са обяснени по-надолу в раздела „Как използваме вашата информация?“. Можем
    също така да обработваме Лични данни с вашето съгласие, като го поискаме,
    както е уместно.
  </p>
  <p>
    <strong>
      <u>a) Информация, която предоставяте</u>
    </strong>
  </p>
  <p>
    Когато осъществявате достъп до Услугите, може да ви помолим да ни
    предоставите доброволно определена информация, която ви идентифицира лично
    или може да бъде използвана за личното ви идентифициране („Лична
    информация“). Личната информация включва (но не се ограничава до) следните
    категории информация: (1) данни за контакт (като вашия служебен адрес, имейл
    адрес и телефонен номер); (2) демографски данни (като вашия пощенски код);
    (3) професионална информация (като името на вашата фирма, вашата длъжност и
    вашия идентификационен номер като служител); и (4) друга идентифицираща
    информация, която решите да ни предоставите доброволно и/или е изисквана от
    вашия работодател, включително без ограничение уникални идентификатори, като
    пароли, снимки или Лични данни в имейли или писма, които ни изпращате. Може
    да записваме също допълнителна информация, която може да представлява Лични
    данни, както е описано по друг начин на мястото на записване или според
    вашето съгласие. Ако решите да не предоставите заявени от нас Лични данни,
    може да не е възможно да получите достъп до определени части от Услугата.
  </p>
  <p>
    <strong>
      <u>б) Информация, предоставена от вашия работодател</u>
    </strong>
  </p>
  <p>
    Вашият работодател може да ни предостави информация за вас: (1) когато
    създава потребителски профил за вас, за да ви даде възможност за достъп до
    Приложението съгласно Договора или за да ни даде възможност да предоставяме
    нашите Услуги, включително текстови съобщения. Тази информация може да
    включва вашето име, имейл адрес, телефонен или мобилен телефонен номер,
    длъжност, отдел; (2) друга информация в хода на използването на Приложението
    от вашия работодател.
  </p>
  <p>
    <strong>
      <u>в) Информация, записана чрез Приложението</u>
    </strong>
  </p>
  <p>
    Когато извършвате определени действия в това приложение, може да бъдете
    подканени да дадете достъп до следните данни. Можете да откажете всяко
    действие.
  </p>
  <ol>
    <li>Самоличност</li>
    <li>Календар</li>
    <li>Снимки/медия/файлове</li>
    <li>Микрофон</li>
    <li>Wifi връзка</li>
    <li>Идентификатор на устройството</li>
  </ol>
  <p>Достъпът ще е необходим за следните действия в Приложението:</p>
  <ul>
    <li>
      Заявката за Самоличност е само за определяне на кого принадлежи акаунтът.
    </li>
    <li>
      Заявката за Календар изисква разрешение за достъп до вашия календар, така
      че приложението да може да добавя елементи по ваш избор към вашия
      календар.
    </li>
    <li>
      Заявката за Снимки/медия/файлове ви позволява да запазвате файлове, които
      са включени в приложението ви за използване офлайн. За тази цел,
      приложението се нуждае от достъп до вашите файлове. Приложението не
      променя вашата информация. То просто ви позволява да запазите файл по ваш
      избор за достъп по-късно, дори когато няма връзка с интернет.
    </li>
    <li>
      Заявката за Микрофон се използва за събития на живо или за запис на
      видеоклипове, които това приложение може да поддържа.
    </li>
    <li>
      Заявката за Wifi връзка е да се определи дали използвате Wifi или мобилна
      връзка за оптимално функциониране на приложението.
    </li>
    <li>
      Заявката за Идентификатор на устройството ви позволява да получавате
      насочени известия в рамките на приложението.
    </li>
  </ul>
  <p>
    <strong>
      <u>г) информация, записана чрез нашата SMS услуга</u>
    </strong>
  </p>
  <p>
    За да използвате нашата SMS услуга, може да бъдете подканени да
    предоставите: вашия мобилен телефонен номер и вашите предпочитания за
    текстови съобщения (приемания и откази). Вашият работодател използва тази
    информация, за да управлява нашата програма за текстови съобщения, както и
    да ви изпраща текстови съобщения. Вашият безжичен оператор може да събира
    данни за използването на вашето безжично устройство и неговите практики се
    ръководят от неговите собствени правила.
  </p>
  <p>
    <strong>
      <u>д) Автоматично записвана информация</u>
    </strong>
  </p>
  <p>
    За аналитични цели нашата платформа може също така да има достъп до
    информация за устройството, като тип устройство, версия, операционна
    система, тип и версия на браузъра (ако е приложимо), както и информация
    относно използването на Услугите от вас.{' '}
  </p>
  <p>
    Можем да получим също информация от трети страни, включително Google
    Analytics, и да я добавим към информацията, която записваме.{' '}
  </p>
  <h1>3. Как използваме вашата информация?</h1>
  <p>
    Ние вземаме мерки за гарантиране, че до вашите Лични данни имат достъп само
    служителите, които се нуждаят от тях, за да изпълнят трудовите си
    задължения.
  </p>
  <p>
    Ние използваме информацията, която записваме, по различни начини при
    предоставянето на Услугите и управлението на нашия бизнес, включително:
  </p>
  <ul>
    <li>
      за управление, поддържане, подобряване и предоставяне на всички функции на
      Услугите, за осигуряване на заявените от вас услуги и данни, за отговаряне
      на коментари и въпроси, и за предоставяне съдействие на потребителите на
      Услугите;
    </li>
    <li>за изпълнение на нашите задължения, произтичащи от Договора;</li>
    <li>
      за разбиране и анализиране на тенденциите и предпочитанията на нашите
      потребители, за подобряване на Услугите и за разработване на нови
      продукти, услуги, характеристики и функции;
    </li>
    <li>
      за предотвратяване и откриване на измами или злоупотреба с вашите
      продукти, Услуги и Приложението;
    </li>
    <li>за да ви изпращаме съобщения в съответствие с приложимите закони;</li>
    <li>
      или за спазване на законови и регулаторни изисквания, когато е приложимо.
    </li>
  </ul>
  <h1>4. С кого споделяме вашата информация?</h1>
  <p>
    При определени обстоятелства и за изпълнение на Услугите, може да разкрием
    определена информация, която получаваме от вас:
  </p>
  <ul>
    <li>
      в рамките на нашата група от компании, която може да включва
      компании-майки, корпорации, филиали, дъщерни дружества, бизнес отдели и
      други компании, които имат съвместна собственост;
    </li>
    <li>с Вашия работодател в съответствие с Договора;</li>
    <li>
      с доставчици на услуги трети страни, работещи от наше име, които изискват
      достъп до вашата информация за изпълнение на своята работа (напр.
      съдействие на клиенти, фактуриране и т.н.);
    </li>
    <li>
      и на приобретател, наследник или правоприемник като част от всяко сливане,
      придобиване или подобна сделка
    </li>
  </ul>
  <p>
    Когато разкриваме вашите лични данни на трети страни, ние предприемаме
    разумни мерки, за да гарантираме спазването на правилата, изложени в тази
    Политика за защита на личните данни и предоставянето на достатъчни гаранции
    за прилагане на подходящи технически и организационни мерки от тези трети
    страни.
  </p>
  <p>
    Можем също да предоставим на трети страни определена автоматично записана,
    обобщена или друга нелична информация за различни цели, включително (i)
    спазване на различни задължения за докладване; (ii) за служебни или
    маркетингови цели; или (iii) за подпомагане на тези страни да разберат
    вашите интереси, навици и модели на използване за определени програми,
    съдържание, услуги и/или функции, налични чрез Услугите.
  </p>
  <h1>5. Как съхраняваме вашата информация?</h1>
  <p>
    Вашите лични данни може да се съхраняват и обработват във всяка държава, в
    която имаме центрове или в която ангажираме доставчици на услуги. Като
    посещавате Уебсайта, изтегляте Приложението или използвате Услугите, вие се
    съгласявате с прехвърлянето на информация в държави извън вашата страна на
    пребиваване, които може да имат различни правила за защита на данните от
    тези във вашата страна.{' '}
  </p>
  <p>
    Въпреки това, нашите практики по отношение на вашите Лични данни ще
    продължат да се управляват винаги от тази Политика за защита на личните
    данни и, ако е приложимо, ще спазваме изискванията на Общия регламент
    относно защитата на данните („ОРЗД“), осигуряващи адекватна защита за
    прехвърлянето на Лични данни от ЕС/ЕИП към трета държава.
  </p>
  <p>
    Ние въведохме различни физически, административни и технически предпазни
    мерки, предназначени за защита на поверителността и сигурността на Личните
    данни под наш контрол. Въпреки това, няма абсолютни или напълно гарантирани
    мерки за сигурност и трябва да сте наясно, че винаги има определено ниво на
    риск предоставените от вас Лични данни да бъдат разкрити без вашето съгласие
    и не по вина на theEMPLOYEEapp. Ако считате, че е имало нарушение на
    защитата на вашите Лични данни, моля, свържете се с нас, както е посочено в
    раздела „Свържете се с нас“. Ако научим за пробив в системите за сигурност,
    ние ще информираме вас и властите за възникването на пробива в съответствие
    с приложимото законодателство.
  </p>
  <p>
    Ние ще съхраняваме вашите Лични данни само толкова дълго, колкото е
    основателно необходимо, за да изпълним съответните цели, посочени в тази
    Политика за защита на личните данни, и за да спазим нашите законови и
    регулаторни задължения.{' '}
  </p>
  <h1>6. Права по отношение на вашите Лични данни</h1>
  <p>
    Потвърждаваме, че имате право на достъп и коригиране на вашите Лични данни в
    съответствие с приложимото законодателство или допълнителни права съгласно
    ОРЗД. Ние събираме информация под ръководството на вашия работодател и
    нямаме пряко взаимоотношение с вас. Ако не желаете повече вашият работодател
    да се свързва с вас и/или искате да получите достъп или да коригирате вашите
    Лични данни, моля, свържете се с вашия работодател, с когото общувате
    директно.
  </p>
  <h1>7. Защита на личните данни на децата</h1>
  <p>
    Услугите не са предназначени за деца под 16-годишна възраст и ние не
    събираме съзнателно Лични данни от деца под 16-годишна възраст, без да
    получим родителско съгласие. Ако не сте навършили 16 години, моля, не
    използвайте и не осъществявайте достъп до Услугите по какъвто и да е начин.
    Ако научим, че са събрани Лични данни чрез Услугите от лица под 16-годишна
    възраст и без удостоверимо родителско съгласие, ще предприемем съответните
    действия, за да изтрием тази информация. Ако сте родител или настойник и
    установите, че вашето дете под 16-годишна възраст е предоставило Лични
    данни, можете да ни уведомите, както е посочено в раздела „Свържете се с
    нас“, и да поискате да изтрием Личните данни на детето от нашите системи.
  </p>
  <h1>8. Актуализация</h1>
  <p>
    Ние ще актуализираме периодично тази Политика за защита на личните данни, за
    да отразява промените в нашите практики, технологии, правни изисквания и
    други фактори. Моля, проверете „Дата на влизане в сила“ в горната част на
    тази страница, за да видите кога е била актуализирана за последен път тази
    Политика за защита на личните данни. Промените в тази Политика за защита на
    личните данни влизат в сила незабавно след публикуването им в актуализирана
    Политика за защита на личните данни, публикувана на тази страница, освен ако
    не е посочено друго. Използването на Услугите след тези промени показва
    вашето съгласие с практиките, описани в актуализираната Политика за защита
    на личните данни.
  </p>
  <h1>9. Свържете се с нас</h1>
  <p>
    Ако имате въпроси или коментари относно тази Политика за защита на личните
    данни или вашите Лични данни, за упражняване на всички приложими права, за
    подаване на жалба или за получаване на информация относно нашите политики и
    практики, можете да се свържете с нашето длъжностно лице по защита на
    Личните данни по пощата или имейл, като използвате следните данни за връзка:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Bulgarian
