import React from 'react'

function French () {
  return (
<>
  <title>theEMPLOYEEapp - Politique de confidentialité</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Politique de confidentialité</h1>
  <h4>Date d’entrée en vigueur : 15 mars 2023</h4>
  <p>
    theEMPLOYEEapp (« theEMPLOYEEapp » ou « nous ») s'engage à assurer à ses
    clients et utilisateurs la transparence concernant ses pratiques de
    traitement de leurs Informations personnelles. à cette fin, la présente
    Politique de confidentialité décrit la manière dont nous collectons,
    utilisons et divulguons les Informations personnelles, ainsi que la manière
    dont lesdites Informations personnelles peuvent être consultées et corrigées
    le cas échéant.
  </p>
  <p>
    En accédant au{' '}
    <a href="https://theemployeeapp.com/">site Web (« Site Web »)</a>, en
    téléchargeant l'application mobile associée (« Application ») ou en
    utilisant les services associés (collectivement « Services »), en nous
    contactant ou en acceptant de recevoir des e-mails de notre part, vous
    acceptez les dispositions de la présente Politique de confidentialité.
  </p>
  <p>
    La présente Politique de confidentialité ne s'étend pas aux sites Web ou aux
    services exploités par des tiers. Nous déclinons donc toute responsabilité
    quant à leurs politiques, procédures et pratiques en matière de protection
    des Informations personnelles.
  </p>
  <p>
    La présente Politique de confidentialité est incorporée et soumise à notre
    Accord de logiciel en tant que service (« Accord »).
  </p>
  <h1>1. Quelle est notre relation avec votre employeur ?</h1>
  <p>
    Nous avons conclu un accord avec votre employeur pour vous donner accès à
    l'Application et aux Services (« Accord »). Nous traiterons vos Informations
    personnelles au nom de votre employeur et conformément à ses instructions
    licites. Les informations que vous nous fournissez ou que vous transférez
    par le biais de l'Application (qu'elles constituent ou non des Informations
    personnelles) seront également régies par l'Accord.
  </p>
  <p>
    Les références à « votre employeur » dans la présente Politique de
    confidentialité désignent l'entité qui a conclu l'Accord avec nous, que vous
    soyez ou non, sur le plan juridique, un employé, un consultant ou un
    contractant de ladite entité, et lesdites références ne sont pas destinées à
    caractériser ou à porter préjudice à votre statut vis-à-vis de ladite
    entité.
  </p>
  <h1>2. Quelles informations collectons-nous ?</h1>
  <p>
    Les types d'Informations personnelles et autres que nous pouvons collecter à
    votre sujet proviennent principalement des sources suivantes : (a)
    informations que vous fournissez ; (b) informations fournies par votre
    employeur ; (c) informations collectées par le biais de l'Application ; (d)
    informations collectées par le biais de notre service SMS et (e)
    informations collectées automatiquement. Les bases juridiques de notre
    traitement des Informations personnelles reposent principalement sur le fait
    que ledit traitement est nécessaire pour fournir les services et que ledit
    traitement est effectué dans nos intérêts légitimes, tels qu'ils sont
    expliqués plus en détail dans la section « Comment utilisons-nous vos
    informations ? ». Nous pouvons également traiter des Informations
    personnelles avec votre consentement, en vous le demandant le cas échéant.
  </p>
  <p>
    <strong>
      <u>a) Info que vous fournissez</u>
    </strong>
  </p>
  <p>
    Lorsque vous accédez aux Services, nous pouvons vous demander de nous
    fournir volontairement certaines informations qui vous identifient
    personnellement ou qui pourraient être utilisées pour vous identifier
    personnellement (« Informations personnelles »). Les Informations
    personnelles incluent (sans s'y limiter) les catégories d'informations
    suivantes : (1) coordonnées (telles que l'adresse de votre lieu de travail,
    votre adresse électronique et votre numéro de téléphone) ; (2) informations
    démographiques (telles que votre code postal) ; (3) informations
    professionnelles (telles que le nom de votre entreprise, votre poste et
    votre numéro d'employé) ; et (4) autres informations permettant de vous
    identifier et que vous choisissez volontairement de nous fournir ou qui sont
    exigées par votre employeur, y compris, sans s'y limiter, des identifiants
    uniques tels que des mots de passe, des photos ou des Informations
    personnelles figurant dans les e-mails ou des courriers que vous nous
    envoyez. Nous pouvons également collecter des informations supplémentaires,
    qui peuvent être des Informations personnelles, comme cela vous est décrit
    au moment de la collecte ou conformément à votre consentement. Si vous
    choisissez de ne pas divulguer certaines Informations personnelles que nous
    vous demandons, vous risquez de ne pas pouvoir accéder à certaines parties
    des Services.
  </p>
  <p>
    <strong>
      <u>b) Informations fournies par votre employeur</u>
    </strong>
  </p>
  <p>
    Votre employeur peut nous fournir des informations vous concernant : (1)
    lors de la création d'un profil d'utilisateur pour vous afin de vous
    permettre d'accéder à l'Application en vertu de l'Accord ou de nous
    permettre de fournir nos Services, y compris la messagerie textuelle.
    Lesdites informations peuvent inclure votre nom, votre adresse e-mail, votre
    numéro de téléphone fixe ou mobile, votre titre de poste, votre département
    ; (2) autrement dans le cadre de l'utilisation de l'Application par votre
    employeur.
  </p>
  <p>
    <strong>
      <u>c) Informations collectées par le biais de l'Application</u>
    </strong>
  </p>
  <p>
    Lorsque vous effectuez certaines actions au sein de ladite Application, vous
    pouvez être invité à donner accès aux éléments suivants. Vous pouvez refuser
    chaque action.
  </p>
  <ol>
    <li>Identité</li>
    <li>Calendrier</li>
    <li>Photos/Médias/Fichiers</li>
    <li>Microphone</li>
    <li>Connexion Wi-Fi</li>
    <li>Identifiant de l'appareil</li>
  </ol>
  <p>
    L'accès serait nécessaire pour les actions suivantes au sein de
    l'application :
  </p>
  <ul>
    <li>
      La demande d'Identité est seulement effectuée pour déterminer à qui
      appartient le compte.
    </li>
    <li>
      La demande de Calendrier demande une permission pour accéder à votre
      calendrier pour que l'application puisse ajouter les éléments de votre
      choix à votre calendrier.
    </li>
    <li>
      La demande de Photos/Médias/Fichiers vous permet d'enregistrer les
      fichiers inclus dans votre application pour une utilisation hors-ligne.
      Pour ce faire, l'application doit accéder à vos fichiers. L'application
      n'altère pas vos informations. Elle vous permet seulement d'enregistrer un
      fichier de votre choix pour y accéder plus tard, même lorsqu'une connexion
      Internet n'est pas disponible.
    </li>
    <li>
      La demande de Microphone est utilisée pour les événements en direct ou
      pour enregistrer des vidéos, une fonctionnalité prise en charge par cette
      application.
    </li>
    <li>
      La demande de Connexion Wi-Fi est utilisée pour déterminer si vous
      utilisez une connexion Wi-Fi ou mobile et optimiser ainsi les performances
      de l'application.
    </li>
    <li>
      La demande d'Identifiant de l'appareil vous permet de recevoir des
      notifications push dans l'application.
    </li>
  </ul>
  <p>
    <strong>
      <u> </u>
    </strong>
  </p>
  <p>
    <strong>
      <u>d) Informations collectées par le biais de notre service SMS</u>
    </strong>
  </p>
  <p>
    Pour utiliser nos SMS, vous pouvez être invité à fournir : votre numéro de
    téléphone mobile et vos préférences en matière d'envoi de SMS (inscriptions
    et désinscriptions). Votre employeur utilise ces informations pour gérer
    notre programme de messagerie textuelle, y compris pour vous envoyer des
    messages textuels. Votre opérateur de téléphonie mobile peut collecter des
    informations sur l'utilisation de votre appareil sans fil et ses pratiques
    sont régies par ses propres politiques.
  </p>
  <p>
    <strong>
      <u>e) Informations collectées automatiquement</u>
    </strong>
  </p>
  <p>
    Notre plateforme peut également accéder aux informations de l'appareil
    telles que le type de l'appareil, la version, le système d'exploitation, le
    type et la version du navigateur (le cas échéant) ainsi qu'à des
    informations concernant votre utilisation des Services à des fins
    d'analyses.{' '}
  </p>
  <p>
    Nous pouvons également obtenir des informations de la part de tiers,
    notamment Google Analytics, et combiner lesdites informations avec les
    informations que nous collectons.{' '}
  </p>
  <h1>3. Comment utilisons-nous vos informations ?</h1>
  <p>
    Nous prenons des mesures pour garantir que seuls les employés ayant besoin
    d'accéder à vos Informations personnelles dans le cadre de leurs fonctions y
    ont accès.
  </p>
  <p>
    Nous utilisons les informations que nous collectons de diverses manières
    pour fournir les Services et gérer nos activités, y compris :
  </p>
  <p>
    ● pour exploiter, maintenir, améliorer et fournir toutes les fonctionnalités
    des Services, fournir les services et les informations que vous demandez,
    répondre aux commentaires et aux questions et fournir une assistance aux
    utilisateurs des Services ;
  </p>
  <p>● pour nous acquitter de nos obligations découlant de l'Accord ;</p>
  <p>
    ● pour comprendre et analyser les tendances d'utilisation et les préférences
    de nos utilisateurs, améliorer les Services et développer de nouveaux
    produits, services, fonctions et fonctionnalités ;
  </p>
  <p>
    ● pour prévenir et détecter la fraude ou l'utilisation abusive de vos
    produits, des Services et de l'Application ;
  </p>
  <p>
    ● pour vous envoyer des communications dans le respect des lois en vigueur ;
  </p>
  <p>
    ● ou pour nous conformer aux exigences légales et réglementaires le cas
    échéant.
  </p>
  <h1>4. Avec qui partageons-nous vos informations ?</h1>
  <p>
    Dans certaines circonstances, et afin d'exécuter les Services, nous pouvons
    divulguer certaines informations que nous collectons auprès de vous :
  </p>
  <p>
    ● au sein de notre groupe d'entreprises, qui peut comprendre des sociétés
    mères, des sociétés, des sociétés affiliées, des filiales, des unités
    commerciales et d'autres entreprises qui partagent des propriétaires communs
    ;
  </p>
  <p>● avec votre employeur conformément à l'Accord ;</p>
  <p>
    ● avec des prestataires de services tiers travaillant pour notre compte et
    qui ont besoin d'accéder à vos informations pour mener à bien leur travail
    (par exemple, assistance à la clientèle, facturation, etc. ;)
  </p>
  <p>
    ● et à un acquéreur, successeur ou cessionnaire dans le cadre d'une fusion,
    d'une acquisition ou de toute autre transaction similaire.
  </p>
  <p>
    Lorsque nous divulguons vos Informations personnelles à des tiers, nous
    prenons des mesures raisonnables pour nous assurer que les règles énoncées
    dans la présente Politique de confidentialité sont respectées et que lesdits
    tiers présentent des garanties suffisantes pour mettre en place des mesures
    techniques et organisationnelles adéquates.
  </p>
  <p>
    Enfin, nous pouvons mettre à la disposition de tiers certaines informations
    collectées automatiquement, agrégées ou autrement non identifiables à des
    fins diverses, dont notamment (i) le respect de diverses obligations en
    matière de rapports ; (ii) à des fins commerciales ou de commercialisation ;
    ou (iii) pour aider lesdits tiers à comprendre vos intérêts, vos habitudes
    et vos profils d'utilisation de certains programmes, contenus, services ou
    fonctionnalités disponibles par le biais des Services.
  </p>
  <h1>5. Comment stockons-nous vos informations ?</h1>
  <p>
    Vos Informations personnelles peuvent être stockées et traitées dans tout
    pays où nous disposons d'installations ou dans lequel nous faisons appel à
    des prestataires de services. En visitant le Site Web, en téléchargeant
    l'Application ou en utilisant les Services, vous consentez au transfert des
    informations vers des pays situés en dehors de votre pays de résidence, qui
    peuvent avoir des réglementations en matière de protection des informations
    différentes de celles de votre pays.{' '}
  </p>
  <p>
    Toutefois, nos pratiques concernant vos Informations personnelles
    continueront à tout moment d'être régies par la présente Politique de
    confidentialité et, le cas échéant, nous nous conformerons aux exigences du
    Règlement général sur la protection des informations (« RGPD ») offrant une
    protection adéquate pour le transfert des Informations personnelles de
    l'UE/l'EEE vers un autre pays.
  </p>
  <p>
    Nous avons mis en place un ensemble de mesures de protection physiques,
    administratives et techniques conçues pour protéger la confidentialité et la
    sécurité des Informations personnelles sous notre contrôle. Cependant,
    aucune mesure de sécurité n'est absolue ou totalement garantie et vous devez
    savoir qu'il existe toujours un certain niveau de risque que les
    Informations personnelles que vous nous fournissez soient divulguées sans
    votre consentement et sans faute de la part de theEMPLOYEEapp. Si vous
    pensez que vos Informations personnelles ont été compromises, veuillez nous
    contacter comme indiqué dans la section « Nous contacter ». Si nous sommes
    informés d'une violation des systèmes de sécurité, nous vous informerons,
    ainsi que les autorités, de la survenance de la violation conformément à la
    législation en vigueur.
  </p>
  <p>
    Nous ne conserverons vos Informations personnelles que pendant la durée
    raisonnablement nécessaire pour remplir les objectifs pertinents définis
    dans la présente Politique de confidentialité et pour nous conformer à nos
    obligations légales et réglementaires.{' '}
  </p>
  <h1>6. Droits concernant vos Informations personnelles</h1>
  <p>
    Nous reconnaissons que vous avez le droit d'accéder à vos Informations
    personnelles et de les corriger, conformément à la législation en vigueur ou
    aux droits supplémentaires prévus par le RGPD. Nous collectons des
    informations sous la direction de votre employeur et n'avons aucun rapport
    direct avec vous. Si vous ne souhaitez plus être contacté par votre
    employeur ou si vous souhaitez accéder à vos Informations personnelles ou
    les corriger, veuillez contacter directement l'employeur avec lequel vous
    interagissez.
  </p>
  <h1>7. Protection de la vie privée des enfants</h1>
  <p>
    Les services ne sont pas destinés aux enfants de moins de 16 ans et nous ne
    collectons pas sciemment des informations personnelles auprès d'enfants de
    moins de 16 ans sans obtenir le consentement de leurs parents. Si vous avez
    moins de 16 ans, n'utilisez pas les Services et n'y accédez pas, à n'importe
    quel moment et de n'importe quelle manière. Si nous constatons que des
    informations personnelles ont été collectées par le biais des Services
    auprès de personnes âgées de moins de 16 ans et sans consentement parental
    vérifiable, nous prendrons les mesures nécessaires pour supprimer lesdites
    informations. Si vous êtes un parent ou un tuteur et que vous découvrez que
    votre enfant de moins de 16 ans a fourni des informations personnelles, vous
    pouvez nous alerter comme indiqué à la section « Nous contacter » et nous
    demander de supprimer les informations personnelles dudit enfant de nos
    systèmes.
  </p>
  <h1>8. Mise à jour</h1>
  <p>
    Nous mettrons à jour la présente Politique de confidentialité de temps à
    autre afin de refléter les changements apportés à nos pratiques,
    technologies, exigences légales et autres facteurs. Veuillez vérifier la «
    Date d'entrée en vigueur » en haut de la présente page pour connaître la
    date de la dernière mise à jour de la Politique de confidentialité. Lorsque
    des modifications sont apportées à la présente Politique de confidentialité,
    celles-ci entrent immédiatement en vigueur lorsqu'elles sont publiées dans
    une mise à jour de la Politique de confidentialité affichée sur la présente
    page, sauf indication contraire. Votre utilisation continue des Services
    après lesdites modifications indique que vous acceptez les pratiques
    décrites dans la Politique de confidentialité mise à jour.
  </p>
  <h1>9. Nous contacter</h1>
  <p>
    Si vous avez des questions ou des commentaires sur la présente politique de
    confidentialité ou sur vos informations personnelles, ou si vous souhaitez
    exercer les droits applicables, déposer une réclamation ou obtenir des
    informations sur nos politiques et pratiques, vous pouvez contacter notre
    responsable confidentialité par courrier ou par e-mail en utilisant les
    coordonnées suivantes :
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default French
