/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// import Modal from 'react-modal'
import styled from 'styled-components'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import color from 'michelangelo/dist/Components/styles/color'
import GET_SIGNED_DOWNLOAD_URL from '../../apollo/queries/getSignedDownloadUrl'
import { useLazyQuery, useMutation } from '@apollo/client'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

const ConfirmationDialogWrapper = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: ${(props) => props.width ? props.width : '400'}px;
  height: auto;
  left: ${(props) => props.width ? 'calc(50% - ' + props.width + 'px/2)' : 'calc(50% - 400px/2)'};
  top: ${(props) => props.height ? 'calc(22vh)' : 'calc(22vh)'};
  background: #FFFFFF;
  box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
  align-items: center;
`

const Footer = styled.div`
  background: #EEF2F6;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 15px;
  justify-content: flex-end;
  height: 70px;
`

const Gap = styled.div`
  width: 15px;
  height: 15px;
`

const BoddyWrapper = styled.div`
  border-top: 1px solid #e0dddd;
  padding: 20px 40px 20px 40px;
  width: 100%;
  height: 400px;
  overflow-y: auto;
`

const ImageModal = ({ isOpen, onRequestClose, content, accountId }) => {
  const [selectedImages, setSelectedImages] = useState([])
  const [getSignedDownloadUrl, { loading, data }] = useLazyQuery(
    GET_SIGNED_DOWNLOAD_URL,
    { variables: { s3Key: 'content.s3Key', accountId } }
  )
  const handleImageClick = (imageId) => {
    if (selectedImages.includes(imageId)) {
      setSelectedImages(selectedImages.filter(id => id !== imageId))
    } else {
      setSelectedImages([...selectedImages, imageId])
    }
  }

  const handleCancel = () => {
    setSelectedImages([])
    onRequestClose(false)
  }

  const handleDownload = async () => {
    if (selectedImages.length === 0) return

    if (selectedImages.length === 1) {
      const imageId = selectedImages[0]
      const image = content.childContents.find(content => content.id === imageId)
      const result = await getSignedDownloadUrl({ variables: { s3Key: image.s3Key, accountId } })
      try {
        const response = await fetch(result.data.getSignedDownloadUrl.signedUrl)
        const blob = await response.blob()
        saveAs(blob, `${image.s3Key}.jpg`)
        setSelectedImages([])
        onRequestClose(true)
      } catch (err) {
        console.error('Error downloading image.', err)
        saveAs(result.data.getSignedDownloadUrl.signedUrl, `${image.s3Key}.jpg`)
      }
    } else {
      const zip = new JSZip()
      const promises = selectedImages.map((imageId) => {
        const childContent = content.childContents.find(content => content.id === imageId)
        return new Promise((resolve, reject) => {
          getSignedDownloadUrl({ variables: { s3Key: childContent.s3Key, accountId } })
            .then(async (result) => {
              const response = await fetch(result.data.getSignedDownloadUrl.signedUrl, {
                cache: 'no-cache'
              })
              const blob = response.blob()
              resolve({ id: childContent.id, blob })
            })
            .catch((error) => {
              // Handle error and reject the promise
              reject(error)
            })
        })
      })
      const results = await Promise.allSettled(promises)
      let validate = true
      // Process results
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          // Handle fulfilled promises (successful responses)
          zip.file(`${result.value.id}.jpg`, result.value.blob, { binary: true })
          console.log('Signed URL:', result.value)
        } else {
          // Handle rejected promises (errors)
          validate = false
          console.error('Error fetching signed URL:', result.reason)
        }
      })
      if (validate) {
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          saveAs(blob, `${content.title}.zip`)
          setSelectedImages([])
          onRequestClose(true)
        })
      } else {
        console.error('Something went wrong while downloading files.')
      }
    }
  }

  return (
    <ConfirmationDialogWrapper className='trashAlt' visible={isOpen}>
      <Modal width={600}>
        <h1 style={{ textAlign: 'center', padding: '20px', fontSize: 'larger', fontWeight: 'bold' }}>Select Gallery Images</h1>
        <BoddyWrapper>
        {content.childContents.map((content) => (
          <div key={content.id} style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              checked={selectedImages.includes(content.id)}
              onChange={() => handleImageClick(content.id)}
            />
            <div style={{ paddingLeft: '20px', paddingRight: '16px' }}>
              <img src={ content.signedThumbnailUrl || content.smallThumbnailUrl || content.mediumThumbnailUrl || content.largeThumbnailUrl || content.xlargeThumbnailUrl} alt={'No thumbnail'} onClick={() => handleImageClick(content.id)} style={{ height: '70px', width: '90px', objectFit: 'cover', borderRadius: '5px', filter: 'drop-shadow(2px 2px 3px black)' }} />
            </div>
            <span style={{ wordBreak: 'break-all', wordWrap: 'break-word', fontWeight: '600' }}>{content.s3Key.split('/')[1]}</span>
          </div>
        ))}
        </BoddyWrapper>
        <Footer>
          <Button ariaLabel={ 'Cancel'} title={ 'Cancel'} onClick={handleCancel} outline='true' themeColor={color.info}/>
          <Gap />
          <Button ariaLabel={'Download'} title={ 'Download'} onClick={selectedImages.length ? handleDownload : null} fill='true' themeColor={selectedImages.length ? color.success : color.lightGrey} style={{ cursor: selectedImages.length ? 'pointer' : 'not-allowed' }} />
        </Footer>
      </Modal>
    </ConfirmationDialogWrapper>
  )
}

export default ImageModal
