import { gql } from '@apollo/client'

const GET_QUICK_LINKS_QUERY = gql`
    query getQuickLinks($cfpId: String!) {
        getQuickLinks(cfpId: $cfpId, req: {includeImageGallery: true}) {
            _id
            title
            thumbnailUrl
            isQuickLink
            displayThumbnail
            contentType
            childContents {
                id
                contentType
                s3Key
                smallThumbnailUrl
                mediumThumbnailUrl
                largeThumbnailUrl
                xlargeThumbnailUrl
                priority
            }
            likesEnabled
            commentsEnabled
            htmlSignedDownloadUrl
            signedDownloadUrl
            webUrl
            secure
        }
    }
`

export default GET_QUICK_LINKS_QUERY
