// @flow
import { writeToCache } from '../apollo/cacheHelper'

type contentPreviewProps = {
    content: Object,
    location: Object,
    contentLocation: string,
    contents: Object[],
    cfpId: string,
    rootPath?: string
}

type exploreStateProps = {
    type: string,
    field: string
}

type exploreFilters = {
    folderFilters?: exploreStateProps,
    contentFilters?: exploreStateProps,
    contentPage?: number,
    folderPage?: number,
}

export const goToOldContentPreview = (navigate, client: Object, {
  content,
  activeChildContent,
  location,
  contentLocation
}: Object) => {
  writeToCache(client, { isPreviewOpen: true })
  navigate(`${location?.pathname}/contentPreview`, {
    pathname: `${location?.pathname}/contentPreview`,
    state: {
      background: location,
      contentId: content._id,
      contentLocation,
      activeChildContent
    }
  })
}

export const goToContentPreview = (navigate, {
  content,
  activeChildContent,
  cfpId,
  contents,
  location,
  contentLocation,
  rootPath
}: contentPreviewProps, filters: exploreFilters) => {
  const view = location?.pathname.includes('explore') ? 'explore' : 'newsfeed'

  navigate(`/${cfpId}/${view}-preview/${content._id}`, {
    pathname: `/${cfpId}/${view}-preview/${content._id}`,
    state: {
      background: location,
      contentId: content._id,
      contentLocation,
      contents: contents || [],
      activeChildContent,
      folderFilters: (filters && filters.folderFilters) || null,
      contentFilters: (filters && filters.contentFilters) || null,
      contentPage: (filters && filters.contentPage) || 1,
      folderPage: (filters && filters.folderPage) || 1,
      rootPath
    }
  })
}
