// @flow
/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import color from 'michelangelo/dist/Components/styles/color'
import Button from 'michelangelo/dist/SharedComponents/Buttons/Button'
import { messages } from '../../i18n/messages'
import type { InjectIntlProvidedProps } from 'react-intl'
import { injectIntl } from 'react-intl'
import ContentPreviewIFrame from './ContentPreviewIFrame'
import ContentPreviewPdf from './ContentPreviewPdf'

const ContentPreviewContainer = styled.div`
  background-color: ${(props) => props.hasBlackBackground ? color.black : color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) => props.isFullPreview && `
    flex: 1;
    height: 100%;`
  };
`
const ImageContainer = styled.img`
  ${(props) => props.isFullPreview
? `
      max-height: 100%;
      max-width: 100%;
      min-width: 200px;
      min-height: 200px;`
    : `
      max-height: ${props.maxHeight}px;
      max-width: ${props.maxWidth}px;
      flex-shrink: 0;
      height: auto;
      width: auto;
   `};
`

const DocumentViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  ${(props) => props.isMobilePreview && `
    padding: 12px 16px;
    background: black;
  `}
`
const FullScreenDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${color.white};
  padding: 32px 24px 16px 0px;
`

// added for testing purposes
// ContentPreviewContainer.displayName = 'ContentPreviewContainer'
ImageContainer.displayName = 'ImageContainer'
export type ContentType = {
  _id: string,
  contentType?: string,
  owner?: string,
  postDate: string,
  likesEnabled?: boolean,
  totalLikes?: number,
  commentsEnabled?: boolean,
  likedByUser?: boolean,
  calendar: ?string,
  s3Key: ?string,
  webUrl: ?string,
  htmlSignedDownloadUrl: ?string,
  signedDownloadUrl: string,
  hlsVideoPlaylistUrl?: string,
  mediumThumbnailUrl?: string,
  largeThumbnailUrl?: string,
  xlargeThumbnailUrl?: string,
  signedThumbnailUrl?: string,
  displayThumbnail?: number,
  contentListPosition?: number,
  ownerName: ?string,
  secure?: boolean,
  translatedTitle?: string,
  translatedCaption?: string
}
type ContentTypePreviewProps = {
  content: ContentType,
  client: Object,
  isFullPreview: boolean,
  maxImageDimensions: Object,
  me?: Object,
  formatMessage: Function,
  contentLocation: String
} & InjectIntlProvidedProps

function ContentTypePreview (props: ContentTypePreviewProps) {
  const { content, client, isFullPreview, maxImageDimensions, isMobilePreview, me, intl: { formatMessage }, contentLocation } = props

  const openProvisoryContentPreview = () => {
    const urlToOpen = content.htmlSignedDownloadUrl ? content.htmlSignedDownloadUrl : content.signedDownloadUrl
    window.open(urlToOpen)
  }

  const coalesceImageUrls = (image: ?string, url: ?string): ?string => {
    if (!image && url) {
      return url
    } else {
      return image
    }
  }
  const getImageFile = () => {
    let image: ?string

    image = coalesceImageUrls(image, content.mediumThumbnailUrl)
    image = coalesceImageUrls(image, content.largeThumbnailUrl)
    image = coalesceImageUrls(image, content.xlargeThumbnailUrl)
    image = coalesceImageUrls(image, content.signedThumbnailUrl)

    return image || (content.contentType === 'CONTENT_IMAGE' ? content.signedDownloadUrl : null)
  }

  const displayHtmlContent = (content) => {
    return (
      <DocumentViewWrapper isMobilePreview={isMobilePreview}>
        { !isMobilePreview &&
          <FullScreenDiv>
            <Button buttonPadding="4px 12px" onClick={openProvisoryContentPreview} ariaLabel={formatMessage(messages.openDocument)} title={formatMessage(messages.openDocument)} fill themeColor={color.info} disabled={false} radius={6} textTransform='inherit' width={148} height={32} />
          </FullScreenDiv>}
        <ContentPreviewIFrame downloadUrl={content.htmlSignedDownloadUrl || content.signedDownloadUrl} isMobilePreview={isMobilePreview} />
      </DocumentViewWrapper>
    )
  }

  const getViewer = () => {
    const uri = content.displayThumbnail ? getImageFile() : null
    switch (content.contentType) {
      case 'CONTENT_IMAGE':
        return <ImageContainer src={content.signedDownloadUrl || content.largeThumbnailUrl} isFullPreview={isFullPreview} maxHeight={maxImageDimensions.height - 120} maxWidth={maxImageDimensions.width} />
      case 'CONTENT_AUDIO':
        return <AudioPlayer autoPlay={true} src={content.signedDownloadUrl} thumbnail={content.mediumThumbnailUrl} content={content} client={client} me={me} formatMessage={formatMessage} contentLocation={contentLocation}/>
      case 'CONTENT_VIDEO':
        return <VideoPlayer inactivityTimeout={100} poster={content.mediumThumbnailUrl} fluid={true} controls={true} autoplay={true} sources={[{ src: content.hlsVideoPlaylistUrl || content.signedDownloadUrl || '' }]} content={content} client={client} me={me} formatMessage={formatMessage} contentLocation={contentLocation}/>
      case 'CONTENT_PDF':
      case 'CONTENT_DOCX':
      case 'CONTENT_PPTX':
      case 'CONTENT_XLSX':
      case 'CONTENT_RICH_TEXT':
        if (content.pdfS3Key) {
          return <ContentPreviewPdf downloadUrl={content.pdfS3Key} isMobilePreview={isMobilePreview} />
        }
        return displayHtmlContent(content)
      case 'CONTENT_WEB':
        return <ImageContainer src={uri} isFullPreview={isFullPreview} maxHeight={maxImageDimensions.height - 120} maxWidth={maxImageDimensions.width} />
      default:
        break
    }
  }
  const contentViewer = getViewer()
  let hasBlackBackground = false
  if (content.contentType === 'CONTENT_IMAGE' || content.contentType === 'CONTENT_AUDIO' || content.contentType === 'CONTENT_VIDEO') hasBlackBackground = true
  return (
    <ContentPreviewContainer hasBlackBackground={hasBlackBackground} isFullPreview={isFullPreview}>
      {contentViewer}
    </ContentPreviewContainer>
  )
}

export default injectIntl(ContentTypePreview)
