import React from 'react'

function Arabic () {
  return (
<>
  <title>theEMPLOYEEapp - سياسة الخصوصية</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n            direction: rtl;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>سياسة الخصوصية</h1>
  <h4>تاريخ السريان: 15 مارس 2023</h4>
  <p>
    يلتزم تطبيق theEMPLOYEEapp (المشار إليه باسم " theEMPLOYEEapp" أو الضمير
    المتصل "نا" أو "نحن") بتقديم الشفافية لعملائه ومستخدميه فيما يتعلق بممارسات
    التطبيق في معالجة معلومات العملاء الشخصية. وسعيًا منا لتحقيق هذه الغاية،
    تحدد سياسة الخصوصية هذه كيفية جمع المعلومات الشخصية واستخدامها والكشف عنها
    وكيفية الرجوع إلى هذه المعلومات الشخصية وتصحيحها عند الضرورة.
  </p>
  <p>
    عبر زيارة <a href="https://theemployeeapp.com/">موقع الويب ("الويب")</a>، أو
    تنزيل تطبيق الهاتف المحمول المرتبط به ("التطبيق") أو استخدام الخدمات
    المرتبطة به (يشار إليها مجتمعة باسم "الخدمات")، عن طريق الاتصال بنا أو
    الموافقة على استلام رسائل البريد الإلكتروني منا، فإن المستخدم يوافق على شروط
    وأحكام سياسة الخصوصية هذه.
  </p>
  <p>
    لا تمتد سياسة الخصوصية هذه إلى مواقع الويب أو الخدمات التي تديرها الجهات
    الخارجية؛ لذلك لا نتحمل المسؤولية عن سياسات الخصوصية والإجراءات والممارسات
    المتعلقة بحماية المعلومات الشخصية.
  </p>
  <p>
    تُدمج سياسة الخصوصية هذه في برمجياتنا وتكون خاضعة لها في صورة اتفاقية
    الخدمات ("الاتفاقية"){' '}
  </p>
  <h1>1. ما هي علاقتنا مع صاحب العمل؟</h1>
  <p>
    لقد عقدنا اتفاقية مع صاحب العمل لمنح المستخدم أحقية الوصول إلى التطبيق
    والخدمات ("الاتفاقية")، حيث تتم معالجة معلومات المستخدم الشخصية نيابة عن
    صاحب العمل الخاص بالمستخدم ووفقًا لتعليماته القانونية، علاوة على ذلك، فإن
    المعلومات أيضًا التي يقدمها المستخدم لنا و/ أو التي يتم تحميلها عبر التطبيق
    (سواءً كانت تعتبر معلومات شخصية أم لا) تخضع لأحكام الاتفاقية.
  </p>
  <p>
    تشير الإحالات في سياسة الخصوصية هذه إلى "صاحب العمل الخاص بالمستخدم" إلى
    الكيان الذي أبرم الاتفاقية معنا، سواء كان المستخدم موظفًا أو استشاريًا أو
    متعاقدًا مع هذا الكيان أم لا، دون أن تكون النية بهذه الإحالات توصيف وضع
    المستخدم أو المساس به تجاه هذا الكيان.
  </p>
  <h1>2. ما هي المعلومات التي يتم جمعها؟</h1>
  <p>
    أنواع المعلومات الشخصية والمعلومات الأخرى التي نجمعها عن المستخدم ترد إلينا
    في الأساس من المصادر الآتية: (أ) المعلومات التي زودنا بها المستخدم؛ (ب)
    المعلومات التي زودنا بها صاحب العمل؛ (ج) المعلومات التي يتم جمعها عبر
    التطبيق؛ (د) المعلومات التي يتم جمعها عبر خدمة الرسائل النصية القصيرة الخاصة
    بنا؛ و (هـ) المعلومات التي يتم جمعها تلقائيًا. تكمن الأسس القانونية
    لمعالجتنا للمعلومات الشخصية بالأساس في ضرورة المعالجة لتقديم الخدمات وإتمام
    المعالجة وفقًا لمصالحنا المشروعة، والتي تم شرحها بمزيد من التفصيل في قسم
    "كيفية استخدام معلومات المستخدم؟"، كما يجوز أيضًا معالجة المعلومات الشخصية
    بناءً على موافقة المستخدم، ويكون الطلب حسب الاقتضاء.
  </p>
  <p>
    <strong>
      <u>‌أ. المعلومات التي زودنا بها المستخدم</u>
    </strong>
  </p>
  <p>
    عند الوصول إلى الخدمات، يجوز أن نطلب من المستخدم طواعية تزويدنا بمعلومات
    معينة تحدد هوية المستخدم الشخصية أو يمكن استخدامها لتحديد هوية المستخدم
    ("المعلومات الشخصية"). تتضمن المعلومات الشخصية (على سبيل المثال لا الحصر)
    أنواع المعلومات التالية: (1) بيانات الاتصال (مثل عنوان مكتب المستخدم وعنوان
    بريده الإلكتروني ورقم هاتفه)؛ (2) البيانات الديموغرافية (مثل الرمز البريدي
    الخاص بالمستخدم)؛ (3) المعلومات المهنية (مثل اسم شركة المستخدم ووظيفته
    ومعرّف الموظف الخاص به)؛ و (4) معلومات التعريف الأخرى التي يختار المستخدم
    تقديمها لنا طواعية و / أو التي يطلبها صاحب العمل، بما في ذلك على سبيل المثال
    لا الحصر المعرفات المميزة مثل كلمات المرور أو الصور أو المعلومات الشخصية في
    رسائل البريد الإلكتروني أو الرسائل المرسلة إلينا. يجوز أيضًا جمع المعلومات
    الإضافية، والتي قد تكون معلومات شخصية، كما هو موضح للمستخدم بخلاف ذلك في
    نقطة جمع المعلومات أو بناءً على موافقة المستخدم. إذا اختار المستخدم حجب أي
    معلومات شخصية مطلوبة، فقد لا يتمكن المستخدم من الوصول إلى أجزاء معينة من
    الخدمة.{' '}
  </p>
  <p>
    <strong>
      <u>‌ب. المعلومات التي زودنا بها صاحب العمل </u>
    </strong>
  </p>
  <p>
    يُمكن لصاحب العمل أن يزودنا بمعلومات عن المستخدم: (1) عند إنشاء ملف تعريف
    خاص بالمستخدم من أجل تمكينه من أحقية الوصول إلى التطبيق بموجب الاتفاقية أو
    لتمكيننا من تقديم خدماتنا بما في ذلك الرسائل النصية القصيرة. يجوز أن تتضمن
    هذه المعلومات اسم المستخدم وعنوان بريده الإلكتروني ورقم هاتفه أو هاتفه
    المحمول والمسمى الوظيفي والقسم؛ (2) أو غيرها من المعلومات اللازمة أثناء
    استخدام صاحب العمل للتطبيق.
  </p>
  <p>
    <strong>
      <u>‌ج. المعلومات التي يتم جمعها عبر التطبيق</u>
    </strong>
  </p>
  <p>
    قد يُطلب من المستخدم منح أحقية الوصول إلى ما يلي ذكره عند القيام بإجراءات
    معينة داخل هذا التطبيق، كما يمكن للمستخدم الانسحاب من كل إجراء.
  </p>
  <ol>
    <li>الهوية</li>
    <li>التقويم</li>
    <li>الصور / الوسائط / الملفات</li>
    <li>الميكروفون</li>
    <li>الاتصال بجهاز الواي فاي</li>
    <li>معرّف الجهاز</li>
  </ol>
  <p>يكون الوصول مطلوبًا للإجراءات التالية داخل التطبيق:</p>
  <ul>
    <li>يكون طلب الهوية فقط لتحديد من هو صاحب الحساب</li>
    <li>
      يستهدف طلب التقويم الحصول على إذن للوصول إلى التقويم الخاص بالمستخدم حتى
      يتمكن التطبيق من إضافة عناصر من اختيار المستخدم إلى التقويم الخاص به.
    </li>
    <li>
      يسمح طلب الصور / الوسائط / الملفات للمستخدم بحفظ الملفات المضمنة في
      التطبيق الخاص به للاستخدام دون اتصال. لتنفيذ ذلك الإجراء، يحتاج التطبيق
      إلى الوصول إلى ملفات المستخدم دون أن يغير التطبيق معلومات المستخدم؛ بحيث
      يسمح للمستخدم ببساطة بحفظ ملف من اختيار المستخدم للوصول إليه في وقت لاحق،
      حتى في حالة عدم توفر اتصال بالإنترنت.
    </li>
    <li>
      يتم استخدام طلب تشغيل الميكروفون لفاعليات البث المباشرة أو لتسجيل مقاطع
      الفيديو، والتي يمكن أن يدعمها هذا التطبيق.
    </li>
    <li>
      طلب الاتصال بجهاز الواي فاي لتحديد الاتصال بجهاز الواي فاي أو الاتصال
      الخلوي للحصول على الأداء الأمثل للتطبيق.
    </li>
    <li>طلب معرّف الجهاز يسمح للمستخدم باستلام الإشعارات داخل التطبيق.</li>
  </ul>
  <p>
    <strong>
      <u>‌د. المعلومات التي تم جمعها عبر خدمتنا للرسائل النصية القصيرة</u>
    </strong>
  </p>
  <p>
    لاستخدام خدمة الرسائل النصية القصيرة الخاصة بنا، يجوز أن يُطلب من المستخدم
    تقديم ما يلي: رقم هاتفه المحمول وتفضيلاته في الرسائل النصية (الاشتراكات
    وإلغاء الاشتراك). يستخدم صاحب العمل هذه المعلومات لإدارة برنامج المراسلة
    النصية الخاص بنا، بما في ذلك إرسال رسائل نصية إلى المستخدم، كما يجوز لشركة
    الاتصالات اللاسلكية الخاصة بالمستخدم بجمع بيانات حول استخدامه للجهاز
    اللاسلكي، وتخضع ممارسات الشركة لسياساتها الخاصة.
  </p>
  <p>
    <strong>
      <u>‌ه. المعلومات التي يتم جمعها تلقائيًا</u>
    </strong>
  </p>
  <p>
    يجوز لمنصتنا أيضًا إتاحة إمكانية أحقية الوصول إلى معلومات الجهاز مثل نوع
    الجهاز والإصدار ونظام التشغيل ونوع المتصفح وإصداره (متى أمكن ذلك) بالإضافة
    إلى المعلومات المتعلقة باستخدام المستخدم للخدمات وذلك لأغراض تحليلية.{' '}
  </p>
  <p>
    يجوز لنا الحصول أيضًا على معلومات من الجهات الخارجية، بما في ذلك تحليلات
    جوجل، ودمجها مع المعلومات التي نجمعها.{' '}
  </p>
  <h1>3. كيفية استخدام معلومات المستخدم؟</h1>
  <p>
    نتخذ خطوات مصممة لضمان أن الموظفين الذين يحتاجون إلى الوصول إلى معلومات
    المستخدم الشخصية للوفاء بمهامهم الوظيفية هم فقط من يمتلكون أحقية الوصول
    إليها.
  </p>
  <p>
    نستخدم المعلومات التي نجمعها بعدة طرق في تقديم خدماتما وتشغيل أعمالنا، بما
    في ذلك:
  </p>
  <ul>
    <li>
      تشغيل وصيانة وتطوير وتقديم جميع ميزات الخدمات، وتوفير الخدمات والمعلومات
      التي يطلبها المستخدم، وللرد على التعليقات والأسئلة ولتقديم الدعم لمستخدمي
      الخدمات
    </li>
    <li>تنفيذ التزاماتنا الناشئة عن الاتفاقية</li>
    <li>
      فهم اتجاهات الاستخدام وتفضيلات مستخدمينا وتحليلها، وتحسين الخدمات، وتطوير
      المنتجات والخدمات والميزات والمهام الجديدة
    </li>
    <li>
      منع واكتشاف الاحتيال أو إساءة استخدام منتجات المستخدم وخدماته وتطبيقاته
    </li>
    <li>إرسال رسائل إلى المستخدم وفقًا للقوانين المعمول بها</li>
    <li>أو الامتثال للالتزامات القانونية والتنظيمية عند الاقتضاء.</li>
  </ul>
  <h1>4. من يمكنهم مشاركة معلومات المستخدم معنا؟</h1>
  <p>
    يجوز الكشف عن بعض المعلومات التي نجمعها من المستخدم تحت ظروف معينة، ومن أجل
    أداء الخدمات كالتالي:
  </p>
  <ul>
    <li>
      ضمن مجموعة شركاتنا، والتي قد تشمل الشركات الأم والشركات التابعة والفروع
      ووحدات الأعمال والشركات الأخرى التي تشترك في الملكية المشتركة
    </li>
    <li>مع صاحب العمل وفقًا للاتفاقية</li>
    <li>
      مع مزودي الخدمة من الغير ممن يعملون نيابة عنا بما يتطلب الوصول إلى معلومات
      المستخدم لتنفيذ عملهم (على سبيل المثال، دعم العملاء، والفواتير، وما إلى
      ذلك)
    </li>
    <li>
      مع الشركات المستحوذة أو الخلف أو المتنازل لهم كجزء من أي عمليات للاندماج
      أو الاستحواذ أو الصفقات المماثلة
    </li>
  </ul>
  <p>
    في حالة الكشف عن معلومات المستخدم الشخصية للغير، فإننا نتخذ تدابير معقولة
    لضمان الامتثال للقواعد المنصوص عليها في سياسة الخصوصية هذه وتوفيرها من الغير
    كضمانات كافية لتنفيذ التدابير الفنية والتنظيمية المناسبة.
  </p>
  <p>
    يجوز لنا أخيرًا توفير بعض المعلومات التي يتم جمعها تلقائيًا أو تجميعها أو
    غير ذلك من معلومات التعريف الشخصية للغير لأغراض مختلفة، بما في ذلك (1)
    الامتثال لالتزامات تقديم التقارير المختلفة؛ (2) للأغراض التجارية أو
    التسويقية؛ أو (3) لمساعدة هذه الأطراف في فهم اهتمامات المستخدم وعاداته
    وأنماط استخدامه لبعض البرمجيات والمحتوى والخدمات و / أو المهام المتاحة من
    خلال الخدمات.
  </p>
  <h1>5. كيف يتم تخزين معلومات المستخدم ؟</h1>
  <p>
    يتم تخزين معلومات المستخدم الشخصية ومعالجتها في أي بلد نمتلك فيه مرافق أو
    نشترك فيه مع مزدي الخدمات. من خلال الدخول على موقع الويب أو تنزيل التطبيق أو
    استخدام الخدمات، يوافق المستخدم على نقل المعلومات إلى دول خارج بلد إقامته،
    والتي قد يكون لها قواعد حماية بيانات مختلفة عن تلك الموجودة في بلده.
  </p>
  <p>
    ومع ذلك، تظل ممارساتنا المتعلقة بمعلومات المستخدم الشخصية خاضعة لسياسة
    الخصوصية هذه في جميع الأوقات، وإذا كان ذلك ممكنًا، نلتزم بمتطلبات اللائحة
    العامة لحماية البيانات التي توفر الحماية الكافية لنقل المعلومات الشخصية من
    الاتحاد الأوروبي / المنطقة الاقتصادية الأوروبية إلى بلد ثالث.{' '}
  </p>
  <p>
    لقد وفرنا العديد من الضمانات المادية والإدارية والفنية المصممة لحماية سرية
    وأمن المعلومات الشخصية التي تقع تحت سيطرتنا. ومع ذلك، لا توجد إجراءات أمنية
    مطلقة أو مضمونة بالكامل ويجب أن يدرك المستخدم أن هناك دائمًا مستوى معينًا من
    المخاطرة بأن المعلومات الشخصية التي يقدمها إلينا سيتم الكشف عنها دون موافقته
    ودون خطأ من جانب تطبيق (theEMPLOYEEapp). في حالة شكوك المستخدم باختراق
    معلوماته الشخصية، يرجى الاتصال بنا على النحو المبين في قسم "اتصل بنا". بمجرد
    علمنا بوجود خرق للأنظمة الأمنية، يتم إبلاغ المستخدم والسلطات بحدوث الانتهاك
    وفقًا للقانون المعمول به.
  </p>
  <p>
    نحتفظ فقط بمعلومات المستخدم الشخصية متى كان ذلك ضروريًا بشكل معقول لتحقيق
    الأغراض ذات الصلة المنصوص عليها في سياسة الخصوصية هذه ومن أجل الامتثال
    للالتزامات القانونية والتنظيمية.
  </p>
  <h1>6. الحقوق المتعلقة بمعلومات المستخدم الشخصية</h1>
  <p>
    نقر بأن المستخدم له الحق في الوصول إلى معلوماته الشخصية وتصحيحها، وفقًا
    للقانون المعمول به، أو الحقوق الإضافية بموجب القانون العام لحماية البيانات.
    يتم جمع المعلومات تحت إشراف صاحب العمل الخاص بالمستخدم، دون أي علاقة مباشرة
    مع المستخدم. إذا لم يرغب المستخدم في أن يتم الاتصال به من صاحب العمل الخاص
    به و / أو لم يرغب في الوصول إلى معلوماته الشخصية أو تصحيحها، يرجى الاتصال
    بصاحب العمل الذي يتعامل معه المستخدم مباشرة.
  </p>
  <h1>7. خصوصية الأطفال </h1>
  <p>
    لا يتم تقديم الخدمات للأطفال تحت سن 16 عامًا، ولا نجمع معلومات شخصية عن قصد
    من الأطفال الذين تقل أعمارهم عن 16 عامًا دون الحصول على موافقة الوالدين. إذا
    كان عمر المستخدم أقل من 16 عامًا، فيرجى عدم استخدام الخدمات أو الوصول إليها
    في أي وقت وبأي طريقة. بمجرد العلم بجمع المعلومات الشخصية عبر الخدمات من
    أشخاص تقل أعمارهم عن 16 عامًا وبدون التحقق من موافقة الوالدين، نتخذ الخطوات
    المناسبة لحذف هذه المعلومات. إذا كنت أحد الوالدين أو وصيًا واكتشفت أن طفلك
    الذي يقل عمره عن 16 عامًا قد زودنا بمعلومات شخصية، فيمكنك تنبيهنا على النحو
    المبين في قسم "اتصل بنا" وتطلب منا حذف المعلومات الشخصية لهذا الطفل من
    أنظمتنا.
  </p>
  <h1>. التحديث</h1>
  <p>
    نجري تحديثات من حينٍ لآخر لسياسة الخصوصية هذه حتى تعكس التغييرات التي تطرأ
    على ممارساتنا وتقنياتنا والمتطلبات القانونية الخاصة بنا إلى جانب عوامل أخرى.
    يُرجى التحقق من "تاريخ السريان" أعلى هذه الصفحة لمعرفة آخر مرة تم فيها تحديث
    سياسة الخصوصية هذه. عند إجراء تغييرات على سياسة الخصوصية هذه، ستصبح هذه
    التعديلات سارية على الفور بمجرد نشرها في سياسة الخصوصية المحدثة المنشورة على
    هذه الصفحة ما لم يُنص على خلاف ذلك. يشير استخدامكم للخدمات بعد هذه التغييرات
    إلى موافقتكم على الممارسات الموضحة في سياسة الخصوصية المحدثة.
  </p>
  <h1>اتصل بنا</h1>
  <p>
    في حال كان لديك أي استفسارات أو تعليقات عن سياسة الخصوصية هذه أو معلومات
    المستخدم الشخصية، أو لممارسة أي حقوق سارية، لتقديم شكوى، أو للحصول على
    معلومات عن سياساتنا وممارساتنا، يمكن الوصول إلى مسؤول الخصوصية لدينا عبر
    البريد العادي أو البريد الإلكتروني باستخدام معلومات جهة الاتصال التالية:{' '}
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Arabic
