import React from 'react'

function PP () {
  return (
<>
  <title>theEMPLOYEEapp - Política de Privacidade</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Política de Privacidade</h1>
  <h4>Data de entrada em vigor: 15 de março de 2023</h4>
  <p>
    A theEMPLOYEEapp ("theEMPLOYEEapp", "nós", "nosso/nossa" ou "connosco") está
    empenhada em oferecer transparência aos respetivos clientes e utilizadores
    no que respeita às respetivas práticas de tratamento das suas informações
    pessoais. Para isso, esta Política de Privacidade descreve a forma como
    recolhemos, utilizamos e divulgamos as informações pessoais e como é
    possível consultar e corrigir estas informações pessoais quando necessário.
  </p>
  <p>
    Quando visita o{' '}
    <a href="https://theemployeeapp.com/">Website ("Website")</a>, transfere a
    aplicação para dispositivos móveis associada ("Aplicação"), utiliza os
    serviços associados (coletivamente, "Serviços"), entra em contacto connosco
    ou aceita receber e-mails nossos, aceita os termos e condições desta
    Política de Privacidade.
  </p>
  <p>
    Esta Política de Privacidade não se aplica a Websites ou serviços operados
    por terceiros. Por conseguinte, não somos responsáveis pelas respetivas
    políticas de privacidade, procedimentos e práticas no que respeita à
    proteção de informações pessoais.
  </p>
  <p>
    Esta Política de Privacidade está integrada e está sujeita ao nosso Software
    como um Contrato de Serviços ("Contrato").
  </p>
  <h1>1. Qual é a nossa relação com a sua entidade empregadora?</h1>
  <p>
    Celebrámos um contrato com a sua entidade empregadora para lhe conceder
    acesso à Aplicação e aos Serviços ("Contrato"). Iremos proceder ao
    tratamento das suas Informações Pessoais em nome da sua entidade empregadora
    e em conformidade com as respetivas instruções legais. As informações que
    nos fornece e/ou carrega através da Aplicação (quer constituam ou não
    Informações Pessoais) também serão reguladas pelo Contrato.
  </p>
  <p>
    As referências à "sua entidade empregadora" nesta Política de Privacidade
    referem-se à entidade que celebrou o Contrato connosco, quer seja ou não, do
    ponto de vista jurídico, colaborador(a), consultor(a) ou contratante dessa
    entidade, e tais referências não se destinam a caracterizar nem a prejudicar
    o seu estatuto perante essa entidade.
  </p>
  <h1>2. Que informações recolhemos?</h1>
  <p>
    Os tipos de informações pessoais ou outras informações que poderemos
    recolher sobre si são provenientes sobretudo das seguintes origens: (a)
    informações fornecidas por si; (b) informações fornecidas pela sua entidade
    empregadora; (c) informações recolhidas através da Aplicação; d) informações
    recolhidas através do nosso serviço de SMS e (e) informações recolhidas
    automaticamente. Baseamos legalmente o nosso tratamento de Informações
    Pessoais no facto de o tratamento ser necessário para fornecer os Serviços e
    de ser efetuado de acordo com os nossos interesses legítimos, que são
    explicados detalhadamente na secção "Como utilizamos as suas informações?".
    Também poderemos tratar Informações Pessoais mediante o seu consentimento,
    solicitando-as conforme adequado.
  </p>
  <p>
    <strong>
      <u>a) Informações fornecidas por si</u>
    </strong>
  </p>
  <p>
    Quando acede aos Serviços, poderemos solicitar-lhe que nos forneça
    voluntariamente determinadas informações que o/a identificam pessoalmente ou
    que podem ser utilizadas para o/a identificar pessoalmente ("Informações
    Pessoais"). As Informações Pessoais incluem (entre outras) as seguintes
    categorias de informação: (1) dados de contacto (como o seu endereço
    profissional, endereço de e-mail e número de telefone); (2) dados
    demográficos (como o seu código postal); (3) informações profissionais (como
    o nome da sua empresa, o seu cargo e o seu ID de colaborador(a)); e (4)
    outras informações de identificação que opte voluntariamente por nos
    fornecer e/ou exigidas pela sua entidade empregadora, incluindo, entre
    outras, identificadores únicos, como palavras-passe, fotografias ou
    Informações Pessoais em e-mails ou cartas que nos envie. Também podemos
    recolher informações adicionais, que poderão ser Informações Pessoais,
    conforme lhe for descrito no momento da recolha ou de acordo com o seu
    consentimento. Se optar por ocultar Informações Pessoais solicitadas por
    nós, poderá não obter acesso a determinadas partes do Serviço.
  </p>
  <p>
    <strong>
      <u>b) Informações fornecidas pela sua entidade empregadora</u>
    </strong>
  </p>
  <p>
    A sua entidade empregadora poderá fornecer-nos informações sobre si: (1)
    quando cria um perfil de utilizador para si para permitir que aceda à
    Aplicação ao abrigo do Contrato ou para nos permitir fornecer os nossos
    Serviços, incluindo mensagens de texto. Estas informações podem incluir o
    seu nome, endereço de e-mail, número de telefone ou telemóvel, cargo e
    departamento; (2) ou enquanto a sua entidade empregadora utiliza a
    Aplicação.
  </p>
  <p>
    <strong>
      <u>c) Informações recolhidas através da Aplicação</u>
    </strong>
  </p>
  <p>
    Ao efetuar determinadas ações nesta aplicação, poderá ser-lhe solicitado que
    conceda acesso aos seguintes itens. Poderá optar por não efetuar cada uma
    das ações.
  </p>
  <ol>
    <li>Identidade</li>
    <li>Calendário</li>
    <li>Fotos/multimédia/ficheiros</li>
    <li>Microfone</li>
    <li>Ligação Wi-Fi</li>
    <li>ID do dispositivo</li>
  </ol>
  <p>O acesso seria necessário para as seguintes ações na Aplicação:</p>
  <ul>
    <li>
      O pedido relativo à identidade é efetuado unicamente para determinar a
      quem pertence a conta.
    </li>
    <li>
      O pedido relativo ao calendário procura obter autorização para aceder ao
      seu calendário para que a aplicação possa adicionar itens da sua escolha
      ao calendário.
    </li>
    <li>
      O pedido relativo às fotos/multimédia/ficheiros permite-lhe guardar
      ficheiros que estão incluídos na sua aplicação para a utilização offline.
      Para o fazer, a aplicação necessita de acesso aos seus ficheiros. A
      aplicação não altera as suas informações. Permite-lhe simplesmente guardar
      um ficheiro da sua preferência para acesso posterior, mesmo que não esteja
      disponível ligação à Internet.
    </li>
    <li>
      O pedido relativo ao microfone é utilizado para eventos em direto ou para
      gravar vídeos, uma ação compatível com esta aplicação.
    </li>
    <li>
      O pedido relativo à ligação Wi-Fi destina-se a determinar se tem uma
      ligação Wi-Fi ou de dados móveis, para alcançar o desempenho ideal da
      aplicação.
    </li>
    <li>
      O pedido relativo ao ID do dispositivo permite-lhe receber notificações
      Push na aplicação.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) Informações recolhidas através do nosso serviço de SMS</u>
    </strong>
  </p>
  <p>
    Para utilizar o nosso serviço de SMS, poderá ser-lhe solicitado que forneça
    o seu número de telemóvel e as suas preferências de mensagens de texto
    (opções que ativou e desativou). A sua entidade empregadora utiliza estas
    informações para gerir o nosso programa de mensagens de texto, incluindo
    para lhe enviar mensagens de texto. A sua operadora de comunicações sem fios
    poderá recolher dados sobre a sua utilização do dispositivo sem fios e as
    respetivas práticas são reguladas pelas próprias políticas.
  </p>
  <p>
    <strong>
      <u>e) Informações recolhidas automaticamente</u>
    </strong>
  </p>
  <p>
    Para fins de análise, a nossa plataforma também poderá ter acesso a
    informações do dispositivo, como o tipo, versão, sistema operativo e tipo e
    versão do navegador (se aplicável), bem como a informações relativas à sua
    utilização dos Serviços.{' '}
  </p>
  <p>
    Também poderemos obter informações a partir de terceiros, incluindo o Google
    Analytics, e combiná-las com as informações que recolhemos.{' '}
  </p>
  <h1>3. Como utilizamos as suas informações?</h1>
  <p>
    Tomamos medidas desenvolvidas para garantir que apenas os colaboradores que
    precisam de acesso às suas Informações Pessoais para cumprir as respetivas
    obrigações profissionais terão acesso às mesmas.
  </p>
  <p>
    Utilizamos as informações que recolhemos de várias formas para fornecer os
    Serviços e gerir o nosso negócio, incluindo:
  </p>
  <ul>
    <li>
      gerir, manter, melhorar e fornecer todas as funcionalidades dos Serviços,
      fornecer os serviços e as informações que solicita, responder a
      comentários e perguntas e prestar apoio aos utilizadores dos Serviços;
    </li>
    <li>cumprir as nossas obrigações resultantes do Contrato;</li>
    <li>
      compreender e analisar as tendências e preferências de utilização dos
      nossos utilizadores para melhorar os Serviços e desenvolver novos
      produtos, serviços, funcionalidades e funções;
    </li>
    <li>
      prevenir e detetar fraudes ou a utilização indevida dos nossos produtos,
      Serviços e Aplicação;
    </li>
    <li>enviar-lhe comunicações sujeitas às leis aplicáveis;</li>
    <li>cumprir os requisitos legais e regulamentares quando aplicável.</li>
  </ul>
  <h1>4. Com quem partilhamos as suas informações?</h1>
  <p>
    Em circunstâncias específicas e para executar os Serviços, poderemos
    divulgar determinadas informações que recolhemos sobre si:
  </p>
  <ul>
    <li>
      ao nosso grupo de empresas, o que pode incluir empresas-mãe, unidades
      corporativas, afiliados, subsidiárias, unidades de negócio e outras
      empresas que partilhem um proprietário comum;
    </li>
    <li>à sua entidade empregadora em conformidade com o Contrato;</li>
    <li>
      a fornecedores de serviços externos que trabalhem em nosso nome e
      requeiram o acesso às suas informações para realizarem o respetivo
      trabalho (por exemplo, apoio ao cliente, faturação, etc.);
    </li>
    <li>
      a um comprador, sucessor ou procurador como parte de uma fusão, aquisição
      ou transação semelhante.
    </li>
  </ul>
  <p>
    Quando divulgamos as suas Informações Pessoais a terceiros, tomamos medidas
    razoáveis para garantir o cumprimento das regras estabelecidas nesta
    Política de Privacidade e que estes terceiros forneçam garantias suficientes
    para implementar medidas técnicas e organizacionais adequadas.
  </p>
  <p>
    Por fim, poderemos disponibilizar a terceiros determinadas informações de
    identificação recolhidas automaticamente, agregadas ou, de outra forma, não
    pessoais para várias finalidades, incluindo (i) conformidade com várias
    obrigações de relatórios; (ii) fins comerciais ou de marketing; ou (iii)
    ajudar estes terceiros a compreender os seus interesses, hábitos e padrões
    de utilização para determinados programas, conteúdo, serviços e/ou
    funcionalidades disponíveis através dos Serviços.
  </p>
  <h1>5. Como armazenamos as suas informações?</h1>
  <p>
    As suas Informações Pessoais poderão ser armazenadas e tratadas em qualquer
    país onde tenhamos instalações ou onde contratemos fornecedores de serviços.
    Quando visita o Website, transfere a Aplicação ou utiliza os Serviços,
    autoriza a transferência de informações para países que não sejam o seu país
    de residência, que poderão ter regras de proteção de dados diferentes das do
    seu país.{' '}
  </p>
  <p>
    Contudo, as nossas práticas relativas às suas Informações Pessoais vão
    continuar sempre a ser reguladas por esta Política de Privacidade e, se
    aplicável, iremos cumprir os requisitos do Regulamento Geral sobre a
    Proteção de Dados ("RGPD"), fornecendo a proteção adequada para a
    transferência de Informações Pessoais da UE/EEE para um país externo.
  </p>
  <p>
    Implementámos várias garantias físicas, administrativas e técnicas,
    desenvolvidas para proteger a confidencialidade e a segurança das
    Informações Pessoais sob o nosso controlo. Contudo, nenhuma medida de
    segurança é absoluta ou totalmente segura e tem de ter em atenção que existe
    sempre um determinado grau de risco de as Informações Pessoais que nos
    fornece serem divulgadas sem o seu consentimento e sem culpa por parte da
    theEMPLOYEEapp. Se acredita que as suas Informações Pessoais estão
    comprometidas, entre em contacto connosco conforme descrito na secção
    "Contacte-nos". Se tivermos conhecimento de uma falha dos sistemas de
    segurança, iremos informá-lo(a) e às autoridades sobre a ocorrência da falha
    em conformidade com a lei aplicável.
  </p>
  <p>
    Guardamos as suas Informações Pessoais apenas durante o período
    razoavelmente necessário para concretizar as finalidades relevantes
    descritas nesta Política de Privacidade e para cumprir as nossas obrigações
    legais e regulamentares.{' '}
  </p>
  <h1>6. Direitos no âmbito das suas Informações Pessoais</h1>
  <p>
    Confirmamos que tem o direito de aceder e corrigir as suas Informações
    Pessoais em conformidade com a lei aplicável ou com direitos adicionais ao
    abrigo do RGPD. Recolhemos informações sob a orientação da sua entidade
    empregadora e não temos uma relação direta consigo. Se já não quiser que a
    sua entidade empregadora entre em contacto consigo e/ou se quiser aceder ou
    corrigir às suas Informações Pessoais, contacte a sua entidade empregadora
    com quem interage diretamente.
  </p>
  <h1>7. Privacidade das crianças</h1>
  <p>
    Os Serviços não se destinam a crianças com menos de 16 anos e não recolhemos
    intencionalmente Informações Pessoais de crianças com menos de 16 anos sem
    obter o consentimento parental. Se tiver menos de 16 anos, não utilize nem
    aceda aos Serviços em momento algum nem de qualquer forma. Se tivermos
    conhecimento de que foram recolhidas Informações Pessoais através dos
    Serviços de pessoas com menos de 16 anos e sem um consentimento parental
    verificável, iremos tomar as medidas adequadas para eliminar estas
    informações. Se for mãe/pai ou tutor(a) e descobrir que a sua criança com
    menos de 16 anos forneceu Informações Pessoais, pode alertar-nos conforme
    descrito na secção "Contacte-nos" e solicitar a eliminação das Informações
    Pessoais desta criança dos nossos sistemas.
  </p>
  <h1>8. Atualização</h1>
  <p>
    Iremos atualizar periodicamente esta Política de Privacidade para refletir
    as alterações às nossas práticas, tecnologias, requisitos legais e outros
    fatores. Verifique a "Data de entrada em vigor" no topo desta página para
    ver a data da última atualização desta Política de Privacidade. Quando são
    efetuadas alterações a esta Política de Privacidade, as alterações entram
    imediatamente em vigor quando são publicadas numa Política de Privacidade
    atualizada divulgada nesta página, salvo indicação em contrário. A sua
    utilização dos Serviços após estas alterações indica que dá o seu
    consentimento às práticas descritas na Política de Privacidade atualizada.
  </p>
  <h1>9. Contacte-nos</h1>
  <p>
    Se tiver perguntas ou comentários sobre esta Política de Privacidade ou as
    suas Informações Pessoais, se pretender exercer quaisquer direitos
    aplicáveis, apresentar uma reclamação ou obter informações sobre as nossas
    políticas e práticas, pode contactar o nosso Diretor de Privacidade por
    correio ou e-mail através das seguintes informações de contacto:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default PP
