import { gql } from '@apollo/client'

/**
 * Don't add ownerName to the query if it's not enabled
 * Don't add translatedTitle and translatedCaption to the query if it's not enabled
 * @param {bool} displayContentAuthorEnabled
 * @param {bool} shouldTranslate
 */
const GET_CONTENT_BY_ID_QUERY = (displayContentAuthorEnabled, shouldTranslate) => {
  let ownerName = ''
  if (displayContentAuthorEnabled) {
    ownerName = 'ownerName'
  }

  let translatedTitle = ''
  let translatedCaption = ''
  if (shouldTranslate) {
    translatedTitle = 'translatedTitle'
    translatedCaption = 'translatedCaption'
  }

  return gql`
  query GetContentById ($contentId: String!, $lang: String) {
    getContentById(contentId: $contentId, lang: $lang)
    {
      _id
      owner
      ${ownerName}
      ownerPhoto {
            _32px
            _64px
            _128px
            _256px
            _512px
          }
      account
      title
     ${translatedTitle}
      created
      modified
      modifiedBy
      notify
      includeInFeed
      accessGroups
      integrationId
      integrationType
      thumbnailS3Key
      mediumThumbnailUrl: thumbnailUrl
      thumbnailJobId
      thumbnailJobStatus
      displayThumbnail
      publishDate
      webUrl
      s3Key
      pdfS3Key
      tags
      share
      postDate
      unpostDate
      postTimezone
      posted
      notesEnabled
      cacheable
      contentType
      childContents {
        id
        contentType
        s3Key
        smallThumbnailUrl
        mediumThumbnailUrl
        largeThumbnailUrl
        xlargeThumbnailUrl
        priority
      }
      totalLikes
      totalComments
      totalFirstComments
      totalViews
      secure
      contentFolder
      info
      hlsVideoPlaylistUrl
      transcodeVideoStatus
      likesEnabled
      commentsEnabled
      caption
      ${translatedCaption}
      published
      likedByUser
      signedDownloadUrl
      htmlSignedDownloadUrl
      disableSaving
      mentionsEnabled
      usersMentioned
      mentions {
        id: _id
        name
      }
      hashtags
      archived
    }
  }
`
}
export default GET_CONTENT_BY_ID_QUERY
