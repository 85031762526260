import React from 'react'

function English () {
  return (
<>
  <title>theEMPLOYEEapp - Privacy Policy</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Privacy Policy</h1>
  <h4>Effective Date: March 15, 2023</h4>
  <p>
    theEMPLOYEEapp("theEMPLOYEEapp", "us", or "we" ) is committed to providing
    transparency to its customers and users with respect to its practices in
    handling their personal information. To that end, this Privacy Policy
    describes how we collect, use and disclose personal information and how this
    personal information can be consulted and corrected when necessary.
  </p>
  <p>
    By visiting the{' '}
    <a href="https://theemployeeapp.com/">website ("Website")</a>, downloading
    the associated mobile application ("App") or using associated services
    (collectively "Services"), by contacting us or agreeing to receive emails
    from us, you accept the terms and conditions of this Privacy Policy.
  </p>
  <p>
    This Privacy Policy does not extend to websites or services operated by
    third parties. We are therefore not liable for their privacy policies,
    procedures and practices regarding the protection of personal information.
  </p>
  <p>
    This Privacy Policy is incorporated into, and is subject to our Software as
    a Service Agreement ("Agreement")
  </p>
  <h1>1. What Is Our Relationship With Your Employer?</h1>
  <p>
    We have entered into an agreement with your employer to grant you access to
    the App and the Services ("Agreement"). We will process your Personal
    Information on behalf of your employer and in accordance with its lawful
    instructions. The information you provide to us and/or upload via the App
    (whether or not it constitutes Personal Information) will also be governed
    by the Agreement.
  </p>
  <p>
    References in this Privacy Policy to "your employer" shall refer to the
    entity who has entered into the Agreement with us, whether or not as a
    matter of law you are an employee, consultant or contractor of that entity,
    and such references are not intended to characterize or prejudice your
    status vis-à-vis that entity.
  </p>
  <h1>2. What Information Do We Collect?</h1>
  <p>
    The types of personal and other information we may collect about you are
    primarily from the following sources: (a) information you provide; (b)
    information provided by your employer; (c) information collected via the
    App; d) information collected via our SMS service and (e) information
    automatically collected. The legal bases for our processing of Personal
    Information are primarily that the processing is necessary for providing the
    Services and that the processing is carried out in our legitimate interests,
    which are further explained in the "How Do We Use Your Information?"
    section. We may also process Personal Information upon your consent, asking
    for it as appropriate.
  </p>
  <p>
    <strong>
      <u>a) Information You Provide</u>
    </strong>
  </p>
  <p>
    When you access the Services, we may ask you to voluntarily provide us
    certain information that personally identifies you or could be used to
    personally identify you ("Personal Information"). Personal Information
    includes (but is not limited to) the following categories of information:
    (1) contact data (such as your office address, e-mail address and phone
    number); (2) demographic data (such as your postal code); (3) professional
    information (such as your company name, your function and your employee ID);
    and (4) other identifying information that you voluntarily choose to provide
    to us and/or required by your employer, including without limitation unique
    identifiers such as passwords, photos, or Personal Information in emails or
    letters that you send to us. We may also collect additional information,
    which may be Personal Information, as otherwise described to you at the
    point of collection or pursuant to your consent. If you choose to withhold
    any Personal Information, requested by us, it may not be possible for you to
    gain access to certain parts of the Service.
  </p>
  <p>
    <strong>
      <u>b) Information Provided by your Employer</u>
    </strong>
  </p>
  <p>
    Your employer may give us information about you: (1) when creating a user
    profile for you in order to enable you to access the App under the Agreement
    or to enable us to provide our Services including text messaging. This
    information may include your name, email address, telephone or mobile phone
    number, job title,department; (2) otherwise in the course of your employer's
    use of the App.
  </p>
  <p>
    <strong>
      <u>c) Information Collected via the App</u>
    </strong>
  </p>
  <p>
    When performing certain actions within this app, you may be prompted to give
    access to the following. You may opt out of each action.
  </p>
  <ol>
    <li>Identity</li>
    <li>Calendar</li>
    <li>Photos/Media/Files</li>
    <li>Microphone</li>
    <li>Wifi connection</li>
    <li>Device ID</li>
  </ol>
  <p>The access would be required for the following actions within the App:</p>
  <ul>
    <li>
      The Identity request is solely to determine who the account belongs to.
    </li>
    <li>
      The Calendar request seeks permission to access your calendar so that the
      app can add items of your choice to your calendar.
    </li>
    <li>
      The request for Photos/Media/Files allows you to save files that are
      included in your app for offline use. To do this, the app needs access to
      your files. The app does not alter your information. It simply allows you
      to save a file of your choice for access at a later time, even when an
      internet connection is not available.
    </li>
    <li>
      The Microphone request is used for live events or to record videos, which
      this app can support.
    </li>
    <li>
      The Wifi connection request is to determine if you are on a Wifi or
      cellular connection for optimal performance of the app.
    </li>
    <li>
      The Device ID request allows you to receive push notifications within the
      app.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) information collected via our SMS service</u>
    </strong>
  </p>
  <p>
    To utilize our SMS you may be prompted to provide: your mobile phone number
    and your texting preferences (opt-ins and opt-outs). Your employer uses this
    information to manage our text messaging program, including to send you text
    messages. Your wireless carrier may collect data about your wireless device
    usage, and its practices are governed by its own policies.
  </p>
  <p>
    <strong>
      <u>e) Information Automatically Collected</u>
    </strong>
  </p>
  <p>
    For purposes of analytics, our platform may also have access to device
    information such as device type, version, operating system, and browser type
    and version (if applicable) as well as information regarding your use of the
    Services.
  </p>
  <p>
    We may also obtain information from third parties, including Google
    Analytics, and combine that with information we collect.
  </p>
  <h1>3. How Do We Use Your Information?</h1>
  <p>
    We take steps designed to ensure that only the employees who need access to
    your Personal Information to fulfill their employment duties will have
    access to it.
  </p>
  <p>
    We use the information that we collect in a variety of ways in providing the
    Services and operating our business, including:
  </p>
  <ul>
    <li>
      to operate, maintain, enhance and provide all the features of the
      Services, to provide the services and information that you request, to
      respond to comments and questions and to provide support to users of the
      Services;
    </li>
    <li>to carry out our obligations arising from the Agreement;</li>
    <li>
      to understand and analyze the usage trends and preferences of our users,
      to improve the Services, and to develop new products, services, features,
      and functionality;
    </li>
    <li>
      to prevent and detect fraud or misuse of your products, Services and App;
    </li>
    <li>to send you communications subject to applicable laws;</li>
    <li>
      or to comply with legal and regulatory requirements where applicable.
    </li>
  </ul>
  <h1>4. With Whom Do We Share Your Information?</h1>
  <p>
    In certain circumstances, and in order to perform the Services, we may
    disclose certain information that we collect from you:
  </p>
  <ul>
    <li>
      within our family of companies, which may include parents, corporate,
      affiliates, subsidiaries, business units and other companies that share
      common ownership;
    </li>
    <li>with your employer in accordance with the Agreement;</li>
    <li>
      with third-party service providers working on behalf of us require access
      to your information to carry out their work (e.g. customer support,
      billing, etc.).;
    </li>
    <li>
      and to an acquirer, successor or assignee as part of any merger,
      acquisition or similar transaction
    </li>
  </ul>
  <p>
    When we disclose your Personal Information to third parties, we take
    reasonable measures to ensure that the rules set forth in this Privacy
    Policy are complied with and these third parties provide sufficient
    guarantees to implement appropriate technical and organizational measures.
  </p>
  <p>
    We may finally make certain automatically-collected, aggregated, or
    otherwise non-personally identifiable information available to third parties
    for various purposes, including (i) compliance with various reporting
    obligations; (ii) for business or marketing purposes; or (iii) to assist
    such parties in understanding your interests, habits, and usage patterns for
    certain programs, content, services, and/or functionality available through
    the Services.
  </p>
  <h1>5. How Do We Store Your Information?</h1>
  <p>
    Your Personal Information may be stored and processed in any country where
    we have facilities or in which we engage service providers. By visiting the
    Website, downloading the App or using the Services, you consent to the
    transfer of information to countries outside of your country of residence,
    which may have different data protection rules than in your country.
  </p>
  <p>
    However, our practices regarding your Personal Information will at all times
    continue to be governed by this Privacy Policy and, if applicable, we will
    comply with the General Data Protection Regulation ("GDPR") requirements
    providing adequate protection for the transfer of Personal Information from
    the EU/EEA to a third country.
  </p>
  <p>
    We have implemented various physical, administrative and technical
    safeguards designed to protect the confidentiality and security of Personal
    Information under our control. However, no security measures are absolute or
    wholly guaranteed and you must be aware that there is always a certain level
    of risk that the Personal Information you provide to us will be disclosed
    without your consent and without fault on the part of theEMPLOYEEapp.. If
    you believe your Personal Information has been compromised, please contact
    us as set forth in the "Contact Us" section. If we learn of a security
    systems breach, we will inform you and the authorities of the occurrence of
    the breach in accordance with applicable law.
  </p>
  <p>
    We will only keep your Personal Information for as long as reasonably
    necessary to fulfil the relevant purposes set out in this Privacy Policy and
    in order to comply with our legal and regulatory obligations.
  </p>
  <h1>6. Rights Regarding Your Personal Information</h1>
  <p>
    We acknowledge that you have the right to access and correct your Personal
    Information, in accordance with applicable law, or additional rights under
    the GDPR. We collect information under the direction of your employer, and
    have no direct relationship with you. If you would no longer like to be
    contacted by your employer and/or would like to access or correct your
    Personal Information, please contact your employer that you interact with
    directly.
  </p>
  <h1>7. Children's Privacy</h1>
  <p>
    The Services are not directed to children under the age of 16, and we do not
    knowingly collect Personal Information from children under the age of 16
    without obtaining parental consent. If you are under 16 years of age, then
    please do not use or access the Services at any time or in any manner. If we
    learn that Personal Information has been collected via the Services from
    persons under 16 years of age and without verifiable parental consent, then
    we will take the appropriate steps to delete this information. If you are a
    parent or guardian and discover that your child under 16 years of age has
    provided Personal Information, then you may alert us as set forth in the
    "Contact Us" section and request that we delete that child's Personal
    Information from our systems.
  </p>
  <h1>8. Update</h1>
  <p>
    We will update this Privacy Policy from time to time to reflect changes to
    our practices, technologies, legal requirements and other factors. Please
    check the "Effective Date" at the top of this page to see when this Privacy
    Policy was last updated. When changes are made to this Privacy Policy, they
    will become immediately effective when published in an updated Privacy
    Policy posted on this page unless otherwise noted. Your use of the Services
    following these changes indicates your consent to the practices described in
    the updated Privacy Policy.
  </p>
  <h1>9. Contact Us</h1>
  <p>
    If you have any questions or comments about this Privacy Policy or your
    Personal Information, to exercise any applicable rights, to make a
    complaint, or to obtain information about our policies and practices our
    Privacy Officer can be reached by mail or email using the following contact
    information:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default English
