import React, { useState } from 'react'
import English from './english'
import Arabic from './arabic'
import Bulgarian from './bulgarian'
import CS from './chinese-simplified'
import CT from './chinese-traditional'
import French from './french'
import German from './german'
import Hungarian from './hungarian'
import Japanese from './japanese'
import Malay from './malay'
import Polish from './polish'
import PB from './portuguese-brazil'
import PP from './portuguese-portugal'
import Spanish from './spanish'
import Select from 'react-select'

function PrivatePolicy () {
  const [language, setLanguage] = useState(<English />)

  const options = [
    {
      value: <Arabic />,
      label: 'Arabic'
    },
    {
      value: <Bulgarian />,
      label: 'Bulgarian'
    },
    {
      value: <English />,
      label: 'English'
    },
    {
      value: <CS />,
      label: 'Chinese-Simplified'
    },
    {
      value: <CT />,
      label: 'Chinese-Traditional'
    },
    {
      value: <French />,
      label: 'French'
    },
    {
      value: <German />,
      label: 'German'
    },
    {
      value: <Hungarian />,
      label: 'Hungarian'
    },
    {
      value: <Japanese />,
      label: 'Japanese'
    },
    {
      value: <Malay />,
      label: 'Malaysian'
    },
    {
      value: <Polish />,
      label: 'Polish'
    },
    {
      value: <PB />,
      label: 'Portuguese-Brazil'
    },
    {
      value: <PP />,
      label: 'Portuguese-Portugal'
    },
    {
      value: <Spanish />,
      label: 'Spanish'
    }
  ]

  return (
    <>
      <div style={{ width: '50%', marginBottom: '5%' }}>
        Please select a language: <Select defaultValue={language} options={options} onChange={(values) => setLanguage(values.value)} />
      </div>
      { language }
    </>
  )
}

export default PrivatePolicy
