// @flow

import { useState, useEffect } from 'react'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { PossibleTypesMap } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { cacheDefaults } from './cacheDefaults'
import { Riffraff } from '@indicium/leonardo'
import { theRingUrl, riffraffUrl, _accessToken } from '../config'
import introspectionQueryResultData from './fragmentTypes.json'
import { setApolloClientForAnalytics } from '../Helpers/segmentHelper'
import { writeToCache, readFromCache } from './cacheHelper'
import { persistCache } from 'apollo3-cache-persist'
import mutations from '../apollo/cacheUpdaters/mutations'
import UrlHelper from '../Helpers/urlHelper'
import { isIE } from '../Helpers/GeneralHelper'
import { setApolloClientForTranslations } from '../Helpers/TranslationHelper'
import { createUploadLink } from 'apollo-upload-client'

export const riffraff = new Riffraff(riffraffUrl)
export const getAccessToken = async (isPrivatePolicy) => {
  if (_accessToken) return _accessToken
  try {
    const result = await riffraff.getAccessWithSecureCookie()
    return result.accessToken
  } catch (err) {
    if (isPrivatePolicy) return null
    const alfredUrl = UrlHelper.buildAlfredUrl()
    if (alfredUrl) {
      window.open(alfredUrl, '_self')
    } else { // since alfred url is not working for localhost
      window.alert('ERROR ' + err.message)
    }
  }
}

const introspectionToPossibleTypes = (introspectionQueryResultData): PossibleTypesMap => {
  const possibleTypes = {}

  introspectionQueryResultData.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name)
    }
  })

  return possibleTypes
}

const cache = new InMemoryCache({
  dataIdFromObject: object => object?._id, // this tells the cache what to use as an identifier for objects
  possibleTypes: introspectionToPossibleTypes(introspectionQueryResultData)
})

export const useSetupClient = (isPrivatePolicy) => {
  const [client, setClient] = useState(null)

  useEffect(() => {
    // setupClient definition
    const setupClient = async () => {
      const accessToken = await getAccessToken(isPrivatePolicy)
      localStorage.setItem('accessToken', accessToken || '')
      // Commented in order to use createUploadLink which implement it itself
      // const httpLink = new HttpLink({ uri: theRingUrl, useGETForQueries: true })
      // Updates to authLink or to SetupClient should also be in SetupClient.mock.js for integration tests to continue working properly
      const authLink = setContext(async (_, { headers }) => {
        const context = {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : '',
            'apollo-require-preflight': 'true'
          }
        }
        if (isIE()) { // don't cache anything in ie11 because it doesnt respect apollo fetch policies and always returns stale cached data
          context.headers['cache-control'] = 'no-cache'
          context.headers.pragma = 'no-cache'
          context.headers.expires = -1
        }
        return context
      })

      await persistCache({
        cache,
        storage: window.localStorage
      })
      const client = new ApolloClient({
        cache,
        link: authLink.concat(ApolloLink.from([createUploadLink({ uri: theRingUrl })])),
        resolvers: {
          Mutation: mutations
        }
      })

      let dataFromCache = readFromCache(client)
      if (!dataFromCache) {
        dataFromCache = cacheDefaults
      }
      writeToCache(client, dataFromCache)
      setClient(client)
      if (accessToken) localStorage.setItem('accessToken', accessToken)
    }

    // setupClient call
    setupClient()
  }, [isPrivatePolicy])
  // Sets the client for analytics
  setApolloClientForAnalytics(client)
  setApolloClientForTranslations(client)
  return client
}
