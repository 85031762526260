import React from 'react'

function Polish () {
  return (
<>
  <title>theEMPLOYEEapp - Zasady ochrony prywatności</title>
  <meta charSet="utf-8" />
  <link
    href="https://fonts.googleapis.com/css?family=Roboto:300,400"
    rel="stylesheet"
  />
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        body {\n            font-family: 'Roboto', sans-serif;\n            text-align: justify;\n            font-style: normal;\n            font-weight: 300;\n            padding: 2em;\n        }\n\n        hr {\n            border: 1px #DDDDDD solid;\n            margin: 2em 1em;\n        }\n\n    "
    }}
  />

  <h1>Polityka prywatności</h1>
  <h4>Data wejścia w życie: 15 marca 2023 r</h4>
  <p>
    theEMPLOYEEapp („theEMPLOYEEapp”, „my”) jest zobowiązana do zapewnienia
    przejrzystości swoim klientom i użytkownikom w odniesieniu do swoich praktyk
    w zakresie postępowania z ich danymi osobowymi. W tym celu niniejsza
    Polityka Prywatności opisuje, w jaki sposób gromadzimy, wykorzystujemy i
    ujawniamy dane osobowe oraz w jaki sposób można się z nimi zapoznać i w
    razie potrzeby skorygować.
  </p>
  <p>
    Odwiedzając
    <a href="https://theemployeeapp.com/">stronę internetową („Strona”)</a>,
    pobierając powiązaną aplikację mobilną („Aplikacja”) lub korzystając z
    powiązanych usług (łącznie „Usługi”), kontaktując się z nami lub wyrażając
    zgodę na otrzymywanie od nas wiadomości e-mail, akceptują Państwo warunki
    niniejszej Polityki Prywatności.
  </p>
  <p>
    Niniejsza Polityka Prywatności nie obejmuje stron internetowych lub usług
    prowadzonych przez strony trzecie. Dlatego nie ponosimy odpowiedzialności za
    ich politykę prywatności, procedury i praktyki dotyczące ochrony danych
    osobowych.
  </p>
  <p>
    Niniejsza Polityka prywatności jest włączona do naszej Umowy o świadczenie
    usług w zakresie oprogramowania („Umowa”) i jest jej przedmiotem.
  </p>
  <h1>1. Jakie są nasze relacje z Państwa pracodawcą?</h1>
  <p>
    Zawarliśmy umowę z Państwa pracodawcą w celu przyznania Państwu dostępu do
    Aplikacji i Usług („Umowa”). Będziemy przetwarzać Państwa dane osobowe w
    imieniu Państwa pracodawcy i zgodnie z jego zgodnymi z prawem poleceniami.
    Informacje, które nam Państwo dostarczą i/lub prześlą za pośrednictwem
    Aplikacji (niezależnie od tego, czy stanowią one dane osobowe, czy też nie)
    również będą podlegać Umowie.
  </p>
  <p>
    Odniesienia w niniejszej Polityce Prywatności do „Państwa pracodawcy”
    odnoszą się do podmiotu, który zawarł z nami Umowę, niezależnie od tego, czy
    w świetle prawa są Państwo pracownikiem, konsultantem lub podwykonawcą tego
    podmiotu, a takie odniesienia nie mają na celu scharakteryzowania lub
    naruszenia Państwa statusu wobec tego podmiotu.
  </p>
  <h1>2. Jakie informacje zbieramy?</h1>
  <p>
    Rodzaje danych osobowych i innych informacji, które możemy gromadzić na
    Państwa temat, pochodzą przede wszystkim z następujących źródeł: (a)
    informacje podane przez Państwa; (b) informacje dostarczone przez Państwa
    pracodawcę; (c) informacje zebrane za pośrednictwem Aplikacji; d) informacje
    zebrane za pośrednictwem naszej usługi SMS oraz (e) informacje zebrane
    automatycznie. Podstawą prawną przetwarzania przez nas danych osobowych jest
    przede wszystkim konieczność takiego przetwarzania do świadczenia Usług oraz
    przetwarzanie w naszych uzasadnionych interesach, które zostały szerzej
    wyjaśnione w części „Jak wykorzystujemy Państwa dane?”. Możemy również
    przetwarzać dane osobowe za Państwa zgodą, prosząc o nią w razie potrzeby.
  </p>
  <p>
    <strong>
      <u>a) Podane przez Państwa informacje</u>
    </strong>
  </p>
  <p>
    Podczas korzystania z Usług możemy poprosić Państwa o dobrowolne podanie
    pewnych informacji, które umożliwiają Państwa identyfikację lub mogą być
    wykorzystane do identyfikacji Państwa („dane osobowe”). Dane osobowe
    obejmują (ale nie ograniczają się do) następujących kategorii informacji:
    (1) dane kontaktowe (takie jak adres biurowy, adres e-mail i numer
    telefonu); (2) dane demograficzne (takie jak Państwa kod pocztowy); (3)
    informacje zawodowe (takie jak nazwa firmy, pełniona funkcja i identyfikator
    pracownika); oraz (4) inne informacje identyfikacyjne, które dobrowolnie
    zdecydują się Państwo nam podać i/lub które są wymagane przez Państwa
    pracodawcę, w tym bez ograniczeń unikalne identyfikatory, takie jak hasła,
    zdjęcia lub dane osobowe w wiadomościach e-mail lub listach, które wysyłają
    Państwo do nas. Możemy również zbierać dodatkowe informacje, które mogą być
    danymi osobowymi, jak zostało opisane Państwu w miejscu zbierania danych lub
    na podstawie Państwa zgody. Jeżeli zdecydują się Państwo na zatajenie
    jakichkolwiek danych osobowych, o które prosimy, uzyskanie dostępu do
    niektórych części Usługi może okazać się niemożliwe.
  </p>
  <p>
    <strong>
      <u>b) Informacje dostarczone przez Państwa pracodawcę</u>
    </strong>
  </p>
  <p>
    Pracodawca może przekazać nam Państwa informacje: (1) podczas tworzenia
    profilu użytkownika dla Państwa w celu umożliwienia Państwu dostępu do
    Aplikacji na podstawie Umowy lub umożliwienia nam świadczenia naszych Usług,
    w tym wysyłania wiadomości tekstowych. Informacje te mogą obejmować Państwa
    nazwisko, adres e-mail, numer telefonu lub telefonu komórkowego, stanowisko,
    dział; (2) w inny sposób w trakcie korzystania z Aplikacji przez Państwa
    pracodawcę.
  </p>
  <p>
    <strong>
      <u>c) Informacje zbierane za pośrednictwem Aplikacji</u>
    </strong>
  </p>
  <p>
    Podczas wykonywania pewnych czynności w niniejszej aplikacji, mogą Państwo
    zostać poproszeni o udzielenie dostępu do następujących danych. Z każdej
    czynności mogą Państwo zrezygnować.
  </p>
  <ol>
    <li>Tożsamość</li>
    <li>Kalendarz</li>
    <li>Zdjęcia/media/pliki</li>
    <li>Mikrofon</li>
    <li>Połączenie Wifi</li>
    <li>Identyfikator urządzenia</li>
  </ol>
  <p>Dostęp jest wymagany do następujących działań w aplikacji:</p>
  <ul>
    <li>
      Zapytanie o tożsamość ma na celu wyłącznie ustalenie, do kogo należy
      konto.
    </li>
    <li>
      Prośba o dostęp do kalendarza ma na celu umożliwienie aplikacji dodania do
      kalendarza wybranych przez Państwa pozycji.
    </li>
    <li>
      Prośba o dostęp do zdjęć/mediów/plików pozwala na zapisanie plików, które
      są zawarte w Państwa aplikacji, do wykorzystania w trybie offline. Aby to
      zrobić, aplikacja musi mieć dostęp do Państwa plików. Aplikacja nie
      zmienia Państwa danych. Pozwala po prostu na zapisanie wybranego pliku w
      celu uzyskania do niego dostępu w późniejszym czasie, nawet gdy nie ma
      połączenia z Internetem.
    </li>
    <li>
      Prośba o dostęp do mikrofonu jest wykorzystywane podczas wydarzeń na żywo
      lub do nagrywania filmów, które ta aplikacja może obsługiwać.
    </li>
    <li>
      Zapytanie o połączenie Wifi ma na celu określenie, czy korzystają Państwo
      z połączenia Wifi czy komórkowego, aby uzyskać optymalne działanie
      aplikacji.
    </li>
    <li>
      Prośba o dostęp do identyfikatora urządzenia pozwala na otrzymywanie
      powiadomień push w aplikacji.
    </li>
  </ul>
  <p>
    <strong>
      <u>d) informacje zebrane za pośrednictwem naszej usługi SMS</u>
    </strong>
  </p>
  <p>
    Aby skorzystać z naszej usługi SMS, mogą Państwo zostać poproszeni o
    podanie: numeru telefonu komórkowego oraz preferencji dotyczących wysyłania
    SMS (włączenie lub wyłączenie odpowiednich opcji). Państwa Pracodawca
    wykorzystuje te informacje do zarządzania naszym programem SMS, w tym do
    wysyłania Państwu wiadomości tekstowych. Państwa operator sieci
    bezprzewodowej może zbierać dane dotyczące użytkowania Państwa urządzenia
    bezprzewodowego, a jego praktyki w tym zakresie są regulowane przez jego
    własne zasady.
  </p>
  <p />
  <p>
    <strong>
      <u>e) Informacje zbierane automatycznie</u>
    </strong>
  </p>
  <p>
    Dla celów analitycznych nasza platforma może mieć również dostęp do
    informacji o urządzeniu, takich jak typ urządzenia, wersja, system
    operacyjny oraz typ i wersja przeglądarki (jeśli dotyczy), jak również do
    informacji dotyczących korzystania przez Państwa z Usług.
  </p>
  <p>
    Możemy również uzyskiwać informacje od osób trzecich, w tym od Google
    Analytics, i łączyć je z gromadzonymi przez nas informacjami.
  </p>
  <h1>3. Jak wykorzystujemy Państwa informacje?</h1>
  <p>
    Podejmujemy działania mające na celu zapewnienie, że dostęp do Państwa
    danych osobowych będą mieli wyłącznie pracownicy, którym jest to niezbędne
    do wykonywania obowiązków służbowych.
  </p>
  <p>
    Gromadzone przez nas informacje wykorzystujemy na wiele sposobów podczas
    świadczenia Usług i prowadzenia naszej działalności, w tym:
  </p>
  <ul>
    <li>
      aby obsługiwać, utrzymywać, ulepszać i zapewniać wszystkie funkcje Usług,
      świadczyć usługi i dostarczać informacje, o które Państwo proszą,
      odpowiadać na komentarze i pytania oraz zapewniać wsparcie użytkownikom
      Usług;
    </li>
    <li>w celu wykonania naszych zobowiązań wynikających z Umowy;</li>
    <li>
      aby zrozumieć i przeanalizować tendencje i preferencje naszych
      użytkowników, aby ulepszać Usługi oraz opracowywać nowe produkty, usługi,
      funkcje i funkcjonalności;
    </li>
    <li>
      w celu zapobiegania i wykrywania oszustw lub niewłaściwego wykorzystania
      Państwa produktów, Usług i Aplikacji;
    </li>
    <li>aby wysyłać Państwu wiadomości zgodnie z obowiązującymi przepisami;</li>
    <li>
      lub w celu spełnienia wymogów prawnych i regulacyjnych, tam gdzie ma to
      zastosowanie.
    </li>
  </ul>
  <h1>4. Komu przekazujemy Państwa informacje?</h1>
  <p>
    W pewnych okolicznościach i w celu świadczenia Usług możemy ujawnić pewne
    informacje, które od Państwa pozyskaliśmy:
  </p>
  <ul>
    <li>
      w ramach naszej rodziny firm, która może obejmować spółki macierzyste,
      korporacyjne, stowarzyszone, zależne, jednostki biznesowe i inne firmy,
      które mają wspólnego właściciela;
    </li>
    <li>z Państwa pracodawcą zgodnie z Umową;</li>
    <li>
      z usługodawcami zewnętrznymi pracującymi w naszym imieniu, którzy
      potrzebują dostępu do Państwa danych w celu wykonania swojej pracy (np.
      obsługa klienta, rozliczenia itp.);
    </li>
    <li>
      oraz nabywcy, następcy lub cesjonariuszowi w ramach jakiejkolwiek fuzji,
      przejęcia lub podobnej transakcji
    </li>
  </ul>
  <p>
    Gdy ujawniamy Państwa dane osobowe stronom trzecim, podejmujemy uzasadnione
    środki w celu zapewnienia, że zasady określone w niniejszej Polityce
    Prywatności są przestrzegane, a te osoby trzecie dają wystarczające
    gwarancje wdrożenia odpowiednich środków technicznych i organizacyjnych.
  </p>
  <p>
    Możemy również udostępnić osobom trzecim pewne automatycznie zbierane,
    zagregowane lub w inny sposób nieidentyfikowalne informacje w różnych
    celach, w tym (i) w celu spełnienia różnych obowiązków sprawozdawczych; (ii)
    w celach biznesowych lub marketingowych; lub (iii) w celu pomocy takim
    osobom w zrozumieniu Państwa zainteresowań, przyzwyczajeń i wzorców
    korzystania z określonych programów, treści, usług i/lub funkcji dostępnych
    za pośrednictwem Usług.
  </p>
  <h1>5. Jak przechowujemy Państwa dane?</h1>
  <p>
    Państwa dane osobowe mogą być przechowywane i przetwarzane w dowolnym kraju,
    w którym posiadamy siedziby lub w którym angażujemy usługodawców.
    Odwiedzając Stronę internetową, pobierając Aplikację lub korzystając z
    Usług, wyrażają Państwo zgodę na przekazywanie informacji do krajów spoza
    Państwa kraju zamieszkania, w których mogą obowiązywać inne zasady ochrony
    danych niż w Państwa kraju.
  </p>
  <p>
    Jednakże nasze praktyki dotyczące Państwa danych osobowych będą przez cały
    czas podlegały niniejszej Polityce Prywatności, a w stosownych przypadkach
    będziemy spełniać wymogi Ogólnego Rozporządzenia o Ochronie Danych Osobowych
    („RODO”), zapewniające odpowiednią ochronę przy przekazywaniu Danych
    Osobowych z UE/EOG do kraju trzeciego.
  </p>
  <p>
    Wprowadziliśmy różne fizyczne, administracyjne i techniczne zabezpieczenia
    mające na celu ochronę poufności i bezpieczeństwa Danych Osobowych
    znajdujących się pod naszą kontrolą. Jednakże żadne środki bezpieczeństwa
    nie są absolutne ani w pełni gwarantowane i muszą być Państwo świadomi, że
    zawsze istnieje pewien poziom ryzyka, że Dane Osobowe, które nam Państwo
    udostępniają, zostaną ujawnione bez Państwa zgody i bez winy ze strony
    theEMPLOYEEapp. Jeżeli sądzą Państwo, że Państwa dane osobowe zostały
    narażone na ujawnienie, prosimy o kontakt z nami w sposób określony w sekcji
    „Kontakt”. Jeżeli dowiemy się o naruszeniu systemów bezpieczeństwa,
    poinformujemy Państwa i władze o wystąpieniu takiego naruszenia zgodnie z
    obowiązującym prawem.
  </p>
  <p>
    Będziemy przechowywać Państwa dane osobowe tylko tak długo, jak będzie to
    zasadnie konieczne do realizacji odpowiednich celów określonych w niniejszej
    Polityce Prywatności oraz w celu wypełnienia naszych zobowiązań prawnych i
    regulacyjnych.
  </p>
  <h1>6. Prawa dotyczące Państwa danych osobowych</h1>
  <p>
    Przyjmujemy do wiadomości, że mają Państwo prawo dostępu do swoich danych
    osobowych i ich poprawiania, zgodnie z obowiązującym prawem lub dodatkowymi
    prawami wynikającymi z RODO. Zbieramy informacje pod kierunkiem Państwa
    pracodawcy i nie mamy z Państwem bezpośredniego związku. Jeżeli nie życzą
    sobie Państwo, aby pracodawca kontaktował się z Państwem i/lub chcieliby
    Państwo uzyskać dostęp do swoich danych osobowych lub je poprawić, proszę
    skontaktować się z pracodawcą, z którym mają Państwo bezpośredni kontakt.
  </p>
  <h1>7. Prywatność dzieci</h1>
  <p>
    Usługi nie są skierowane do dzieci poniżej 16 roku życia, a my nie
    gromadzimy świadomie danych osobowych dzieci poniżej 16 roku życia bez
    uzyskania zgody rodziców. Jeżeli mają Państwo mniej niż 16 lat, prosimy o
    niekorzystanie z Usług ani nie uzyskiwanie do nich dostępu w jakimkolwiek
    czasie i w jakikolwiek sposób. Jeżeli dowiemy się, że dane osobowe zostały
    zebrane za pośrednictwem Usług od osób poniżej 16 roku życia i bez możliwej
    do zweryfikowania zgody rodziców, wówczas podejmiemy odpowiednie kroki w
    celu usunięcia tych informacji. Jeżeli są Państwo rodzicami lub opiekunami i
    odkryją, że Państwa dziecko w wieku poniżej 16 lat podało dane osobowe, mogą
    Państwo powiadomić nas w sposób określony w sekcji „Kontakt” i zażądać
    usunięcia danych osobowych tego dziecka z naszych systemów.
  </p>
  <h1>8. Aktualizacja</h1>
  <p>
    Od czasu do czasu będziemy aktualizować niniejszą Politykę Prywatności, aby
    odzwierciedlić zmiany w naszych praktykach, technologiach, wymaganiach
    prawnych i innych czynnikach. Proszę sprawdzić „datę wejścia w życie” na
    górze tej strony, aby dowiedzieć się, kiedy ta Polityka Prywatności była
    ostatnio aktualizowana. W przypadku wprowadzenia zmian w niniejszej Polityce
    Prywatności, staną się one natychmiast skuteczne po opublikowaniu w
    zaktualizowanej Polityce Prywatności zamieszczonej na tej stronie, chyba że
    zaznaczono inaczej. Korzystanie przez Państwa z Usług po wprowadzeniu tych
    zmian oznacza zgodę na praktyki opisane w zaktualizowanej Polityce
    Prywatności.
  </p>
  <h1>9. Kontakt z nami</h1>
  <p>
    W razie jakichkolwiek pytań lub uwag dotyczących niniejszej Polityki
    Prywatności lub Państwa danych osobowych, w celu skorzystania ze stosownych
    praw, złożenia skargi lub uzyskania informacji na temat naszej polityki i
    praktyk, z naszym specjalistą ds. ochrony prywatności można skontaktować się
    listownie lub pocztą elektroniczną, korzystając z następujących danych
    kontaktowych:
  </p>
  <p>
    Privacy Officer
    <br />
    theEMPLOYEEapp, LLC
    <br />
    3636 Nobel Dr Ste 130
    <br />
    San Diego, CA 92122
    <br />
    <a href="mailto:customercare@theemployeeapp.com">
      customercare@theemployeeapp.com
    </a>
  </p>
</>
  )
}

export default Polish
